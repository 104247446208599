import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    constructor(private http: HttpClient) { }
    get(url, displayLoading = true) {
        let headers = new HttpHeaders();

        if (!displayLoading) {
            headers = headers.set('hiddenLoading', 'true');
        }
        return this.http.get(url, { headers: headers });
    }

    post(url, body, displayLoading = true) {
        let headers = new HttpHeaders();

        if (!displayLoading) {
            headers = headers.set('hiddenLoading', 'true');
        }
        return this.http.post(url, body, { headers: headers });
    }
    
    delete(url, displayLoading = true) {
        let headers = new HttpHeaders();

        if (!displayLoading) {
            headers = headers.set('hiddenLoading', 'true');
        }
        return this.http.delete(url, { headers: headers });
    }
}
import { EventEmitter, Injectable, Output } from "@angular/core";


export class DateRange {
    fromDate: Date;
    toDate: Date;
}

@Injectable()
export class RangeDatesService {

    @Output() onChangeRange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

    setRange(DateRange: DateRange) {
        this.onChangeRange.emit({ fromDate: DateRange.fromDate, toDate: DateRange.toDate });
    }

}
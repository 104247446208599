import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationModel } from '../model/verification.model';

@Injectable()
export class VerificationService {

  constructor(private httpClient: HttpClient) { }

  sendVerificationCode(request: VerificationModel): Observable<any> {
    return this.httpClient.post<{
    }>('/verification/sendVerificationCode', request);
  }
  sendVerificationPhone(phone: string): Observable<any> {
    return this.httpClient.get('/verification/sendVerificationPhone/' + phone);
  }

  checkVerificationCode(model: any): Observable<any> {
    return this.httpClient.post('/verification/checkVerificationCode', model);
  }
}

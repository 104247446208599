import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import { expand, map, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  //#region private members
  private _currentPosition: { x: number; y: number; };
  public get currentPosition(): { x: number; y: number; } {
    return this._currentPosition;
  }
  //#endregion

  constructor(private httpClient: HttpClient) {
    navigator.geolocation.watchPosition((res: any) => {
      this._currentPosition = { x: res.coords.latitude, y: res.coords.longitude };
    })
  }

  getRange(url: string, options: any): Observable<any> {
    return this.httpClient.get(url, options);
  };


  // Perform a recursive request to retrive results with pagination:

  getIsraelCities(): Observable<object[]> {
    var offset = 1;
    var url = 'https://data.gov.il/api/3/action/datastore_search?resource_id=5c78e9fa-c2e2-4771-93ff-7f400a12f7ba&offset=';
    var options = { headers: { 'Do-Not-Add-Headers': 'true', } };
    return this.getRange(url + offset, options).pipe(
      expand((res) => res.result?.records.length
        ? this.getRange(url + (offset += 100), options)
        : empty()
      ),
      map(res => res.result?.records),
      reduce((val1, val2) => val1.concat(val2), new Array<object>()),
    );
  }

  getCountriesList() {
    var offset = 1;
    var url = 'https://data.gov.il/api/3/action/datastore_search?resource_id=c84082e9-7d45-4853-9a95-e7eaad7f66d5&offset=';
    var options = { headers: { 'Do-Not-Add-Headers': 'true', } };
    return this.getRange(url + offset, options).pipe(
      expand((res) => res.result?.records.length
        ? this.getRange(url + (offset += 100), options)
        : empty()
      ),
      map(res => res.result?.records),
      reduce((val1, val2) => val1.concat(val2), new Array<object>()),
    );
  }

  //#region calc distance between 2 points in meters
  calcDistanceInMeters(x1: number, y1: number, x2: number = null, y2: number = null) {
    x2 = !x2 ? this.currentPosition?.x : x2;
    y2 = !y2 ? this.currentPosition?.y : y2;
    return (this.calcDistByFormula(x1, y1, x2, y2) * 1000).toFixed();
  }

  calcDistByFormula(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  toRad(Value) {
    return Value * Math.PI / 180;
  }
  //#endregion
}


import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyGuard } from '../kesher-shared/guards/company.guard';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { PermissionType } from './permissions/permission-type.enum';
import { PermissionsGuard } from './permissions/permissions.guard';
import { TranslateResolve } from 'shared/src/public-api';



const desktopRoutes: Routes = [
  {
    path: '', component: DefaultLayoutComponent, resolve: { menuTranslations: TranslateResolve }/*, canActivate: [CompanyGuard]*/
    , data: { translateKey: 'MENU' },
    children: [
      { path: 'personal', loadChildren: () => import('../personal/personal.module').then(m => m.PersonalModule) },
      { path: 'management', loadChildren: () => import('../management/management.module').then(m => m.ManagementModule), canActivate: [PermissionsGuard], data: { permissionName: PermissionType.MANAGMENT_MODULE } },
      { path: 'kesher/:company', loadChildren: () => import('../kesher/kesher.module').then(m => m.KesherModule), canActivate: [CompanyGuard] },
      { path: 'technicians', loadChildren: () => import('../technicians/technicians.module').then(m => m.TechniciansModule), canActivate: [PermissionsGuard], data: { permissionName: PermissionType.TECHNICIAN } }
    ]
  }
];
let currentRoutes = desktopRoutes;


// switch (AppResolutionType) {
//   case ResolutionType.Mobile:
//     {
//       currentRoutes = mobileRoutes;
//     }
// }


@NgModule({
  imports: [RouterModule.forChild(currentRoutes,)],
  exports: [RouterModule]
})
export class CoreRoutingModule {

  private companyID: number;
  private pageName: string = "CoreRoutingModule";
  private subscriptions: Subscription = new Subscription();
  private actRoute: ActivatedRouteSnapshot

}

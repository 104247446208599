

<div class="contact-container">
    <!-- Main Icon -->
    <button pButton pRipple type="button"
        class="p-button-rounded mr-2 mb-2 main-button primaryFill main-icon p-button-icon-only"
        (click)="toggleContactIcons()">
        <ng-container *ngIf="!showContactIcons; else alternateIcon">
            <i class="pi pi-comments"></i>
        </ng-container>
        <ng-template #alternateIcon>
            <i class="pi pi-times"></i> <!-- Replace with your alternate icon -->
        </ng-template>
    </button>
    <!-- Floating Icons -->
    <button pButton pRipple type="button" (click)="openGmailChat()" pTooltip="מייל" *ngIf="showContactIcons"
        [ngClass]="{ show: showContactIcons }" tooltipPosition="top"
        class="contact-icon  p-button-icon-only p-button-rounded mr-2 mb-2 main-button primaryFill  email-icon"
        (click)="toggleContactIcons()">
        <img src="/assets/images/mail.png" alt="icon" style="    height: 2rem;" />
    </button>
    <button style="background-color: #4caf50;" pButton pRipple type="button" (click)="whatsappClick()" pTooltip="וואצאפ"
        *ngIf="showContactIcons" [ngClass]="{ show: showContactIcons }" tooltipPosition="top"
        class="contact-icon  p-button-icon-only p-button-rounded mr-2 mb-2 main-button primaryFill  whatsapp-icon"
        (click)="toggleContactIcons()">
        <img src="/assets/images/whatsapp_icon.png" alt="icon" style="    height: 2rem;" />

    </button>

</div>
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  QueryList
} from "@angular/core";
import { AbstractControl, FormControlName } from "@angular/forms";
import { LocalizationService } from "shared/src/localization";
import { DEFAULT_ERRORS } from "../../default-errors";
import { ErrorMessage } from "../../models/error-message";
import { MessagesComponent } from "../messages/messages.component";

@Component({
  // tslint:disable:component-selector
  selector: ".form-group",
  template: `
     <ng-content></ng-content>
     <div >
     <error-message  *ngIf="!messagesBlock" [messages]="messages"></error-message>
     </div>
   `
})
export class FormGroupComponent implements OnInit, AfterContentInit {
  @ContentChildren(FormControlName, { descendants: true })
  FormControlNames: QueryList<FormControlName>;

  @Input()
  customErrorMessages: ErrorMessage[] = [];

  @Input()
  validationDisabled = false;

  private _updateValidations = 0;
  @Input() set updateValidations(value: number) {
    this._updateValidations = value;
    if (this._updateValidations > 0) {
      this.insertRequiredSign();
    }
  }
  get updateValidations() { return this._updateValidations };


  @HostBinding("class.has-error")
  get hasErrors() {
    return (
      this.FormControlNames.some(c => !c.valid && c.dirty && c.touched) &&
      !this.validationDisabled
    );
  }

  @HostBinding("class.has-success")
  get hasSuccess() {
    return (
      !this.FormControlNames.some(c => !c.valid) &&
      this.FormControlNames.some(c => c.dirty && c.touched) &&
      !this.validationDisabled
    );
  }

  @ContentChild(MessagesComponent)
  public messagesBlock: MessagesComponent;

  private errorMessages: ErrorMessage[];

  public messages = () => this.getMessages();

  constructor(
    private elRef: ElementRef,
    private translator: LocalizationService
  ) { }

  ngAfterContentInit() {
    if (this.messagesBlock) {
      this.messagesBlock.messages = this.messages;
    }

    this.insertRequiredSign();
  }

  ngOnInit() {
    this.errorMessages = DEFAULT_ERRORS
      .concat(this.customErrorMessages)
      .reverse();
  }

  get label() {
    const label = this.elRef.nativeElement.querySelector("label");
    if (label && label.textContent) {
      let index = label.textContent.indexOf("*");
      return index > 0 ? label.textContent.substring(0, index - 1) : label.textContent;
    }
    return this.translator.Translate("ERRORS.THIS_FIELD");
  }

  get isDirtyAndTouched() {
    return this.FormControlNames.some(c => c.dirty && c.touched);
  }

  private getMessages(): string[] {
    const messages = [];
    if (!this.isDirtyAndTouched || this.validationDisabled) {
      return messages;
    }

    const names = this.FormControlNames.map(f => f.name);

    this.FormControlNames.filter(
      (c, i) =>
        !c.valid &&
        !!c.errors &&
        // filter out FormControlNames that share the same name - usually for radio buttons
        names.indexOf(c.name) === i
    ).forEach(control => {
      Object.keys(control.errors).forEach(key => {
        const error = this.errorMessages.find(err => err.error === key);
        if (!error) {
          return;
        }
        messages.push(error.format(this.label, this.translator, this.getCorrectError(control.errors, key)));
      });
    });

    return messages;
  }

  private insertRequiredSign() {
    //var label = this.elRef.nativeElement.querySelector("label")
    //console.log(label?.attributes[1].nodeValue, label?.textContent, label?.toString());

    //when clear validators, need remove * sign from title
    let clearedControls = this.FormControlNames?.filter(control => (control as any).hasRequiredSign) ?? [];
    if (clearedControls && clearedControls.length > 0) {
      const label = this.elRef.nativeElement.querySelector("label");
      if (label && label.textContent.indexOf('*') > -1) {
        let signIndex = label.textContent.indexOf('*');
        label.textContent = label.textContent.slice(0, signIndex - 1);
      }
    }

    let requiredControl = this.FormControlNames?.filter(formControlName => {
      let control = formControlName.control;
      if (control && control.validator) {
        const validators = control.validator({} as AbstractControl);
        return validators && validators.required;
      }
      return false;
    });

    if (requiredControl && requiredControl.length > 0) {
      //set hasRequiredSign to true for next time if need clear validator
      (requiredControl[0] as any).hasRequiredSign = true;
      const label = this.elRef.nativeElement.querySelector("label");
      if (label && (!label.textContent || label.textContent.indexOf('*') < 0)) {
        if (label.textContent.length > 0) {
          let span = document.createElement("span");
          span.textContent = "  *";
          label.appendChild(span);
        }
      }
    }
  }

  private getCorrectError(errObject: any, key: string): any {
    if (Object.keys(errObject[key]).length) return errObject[key];
    switch (key) {
      case "max":
        errObject.max = errObject.requiredValue;
        break;
    }
    return errObject;
  }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient) { }

  getUsers(request: any): Observable<any> {
    return this.httpClient.post("/user/getUsers", request);
  }
  exportUsers(request: any) {
    return this.httpClient.post("/user/exportUsers", request, { responseType: "blob", headers: { 'Accept': 'application/octet-stream' } });
  }
  saveAutoSend(request: any, userId: number): Observable<any> {
    return this.httpClient.post(`/user/saveAutoSend/${userId}`, request);
  }
  update(user: any): Observable<any> {
    return this.httpClient.put(`/user/${user.id}`, { entity: user });
  }
  changeUserRole(id: number, isAdmin: boolean): Observable<any> {
    return this.httpClient.get(`/user/changeUserRole/${id}/${isAdmin}`);
  }
}

import { ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { CommonState } from '../state/state.index';
import { Observable } from 'rxjs';
import { AdvMessageService } from './message.service';
import { MessageTypes } from 'shared/src/enum';
import { setIsLoading, setSpinnerLoading } from '../state/actions/‏‏shared-common.action';
import { DecodingTokenModel } from '../model/decoding-token.model';

@Injectable()
export class CommonService {

  constructor(private store: Store<CommonState>, private httpClient: HttpClient, private messagesService: AdvMessageService,
) {
  }

  public blockScreen(divElement?: any) {
    this.setIsLoading(true, divElement);
  }

  public spinnerBlockScreen(divElement?: any) {
    this.setSpinnerLoading(true, divElement);
  }

  public unblockScreen() {
    this.setIsLoading(false);
    this.setSpinnerLoading(false);
  }

  private setIsLoading(isLoading: boolean, divElement?: ElementRef) {
    this.store.dispatch(setIsLoading({ isLoading, divElement }));
  }

  private setSpinnerLoading(spinnerLoading: boolean, divElement?: ElementRef) {
    this.store.dispatch(setSpinnerLoading({ spinnerLoading, divElement }));
  }

  decodingToken(request: DecodingTokenModel) {
    return this.httpClient.post('/Clearing/DecodingToken', request);
  }

  checkThereIsNewVersion(clientVersion): Observable<boolean> {
    return this.httpClient.get<boolean>('/Common/thereIsNewVersion?clientVersion=' + clientVersion);
  }
  getCurrencies(): Observable<any> {
    return this.httpClient.get<any>('/Common/getCurrencies' );
  }

  updateNewVersion() {
    this.messagesService.showMessage(MessageTypes.info, "נמצאה גרסה חדשה", "המערכת מתעדכנת...", 5000);
    setTimeout(() => {
      let locationWin = window.location;
      (locationWin as any).reload(true); // send true for full reload and not from cache
    }, 5000);
  }
}

<ng-template id="ngx-photo-editor-modal" #ngxPhotoEditorContent let-modal>
    <div [style.backgroundColor]="darkTheme?'#36393F':'#D8D8D8'" class="modal-body" style="padding-bottom: 0">
      <h5 *ngIf="!hideModalHeader" class="pt-4 text-center font-light" [style.color]="darkTheme?'white':'black'"
          style="margin-bottom: 16px">תצוגה מקדימה של הקובץ: {{modalTitle}}</h5>
      <div [style.height]="canvasHeight+'px'" class="ngx-photo-editor-img-container">
        <div *ngIf="!imageLoaded" class="w-100 h-100 d-flex justify-content-center align-items-center text-light">
          <div class="spinner-border" role="status"></div>
        </div>
        <img width="0" height="0" #image (load)="onImageLoad(image)" [src]="url" crossorigin="anonymous"
             id="ngx-photo-editor-image">
      </div>
    </div>
    <!--  <div style="padding: 5px 16px" [style.backgroundColor]="darkTheme?'#36393F':'#D8D8D8'">-->
    <!--    <input type="range" style="height: 1px; margin: 0" class="custom-range" max="1" min="0" value="0" step="0.1" id="customRange1" (change)="zoom($event)">-->
    <!--  </div>-->
    <div class="ngx-photo-editor-icons-container flex">
      <div class="mr-auto" [pTooltip]="'IMAGE_EDITOR.CROP' | translate" tooltipPosition="bottom">
        <svg (click)="crop()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d="M17 15h2V7c0-1.1-.9-2-2-2H9v2h8v8zM7 17V1H5v4H1v2h4v10c0 1.1.9 2 2 2h10v4h2v-4h4v-2H7z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.DRAG' | translate" tooltipPosition="bottom">
        <svg (click)="move()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path
            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.ZOOM_IN' | translate" tooltipPosition="bottom">
        <svg (click)="zoomIn()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.ZOOM_OUT' | translate" tooltipPosition="bottom">
        <svg (click)="zoomOut()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
      </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.CHANGE_DIR' | translate" tooltipPosition="bottom">
        <svg (click)="flipV()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.CHANGE_DIR_VERTICAL' | translate" tooltipPosition="bottom">
        <svg (click)="flipH()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.ROTATE_RIGHT' | translate" tooltipPosition="bottom">
        <svg (click)="rotateLeft()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
            d="M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"/>
        </svg>
      </div>
      <div [pTooltip]="'IMAGE_EDITOR.ROTATE_LEFT' | translate" tooltipPosition="bottom">
        <svg (click)="rotateRight()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
            d="M15.55 5.55L11 1v3.07C7.06 4.56 4 7.92 4 12s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91V10l4.55-4.45zM19.93 11c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zM13 17.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z"/>
        </svg>
      </div>
      <div [pTooltip]="'CUSTOMERS.CARD.PRINT' | translate" tooltipPosition="bottom">
        <svg (click)="print()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve">
          <g>
            <path d="M420.931,104.275h-44.166V0H113.236v104.275H69.069C30.982,104.275,0,136.499,0,176.104v123.732   c0,39.615,30.982,71.839,69.069,71.839h33.506V490h284.839V371.675h33.516c38.089,0,69.069-32.224,69.069-71.839V176.104   C490,136.499,459.02,104.275,420.931,104.275z M134.087,20.852h221.827v83.424H134.087V20.852z M366.564,469.149H123.427V290.204   h243.137V469.149z M469.149,299.836c0,28.109-21.635,50.987-48.218,50.987h-33.516v-81.47H102.576v81.47H69.069   c-26.583,0-48.218-22.878-48.218-50.987V176.104c0-28.11,21.635-50.978,48.218-50.978h351.862   c26.583,0,48.218,22.867,48.218,50.978V299.836z"/>
            <rect x="173.997" y="337.975" width="141.997" height="20.852"/>
            <rect x="173.997" y="395.508" width="141.997" height="20.852"/>
            <rect x="366.34" y="173.354" width="31.124" height="20.852"/>
          </g>
          </svg>
      </div>
      <div class="ml-auto" [pTooltip]="'IMAGE_EDITOR.RESET' | translate" tooltipPosition="bottom">
        <svg (click)="reset()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path
            d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/>
        </svg>
      </div>
    </div>
    <div [style.backgroundColor]="darkTheme?'#D8D8D8':'#D8D8D8'" class="ngx-photo-editor-custom-modal-footer py-2">
      <button pButton (click)="export(true);" class="btn btn-primary fade" type="button">שמור</button>
      <button pButton (click)="export();" class="btn btn-primary fade" type="button">הצג תמונה סופית</button>
      <button pButton (click)="imageLoaded = false; reset(true);" [class.btn-outline-dark]="!darkTheme"
              [class.btn-outline-light]="darkTheme"
              class="btn fade"
              type="button">ביטול
      </button>
    </div>
  </ng-template>
  <div class="grid">
    <div class="col-8 my-auto">
      <ng-container [ngTemplateOutlet]="ngxPhotoEditorContent"></ng-container>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div class="col-3 flex layout-invoice-content">
      <img [src]="outputImage" class="m-auto layout-invoice-content" [height]="460"/>
    </div>
  </div>

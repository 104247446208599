import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { enumToArray, PaymentType, TransactionSearchOptions, verificationCodeModel, VerificationStatus } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { VerificationModel } from '../../../kesher-shared/model/verification.model';
import { VerificationService } from '../../../kesher-shared/services/verification.service';
import { TransactionService } from '../../../kesher/services/transaction.service';
import { TransactionsByBankAccountModel, TransactionsByCreditCardModel, TransactionsByMailOrPhoneModel, TransactionsSearchBaseModel } from '../../model/TransactionSearchModel';

@Component({
  selector: 'transaction-search',
  templateUrl: './transaction-search.component.html',
  styleUrls: ['./transaction-search.component.scss']
})
export class TransactionSearchComponent implements OnInit {

  constructor(private _transactionService: TransactionService,
    private _verificationService: VerificationService) { }

  @ViewChild("form") form: NgForm;
  pageName: "TransactionSearchComponent";

  transactionsSearchBaseModel = new TransactionsSearchBaseModel();
  transactionsByCreditCardModel = new TransactionsByCreditCardModel();
  transactionsByBankAccountModel = new TransactionsByBankAccountModel();
  transactionsByMailOrPhoneModel = new TransactionsByMailOrPhoneModel();
  verificationCodeDetails = new verificationCodeModel();
  verificationModel = new VerificationModel();

  verificationStatus = VerificationStatus;

  transactionSearchOptions = TransactionSearchOptions;
  selectedSearchOption = TransactionSearchOptions.BySum;
  transactionSearcOptionshList = [];

  payment = PaymentType;
  selectedPayWayOption = PaymentType.CreditCard;
  payWayOptionsList = [];

  creditCardOptions$: Observable<any>;
  selectedCreditCard: any;

  showCaptchaRrquierd: boolean;
  get sum() {
    return this.selectedPayWayOption == PaymentType.CreditCard ? this.transactionsByCreditCardModel.sum : this.transactionsByBankAccountModel.sum
  }
  set sum(val: number) {
    this.selectedPayWayOption == PaymentType.CreditCard ? this.transactionsByCreditCardModel.sum = val : this.transactionsByBankAccountModel.sum = val;
  }
  get date() {
    return this.selectedPayWayOption == PaymentType.CreditCard ? this.transactionsByCreditCardModel.date : this.transactionsByBankAccountModel.date
  }
  set date(val: Date) {
    this.selectedPayWayOption == PaymentType.CreditCard ? this.transactionsByCreditCardModel.date = val : this.transactionsByBankAccountModel.date = val;
  }

  ngOnInit(): void {

    this.transactionSearcOptionshList = enumToArray(TransactionSearchOptions);
    this.payWayOptionsList = enumToArray(PaymentType);
    this.creditCardOptions$ = this._transactionService.getCreditCardCompanies();

  }

  getSelectedOption(value) {

    this.selectedPayWayOption = value;

  }

  getTransactionBySerch() {
    if (!this.form.valid) {
      this.showErrors();
      return;
    }
    if (!this.transactionsSearchBaseModel.captchaToken) return this.showCaptchaRrquierd = true;      // check captcha!!  // show som messege // unShow after clicked
    switch (this.selectedSearchOption) {
      case TransactionSearchOptions.BySum:
        return this.selectedPayWayOption == PaymentType.CreditCard
          ? this._transactionService.getTransactionsByCreditCard(this.transactionsByCreditCardModel)
          : this._transactionService.getTransactionsByBankAccount(this.transactionsByBankAccountModel)
      case TransactionSearchOptions.ByTelOrMail:
        this.verificationModel = { UserMailOrPhone: this.transactionsByMailOrPhoneModel.userMailOrPhone };
        this.sendVerificationCode();
      // server-get verification code check ... and send if succsess
    }
  }
  sendVerificationCode() {
    this._verificationService.sendVerificationCode(this.verificationModel).subscribe((res: any) => {
      switch (res.Status) {
        case VerificationStatus.VerificationCodeSent:
          this.verificationCodeDetails = { verificationCodeSend: true, encryptedNumMailOrPhone: res.EncryptedNumMailOrPhone, verificationType: null }
          break;
        // case VerificationStatus.BlockedUser:  break;
        // case VerificationStatus.GeneralError: break;
      }
    });
  }
  private showErrors() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].markAsDirty(); // as touch?
    });
    //this._cdr.markForCheck();
  }
}



import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { Issuers } from 'shared/src/enum';
import { ClearingService } from 'projects/shared/src/lib/services/clearing.Service';
import { AdvMessageService } from 'shared/src/common';
import { LocalizationService } from 'shared/src/localization';
import { SwipeCardComponent } from './swipe-card/swipe-card.component';

@Component({
  selector: 'credit-number',
  templateUrl: './credit-number.component.html',
  styleUrls: ['./credit-number.component.scss']
})
export class CreditNumberComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() showSwipeButton: boolean;
  @Input() isRequired: boolean = false;
  @Output() changeExpiryFromSwipeCard = new EventEmitter<Date>();
  @Output() isCheckingCreditCard = new EventEmitter<boolean>();

  @ViewChild('ccNumber') ccNumberField: ElementRef;

  issuers = Issuers;
  loadingIssuer = Issuers[Issuers.Loading]
  currentIssuer = Issuers[Issuers.Hide];
  validators = [Validators.pattern('^[ 0-9]*$')];
  cardNumberIsValid = true;
  isOpendSwipeCard: boolean = false;

  constructor(private clearingService: ClearingService,
    private messagesService: AdvMessageService,
    public dialogService: DialogService,
    private localizationService: LocalizationService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.formGroup && this.formGroup.controls.CreditCard && this.formGroup.controls.CreditCard.value)
      this.checkCardNumber();

    if (typeof (window?.Kesher) != "undefined") {
      try {
        window?.Kesher?.getCard("0");
        window?.Kesher?.getCard("1");
      }
      catch (error) {
      }

    }
    var self: any = this;
    window.addEventListener("message", function (event) {
      //Security: Validate message origin
      // if (event.origin !== 'https://kesherhk.info') {
      //   return;
      // }

      var track = event.data;
      if (track.indexOf('D') !== -1) {
        var card = track.split('D')[0];
        var exp = track.split('D')[1].substring(0, 4);
        let expMonth = exp.substring(2);
        let expYear = '20' + exp.substring(0, 2);
        let creditNum = card.toString();
        let expiry = expMonth + "/" + expYear.substring(2);

        self.formGroup.get('CreditCard').patchValue(creditNum);
        self.onBlur();
        if (expYear && expMonth)
          self.changeExpiryFromSwipeCard.emit(new Date(+expYear, expMonth - 1));
        self.cdr.markForCheck();
        // if (typeof (window?.Kesher) != "undefined") {
        //   try {
        //     window?.Kesher?.getCard("0");
        //   }
        //   catch (error) {
        //   }

        // }
      }
    });
  }

  ngOnChanges(changes) {
    if (this.formGroup) {
      if (this.isRequired == true)
        this.validators.push(Validators.required);
      this.formGroup.addControl("CreditCard", new FormControl("", this.validators));
    }
  }

  creditCardNumberSpacing() {
    //if there is server validator, remove it and put default validator
    //add server validator just after check in server side
    if (!this.cardNumberIsValid) {
      this.formGroup.controls.CreditCard.clearValidators();
      this.formGroup.controls.CreditCard.setValidators(this.validators);
      this.cardNumberIsValid = true;
    }

    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const cardNumber = this.formGroup.controls.CreditCard;

    if (!cardNumber.value) return;

    let trimmedCardNum = cardNumber.value.replace(/\s+/g, '');

    if (trimmedCardNum.length > 19) {
      trimmedCardNum = trimmedCardNum.substr(0, 19);
    }

    /* Handle American Express 4-6-5 spacing */
    let partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
      ? [4, 6, 5]
      : [4, 4, 4, 4, 4];

    if (trimmedCardNum.startsWith('36'))
      partitions = [4, 6, 4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    cardNumber.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < cardNumber.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }

    let cardNumberLength = partitions.reduce((a, b) => a + b, 0);

    if (trimmedCardNum.length < cardNumberLength)
      this.currentIssuer = Issuers[Issuers.Hide];
    else if (trimmedCardNum.length == cardNumberLength)
      this.checkCardNumber();
  }

  checkCardNumber() {
    if (!this.formGroup.controls.CreditCard.valid || !this.formGroup.controls.CreditCard.value || this.formGroup.controls.CreditCard.value.length == 0
      || this.formGroup.controls.CreditCard.value.indexOf('*') > -1) {
      this.currentIssuer = Issuers[Issuers.Hide];
      return;
    }

    this.isCheckingCreditCard.emit(true);
    
    let cardNumber = this.formGroup.controls.CreditCard.value.replace(/\s/g, "");

    this.currentIssuer = Issuers[Issuers.Loading];
    this.clearingService.getCardIssuer(cardNumber).subscribe((res: any) => {
      //there is error - card number is not valid, add validator
      if (res.status > 0) {
        this.formGroup.controls.CreditCard.setValidators(this.validators.concat([() => {
          return { creditNumberNotValid: res.errorMessage };
        }]));
        this.currentIssuer = this.issuers[Issuers.Hide];
        this.cardNumberIsValid = false;
        this.formGroup.controls.CreditCard.updateValueAndValidity();
      }
      else {
        this.currentIssuer = this.issuers[res.entity];
      }
      this.cd.detectChanges();
      this.isCheckingCreditCard.emit(false);
    }, (error) => {
      this.currentIssuer = this.issuers[Issuers.Hide];
      this.isCheckingCreditCard.emit(false);
    });
  }

  onBlur() {
    if (!this.currentIssuer || this.currentIssuer == Issuers[Issuers.Hide])
      this.checkCardNumber();
  }
  //========================Swipe Card===============================//
  openSwipeCardWindow() {

  
    if (typeof (window?.Kesher) != "undefined") {
      try {
        this.formGroup.get('CreditCard').patchValue("");
        this.currentIssuer = Issuers[Issuers.Hide];
        window?.Kesher?.getCard("0");
        window?.Kesher?.getCard("1");
      }
      catch (error) {
      }
      return;
    }


    if (this.isOpendSwipeCard == true) return;
    this.isOpendSwipeCard = true;
    this.formGroup.get('CreditCard').patchValue("");
    this.currentIssuer = Issuers[Issuers.Hide];

    const ref = this.dialogService.open(SwipeCardComponent, {
      // header: this.localizationService.Translate('CREDIT_CARD.PLEASE_SWIPE_CARD'),
      //width: '15%',
      closable: false
    });
    ref.onClose.subscribe((res: any) => {
      this.isOpendSwipeCard = false;
      if (res) {
        this.formGroup.get('CreditCard').patchValue(res.creditNum);
        this.onBlur();
        if (res.expYear && res.expMonth)
          this.changeExpiryFromSwipeCard.emit(new Date(res.expYear, res.expMonth - 1));

      }
    });

    ;
  }
}


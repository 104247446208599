import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DelayMessageService } from './delay-message.service';

@Component({
  selector: 'delay-message',
  templateUrl: './delay-message.component.html',
  styleUrls: ['./delay-message.component.scss']
})
export class DelayMessageComponent implements OnInit, OnDestroy {

  displayDelayMessage = false;
  progressValue = 0;
  interval;
  timeout;

  constructor(private cdr: ChangeDetectorRef, private delayMessageService: DelayMessageService) { }

  ngOnInit(): void {

    this.delayMessageService.onOpenDelayMessage.subscribe(() => {
      this.displayDelayMessage = true;
      let delayMessageMilliseconds = 20000;
      this.progressValue = 0;

      this.interval = setInterval(() => {
        this.progressValue += 1;
        this.cdr.detectChanges();
      }, delayMessageMilliseconds / 120);

      this.timeout = setTimeout(() => {
        clearInterval(this.interval);
        this.displayDelayMessage = false;
        this.delayMessageService.onCloseDelayMessage.emit(false);
        this.cdr.detectChanges();
      }, delayMessageMilliseconds);
    });


  }

  okClick() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.displayDelayMessage = false;
    this.delayMessageService.onCloseDelayMessage.emit(true);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }

}

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { setAuthData, clearUserAuthData, setUser, setCompany, clearCompany } from '../state/actions/user.actions';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { AuthUserInfo } from '../state/reducers/auth-user.reducer';
import { AuthData } from '../model/auth-data.model';
import { Company } from '../model/company.model';


import { COMPANY_STATE_KEY } from '../model/local-storage-keys';
import { clearStoreAfterCompanyChanged } from '../../kesher/state/actions/kehser-common.actions';
import { LoginModel } from '../../login/model/login.model';
import { LoginBySessionModel } from '../../login/model/loginBySession.model';
import { AppState, selecCompanyInCommonState, selectAuthDataCommonState, selectUserInCommonState } from '../../app-state.index';
import { PermissionType } from '../../core/permissions/permission-type.enum';
import { AdvMessageService, DelayMessageService, LoginStatus, Role } from 'projects/shared/src/public-api';
import { CompanySettingsService } from './company-settings.service';




@Injectable()
export class AppAuthService {

    private _authData: AuthData;
    private _company: Company;
    private changePasswordToken: string;
    private _userInfo: AuthUserInfo;
    sessionExpireIntervalMinutes = 20;
    lastUserActionTime;
    refreshSessionTimer: any;
    inactiveUserTimer: any;
    userInactiveAfterMinutes = 15;

    constructor(
        private router: Router, private httpClient: HttpClient,
        private store: Store<AppState>, private delayMessageService: DelayMessageService,
        private ngZone: NgZone,
        private messageService: AdvMessageService,
        private companyService: CompanySettingsService

    ) {
        this.store.select(selectAuthDataCommonState).subscribe((authData: AuthData) => {
            this._authData = authData;
        });
        this.store.select(selecCompanyInCommonState).subscribe((company: any) => {
            this._company = company;
        })
        this.store.select(selectUserInCommonState).subscribe((userInfo: AuthUserInfo) => {
            this._userInfo = userInfo;
        });
        setTimeout(() => {
            this.initExpireSessionTimer();
        }, 1);
    }

    //#region Public getters
    get company(): Company {
        return this._company;
    }
    get userMailOrPhone(): string {
        return this._authData?.userMailOrPhone;
    }
    get authToken(): string {
        return this._authData?.token;
    }
    get lastEntranceDate(): Date {
        return this._authData?.lastEntranceDate;
    }
    get SessionExpiry(): boolean {
        return !this.authToken;
    }
    get isLoggedOn(): boolean {
        return this.isSessionActive;
    }
    get isSessionActive(): boolean {
        return !this.SessionExpiry;
    }
    get userInfo(): AuthUserInfo {
        return this._userInfo;
    }
    get userConnectToChildComponent(): boolean {
        return this._company?.isMyChildCompany;
    }
    //#endregion

    //#region Public Methods
    refreshSession() {
        if (this.refreshSessionTimer) {
            clearInterval(this.refreshSessionTimer)
        }
        this.refreshSessionTimer = setTimeout(() => {
            clearInterval(this.refreshSessionTimer);
            if (this.isLoggedOn) {
                //when session is expired, check if user is inactive
                //if user is inactive - show message if client want remianing in session
                //else - refresh client & server session
                const now = Date.now();
                const timeLeft = parseInt(this.lastUserActionTime) + (5) * 60 * 1000;
                const diff = timeLeft - now;
                const isTimeout = diff < 0;
                if (isTimeout)
                    this.checkClientResponse();
                else
                    this.refreshSession();
            }
        }, (this.sessionExpireIntervalMinutes * 60 * 1000) - 100000);//expire client session 10 minutes before session expire in server
        this.refreshServerSession();
    }

    checkClientResponse() {
        this.delayMessageService.showDelayMessage();
        this.delayMessageService.onCloseDelayMessage.subscribe((result) => {
            if (result) {
                this.refreshSession();
            }
            else
                this.clientLogout();
        });
    }

    //handle user events for check if user is inactive
    //after 5 minutes without events, the user is "INACTIVE" 
    //if user is loggedon check if wants remaining in site 
    userEventHandler() {
        this.lastUserActionTime = Date.now();

        if (this.inactiveUserTimer) {
            clearInterval(this.inactiveUserTimer)
        }
        this.inactiveUserTimer = setTimeout(() => {
            clearInterval(this.inactiveUserTimer);
            if (this.isLoggedOn)
                this.checkClientResponse();
        }, this.userInactiveAfterMinutes * 60 * 1000);
    }

    handleUserEvents() {
        window.addEventListener('mousemove', this.userEventHandler.bind(this));
        window.addEventListener('scroll', this.userEventHandler.bind(this));
        window.addEventListener('keydown', this.userEventHandler.bind(this));
    }

    clearHandleUserEvents() {
        window.removeAllListeners("mousemove");
        window.removeAllListeners("scroll");
        window.removeAllListeners("keydown");
    }

    closeConfirmDialogs() {
        this.messageService.close();
    }

    login(request: LoginModel): Observable<{ status: LoginStatus, companyCode: string, encryptedNumMailOrPhone: string, isClientVersionNotUpdated: boolean }> {
        this.clearLogin();
        return this.httpClient.post<{
            status: LoginStatus,
            token: string,
            company: Company,
            role: Role,
            encryptedNumMailOrPhone: string,
            companyIsMyChildCompany: boolean,
            isClientVersionNotUpdated: boolean
        }>('/auth/login', request).pipe(map((response) => {
            return this.saveLoginData(response, request.userMailOrPhone);
        }));
    }

    refreshServerSession() {
        this.httpClient.post('/auth/refreshSession', {}).subscribe();
    }

    loginBySession(loginModel: LoginBySessionModel) {
        this.clearLogin();
        return this.httpClient.post<any>('/auth/loginBySession', loginModel).pipe(tap((response) => {
            this.saveLoginData(response, response.EncryptedNumMailOrPhone);
        }));
    }

    signup(model: any): Observable<any> {
        return this.httpClient.post('/auth/signup', model);
    }
    updateLoginUser(model: any): Observable<any> {
        return this.httpClient.post('/auth/updateLoginUser', model);
    }
    sendRecoverPasswordVerification(model: LoginModel): Observable<any> {
        return this.httpClient.post('/auth/recoverPasswordVerification', model);
    }

    verifyRecoverPasswordCode(model: any): Observable<any> {
        return this.httpClient.post('/auth/checkVerificationCode', model).pipe(tap(response => {
            this.changePasswordToken = response.token;
        }));
    }

    changePassword(model: any) {
        model.token = this.changePasswordToken;
        return this.httpClient.post('/auth/changePassword', model);
    }

    getOrLoadUserInfo(): Observable<AuthUserInfo> {
        if (this.userInfo) {
            return of(this.userInfo);
        } else {
            return this.getUserInfo();
        }
    }

    getUserInfo(): Observable<AuthUserInfo> {
        return this.httpClient.get<{
            succeeded: boolean,
            identity: number,
            role: Role,
            firstName: string,
            lastName: string,
        }>('/auth/getUserInfo').pipe(tap(async (response: any) => {
            if (response.succeeded) {
                this.store.dispatch(setUser({
                    identity: response.identity,
                    role: response.role,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    mail: response.mail
                }));
                if (response.role == PermissionType.TECHNICIAN) this.router.navigate(['technicians/technician']);
                if (!this._company) {
                    let lastCompany = JSON.parse(localStorage.getItem(COMPANY_STATE_KEY));
                    if (lastCompany && lastCompany.companyId && lastCompany.companyCode && lastCompany.companyName) {
                        let result = await this.companyAllowed(lastCompany.companyCode).toPromise();
                        if (result.isAllowed) {
                            lastCompany.isMyChildCompany = result.isMyChildCompany;
                            this._company = lastCompany;
                            this.store.dispatch(setCompany(this._company));
                        }
                    }
                }
            }
        })).pipe(map(response => {
            return response.succeeded ? {
                identity: response.identity, role: response.role, firstName: response.firstName,
                lastName: response.lastName,
                mail: response.mail, phone: response.phone,
                userNameMail: response.userNameMail,
                address: response.address,
                city: response.city
            } : null;
        }));
    }

    companyAllowed(companyCode: string) {
        return this.httpClient.get<{
            isAllowed: boolean,
            isMyChildCompany: boolean
        }>(`/auth/companyAllowed?companyCode=${companyCode}`);
    }
    logout() {
        this.httpClient.post("/auth/logout", null).subscribe(() => {
            this.clientLogout();
        });
    }

    clientLogout() {
        this.clearHandleUserEvents();
        this.closeConfirmDialogs();
        this.clearLogin();
        this.router.navigate(['/login']);
        this.ngZone.run(() => this.router.navigate(['/login']));
    }

    changeCompany(companyCode: string): Observable<{
        succeeded: boolean, isMyChildCompany: boolean,
        company: { companyID: number, companyCode: string, name: string, userRole: number, parentCompanyId?: number },
        reminder: { body: string }
    }> {
        if (!companyCode) {
            return of(null);
        }
        return this.httpClient.get<{
            succeeded: boolean, isMyChildCompany: boolean,
            company: { companyID: number, companyCode: string, numAssociation: string, name: string, userRole: number, mail: string, parentCompanyId?: number, imgPath: string, showDecodingT: boolean },
            reminder: { body: string }
        }>
            (`/auth/changeCompany?companyCode=${companyCode}`).pipe(tap((response) => {
                this.store.dispatch(clearCompany());
                this.store.dispatch(clearStoreAfterCompanyChanged());
                if (response.succeeded) {
                    this._company = {
                        companyId: response.company.companyID,
                        companyCode: response.company.companyCode,
                        numAssociation: response.company.numAssociation,
                        companyName: response.company.name,
                        companyMail: response.company.mail,
                        isMyChildCompany: response.isMyChildCompany,
                        parentCompanyId: response.company.parentCompanyId,
                        imgPath: response.company.imgPath,
                        registeredEasyCountService: null,
                        showDecodingToken: response.company.showDecodingT

                    };
                    this.store.dispatch(setCompany(this._company));
                }
            }), catchError((error) => {
                this.logout();
                return throwError(error);
            }));
    }
    //#endregion

    //#region Private Methods
    private saveLoginData(loginResponse, userMailOrPhone: string) {
        let companyCode;
        if (loginResponse.status === LoginStatus.Succeeded) {
            this.handleUserEvents();
            this.store.dispatch(setAuthData({
                authData: new AuthData(loginResponse.token, userMailOrPhone, loginResponse.lastEntranceDate),
            }));

            if (loginResponse.reminder)
                setTimeout(() => {
                    this.companyService.openReminder(loginResponse.reminder);
                }, 1000);

            if (loginResponse.company) {
                this._company = {
                    companyId: loginResponse.company.companyID,
                    companyCode: loginResponse.company.companyCode,
                    numAssociation: loginResponse.company.numAssociation,
                    companyName: loginResponse.company.name,
                    companyMail: loginResponse.company.mail,
                    isMyChildCompany: loginResponse.isMyChildCompany,
                    parentCompanyId: loginResponse.company.parentCompanyId,
                    imgPath: loginResponse.company.imgPath,
                    registeredEasyCountService: null,
                    showDecodingToken: loginResponse.company.showDecodingT
                };
                this.store.dispatch(setCompany(this._company));
                companyCode = loginResponse.company.companyCode;
            } else if (loginResponse.role == Role.KesherUser) {
                let lastCompany = JSON.parse(localStorage.getItem(COMPANY_STATE_KEY));
                if (lastCompany && lastCompany.companyCode) {
                    companyCode = lastCompany.companyCode;

                }
            }
        }
        return { status: loginResponse.status, companyCode, encryptedNumMailOrPhone: loginResponse.encryptedNumMailOrPhone, isClientVersionNotUpdated: loginResponse.isClientVersionNotUpdated };

    }
    private initExpireSessionTimer() {
        this.httpClient.get('/auth/getSessionTime/' + this.userMailOrPhone).subscribe((res: any) => {
            //this.sessionExpireIntervalMinutes = res.sessionExpireIntervalMinutes;
            this.sessionExpireIntervalMinutes = res;
            if (this.isLoggedOn) {
                this.refreshSession();
                this.handleUserEvents();
            }
        })
    }
    private clearLogin() {
        this.store.dispatch(clearUserAuthData());
        this.store.dispatch(clearCompany());
    }
    getNewUserMailByToken(token): Observable<any> {
        return this.httpClient.get<{}>
            (`/auth/getNewUserMailByToken?token=${token}`)
    }
    //#endregion
}



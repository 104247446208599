import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompaniesService } from 'projects/kesher-site/src/app/core/services/companies.service';
import { FormService } from 'projects/shared/src/public-api';

@Component({
  selector: 'company-default-tremianl',
  templateUrl: './company-default-tremianl.component.html',
  styleUrls: ['./company-default-tremianl.component.scss']
})
export class CompanyDefaultTremianlComponent implements OnInit {

  @Input() editAble: boolean;
  @Input() terminalId: number;
  defaultTerminal: boolean = false;
  defaultAmexTerminal: boolean = false;
  defaultDinersTerminal: boolean = false;
  hokDefaultTerminal: boolean = false;
  hokDefaultAmexTerminal: boolean = false;
  hokDefaultDinersTerminal: boolean = false;

  defaultTerminalsForm: FormGroup = new FormGroup({
    defaultTerminal: new FormControl(false),
    defaultAmexTerminal: new FormControl(false),
    defaultDinersTerminal: new FormControl(false),
    hokDefaultTerminal: new FormControl(false),
    hokDefaultAmexTerminal: new FormControl(false),
    hokDefaultDinersTerminal: new FormControl(false)
  });

  defaultTerminals: any = {};

  constructor(
    private terminalService: CompaniesService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.getDefaultTerminals();
  }

  getDefaultTerminals() {
    this.terminalService.getDefaultTerminals().subscribe((res: any) => {
      if (res.succeeded) {
        this.defaultTerminals.regullar = res.entity.regullar;
        this.defaultTerminals.amex = res.entity.amex;
        this.defaultTerminals.diners = res.entity.diners;
        this.defaultTerminals.hokRegullar = res.entity.hokRegullar;
        this.defaultTerminals.hokAmex = res.entity.hokAmex;
        this.defaultTerminals.hokDiners = res.entity.hokDiners;

        if (this.terminalId) {
          this.defaultTerminal = this.defaultTerminals.regullar?.id == this.terminalId;
          this.defaultAmexTerminal = this.defaultTerminals.amex?.id == this.terminalId;
          this.defaultDinersTerminal = this.defaultTerminals.diners?.id == this.terminalId;
          this.hokDefaultTerminal = this.defaultTerminals.hokRegullar?.id == this.terminalId;
          this.hokDefaultAmexTerminal = this.defaultTerminals.hokAmex?.id == this.terminalId;
          this.hokDefaultDinersTerminal = this.defaultTerminals.hokDiners?.id == this.terminalId;

          this.defaultTerminalsForm.setValue({
            defaultTerminal: this.defaultTerminals.regullar?.id == this.terminalId,
            defaultAmexTerminal: this.defaultTerminals.amex?.id == this.terminalId,
            defaultDinersTerminal: this.defaultTerminals.diners?.id == this.terminalId,
            hokDefaultTerminal: this.defaultTerminals.hokRegullar?.id == this.terminalId,
            hokDefaultAmexTerminal: this.defaultTerminals.hokAmex?.id == this.terminalId,
            hokDefaultDinersTerminal: this.defaultTerminals.hokDiners?.id == this.terminalId
          })
        }
      }
    })
  }

  saveDefaultTerminals(): Promise<any> {
    return new Promise((resolve, reject) => {
      let request = this.formService.getFormValues(this.defaultTerminalsForm);
      request = {
        ...request,
        terminalId: this.terminalId
      };
      this.terminalService.saveDefaultTerminals(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-first-column-template',
  template:
    `<div class="flex">
      <div>
        <div *ngIf="params.showSelectAll" class="ag-header-select-all" >
        <p-checkbox #selectAllCheckbox value="false"(onChange)="selectAll($event)" [(ngModel)]='selectAllState'></p-checkbox>
        </div>
        <div *ngIf="params.enableSorting" (click)="onSortRequested('asc', $event)" [ngClass]="ascSort" class="customSortDownLabel"><i
            class="fa fa-long-arrow-alt-down"></i></div>
        <div *ngIf="params.enableSorting" (click)="onSortRequested('desc', $event)" [ngClass]="descSort" class="customSortUpLabel"><i
            class="fa fa-long-arrow-alt-up"></i></div>
        <div *ngIf="params.enableSorting" (click)="onSortRequested('', $event)" [ngClass]="noSort" class="customSortRemoveLabel"><i
            class="fa fa-times"></i>
        </div>
      </div>
      </div>
      `,
  // <div pTooltip="{{ filterOpened ? ('GENERAL.HIDDEN_FILTER' | translate) : 'GENERAL.SHOW_FILTER' | translate }}" tooltipPosition="bottom" *ngIf="params.filter" (click)="handleFilter()" [ngClass]="!filterOpened ? 'ag-icon ag-icon-filter' : 'pi pi-undo'"></div>
  // <div pTooltip="{{ 'GENERAL.MENU' | translate }}" tooltipPosition="bottom" *ngIf="params.enableMenu" #menuButton class="customHeaderMenuButton" (click)="onMenuClicked()"><i class="fa {{params.menuIcon}}"></i></div>
  styles: [
    `
    .customHeaderMenuButton,
    .customHeaderLabel,
    .customSortDownLabel,
    .customSortUpLabel,
    .customSortRemoveLabel {
      float: left;
      margin: 0 0 0 3px;
    }

    .customSortUpLabel {
      margin: 0;
    }

    .customSortRemoveLabel {
      font-size: 11px;
    }

    .pi {
      font-size: 12px !important;
    }
    .active {
      color: cornflowerblue;
    }
    div {
      cursor: pointer;
    }
  `
  ]
})
export class FirstColumnTemplateComponent implements IHeaderAngularComp {

  params: any;
  ascSort: string;
  descSort: string;
  noSort: string;
  filterOpened: boolean = false;
  selectAllState: boolean = false;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;
  @ViewChild("selectAllCheckbox") selectAllCheckbox: any;

  agInit(params: any): void {
    this.params = params;
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
    this.filterOpened = this.params.showFilter;
  }

  ngAfterViewInit() {
    this.params.getGridObject()?.rowDataChanged.subscribe(res => {
      this.selectAllState = false;
    })
  }

  handleFilter() {
    this.params.filter();
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  };

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: string, event: any) {
    this.params.setSort(order, event.shiftKey);
  }
  refresh(params: IHeaderParams): boolean {
    return false;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }

  selectAll(event) {
    if (event.checked.length) {
      this.params.api.forEachNode((node) => {
        node.setSelected(true)
      });
    }
    else {
      this.params.api.deselectAll();
    }
  }
}

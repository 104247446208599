export class PaymentPagePaymentType {
    typeId:number;
    name:string;
    creditValue?:string;
    description?:string;
    img?:string;
    index?:number;
    inactive?:boolean;
    isSelected?:boolean;
    disableMessage?:string;
}
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-first-column-template',
  template:
    `
      <div  class="flex gap-2 header">
        <div (click)="onSortRequested($event)" class="flex gap-1">
          <i *ngIf="params.editable" class="my-auto ng-star-inserted pi pi-pencil text-xs"></i>
          <div class="customHeaderLabel">{{ params.displayName }}</div>
          <div *ngIf="params.allowSort">
            <i class="pi sort" [ngClass]="ascSort == 'active' ? 'pi-arrow-up' : descSort == 'active' ? 'pi-arrow-down' : ''"></i>
          </div>          
        </div>
        <div *ngIf="params.column.filterActive" (click)="onMenuClicked()"><i class="ag-icon ag-icon-filter"></i> </div>          
        <div class="mr-auto flex gap-1 actions">
          <i class="fa ag-icon ag-icon-menu" pTooltip="{{ 'GENERAL.MENU' | translate }}" tooltipPosition="bottom" #menuButton  (click)="onMenuClicked()"></i>
          <i *ngIf="params.displayCloseIcon" class="pi pi-times sort my-auto" pTooltip="{{ 'GRID.COLUMNS.HEADER_MENU.TOOLTIP.HIDE' | translate }}" tooltipPosition="bottom" (click)="hideColumn()"></i>
        </div>
      </div>
    `,
  styles: [
    `
    .sort {
      font-size: 12px;
    }

    .actions {
      opacity: 0;
      transition: all 0.2s ease;
      z-index: 9999;
      position: absolute;
      left: 0.6rem;
      color: #f8f8f8 !important;
      padding: 0.2rem;
      border-radius: 6px;
    }

    .customHeaderLabel {
      transition: .2s all ease-in-out;
    }

      app-first-column-template {
        width: 100%;
      }
      .ag-header-cell:hover {
        .actions {
          opacity: 1 !important;
        }
        .customHeaderLabel {
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
   
  `
  ],
 encapsulation: ViewEncapsulation.None

})
export class ColumnHeaderTemplateComponent implements IHeaderAngularComp {

  params: any;
  ascSort: string;
  descSort: string;
  noSort: string;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  };

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(event: any) {
    if (!this.params.allowSort) return;
    this.params.setSort(this.ascSort == 'active' ? 'desc' : this.descSort == 'active' ? '' : 'asc', event.shiftKey);
    this.onSortChanged();
  }

  hideColumn() {
    this.params.columnApi.setColumnsVisible([this.params.column.colId], false)
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void { }
}

<p-dialog (window:keyup.enter)="acceptOnEnter==true?callAccept():''" (window:keyup.escape)="closeModal()"
    [modal]="modal" [styleClass]="class" [(visible)]="visible" [dismissableMask]="dismissableMask" [appendTo]="appendTo"
    [style]="onMobile() ? mobileStyle : style" (onHide)="closeModal()">
    <h5 class="text-center gap-1 header mx-2">
        <i *ngIf="showIcon" class="font-bold ml-2 my-auto pi text-2xl mr-auto modeIcon"
            [class]="'pi-' + getModeIcon()"></i>
        <div class="my-auto ml-auto"> {{ header | translate }} </div>
    </h5>
    <div class="w-full text-center -mt-1 text-gray-600 px-4">{{ subHeader | translate }}</div>
    <div class="my-4 content flex flex-column">
        <ng-content ></ng-content>
        <ng-template #container></ng-template>
    </div>
    <div class="flex w-full dynamic-popup-buttons mt-2"
        [class]="showCancelButton?(spreadButtons ? 'gap-2' : 'gap-1'):'sendButton'">
        <button *ngIf="showCancelButton" pButton class="fade dynamic-popup-cancel p-button-outlined primary"
            [class]="spreadButtons ? 'w-1/2 text-center': 'mr-auto'" (click)="callCancel()"
            [label]="cancelLabel | translate" [icon]="cancelLabel?.length ? '' : cancelIcon"
            [pTooltip]="cancelTooltip | translate" tooltipPosition="bottom"></button>

        <button *ngIf="showAcceptButton && showCancelButton" pButton class="fade dynamic-popup-accept"
            [class]="spreadButtons ? 'w-1/2 text-center': ''" (click)="callAccept()" [disabled]="checkDisabled()"
            [label]="acceptLabel | translate" [icon]="acceptLabel?.length ? '' : acceptIcon"
            [pTooltip]="acceptTooltip | translate" tooltipPosition="bottom"></button>
        <button *ngIf="showAcceptButton && !showCancelButton" pButton class="fade dynamic-popup-accept singleButton"
            (click)="callAccept()" [disabled]="checkDisabled()" [label]="acceptLabel | translate"
            [icon]="acceptLabel?.length ? '' : acceptIcon" [pTooltip]="acceptTooltip | translate"
            tooltipPosition="bottom"></button>
    </div>
</p-dialog>
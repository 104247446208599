export const environment = {
  version:"3.0.5",
  production: true,
  //apiBaseUrl: 'https://apitest.kesherhk.info/',
  
 apiBaseUrl: 'https://api2022.kesherhk.info/',

};




import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CurrencyType, EnumService, InfoPanelFormatOptions } from 'shared/src/enum';
import { LocalizationService } from 'shared/src/localization';
import { InfoPanelFormat } from '../../model/info-panel-format.model';

const REQUIRED_SIGN = '*';

@Component({
  selector: 'info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})

export class InfoPanelComponent implements OnInit {

  constructor(
    private localizationService: LocalizationService,
    private enumService: EnumService
  ) { }

  private _formControl: FormControl;

  @Input() set control(value: FormControl) {
    if (value) {
      this._formControl = value;
      if (!this.value?.length) this.value = value.value;
    }
  }

  get control(): FormControl {
    return this._formControl;
  }

  @Input() description: string = "";

  @Input() value: string = "";

  @Input() autoFormat: InfoPanelFormat;

  @Input() format: (any) => string = () => {
    if (this.autoFormat) {
      switch (this.autoFormat.formatOption) {
        case InfoPanelFormatOptions.Date:
          return this.value ? moment(this.value).format('DD/MM/yyyy') : null;

        case InfoPanelFormatOptions.Currency:
          return `${this.value} ${this.getTranslation("ENUMS.CurrencyType." + CurrencyType[this.autoFormat.params])}`;

        case InfoPanelFormatOptions.Payments:
          return this.value && this.value != "9999" ? this.value : "ללא הגבלה";

        case InfoPanelFormatOptions.Enum:
          return this.getTranslation(`ENUMS.${this.enumService.getEnumName(this.autoFormat.params)}.${this.autoFormat.params[this.value]}`);


      }
    }
    return this.value;
  }

  ngOnInit(): void {
  }

  getRequiredSign() {
    return this.control?.hasValidator(Validators.required) ? REQUIRED_SIGN : '';
  }

  private getTranslation(translationWord, parameter?) {
    return this.localizationService.Translate(translationWord, parameter)
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { PermissionsService } from 'projects/kesher-site/src/app/core/permissions/permissions.service';

import { Subscription } from 'rxjs';
import { asyncFilter, LocalizationService } from 'shared/src/public-api';
import { AppState, selecCompanyInCommonState } from '../../../app-state.index';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { MenuService } from './menu.service';
import { PermissionMenuItem } from './permission-menu-item';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AppMenuComponent implements OnDestroy {

  constructor(public localizationService: LocalizationService, private _cdr: ChangeDetectorRef, public app: DefaultLayoutComponent,
    private store: Store<AppState>, private permissionsService: PermissionsService,
    protected route: ActivatedRoute, private menuService: MenuService) {
    this.companySubscription = this.store.select(selecCompanyInCommonState).subscribe((company) => {
      if (company) {
        if (this.companyCode !== company.companyCode) {
          this.companyCode = company.companyCode;
          this.numAssociation = company.numAssociation;
          this.initMenu();
        }
      }
      else
        this.companyCode = null;

      if (!this.items) {
        this.initMenu();
      }

      this.initMenuByPermissios();
    });
  }
  companyCode: string;
  fullMenuItems: PermissionMenuItem[];
  @Input() activeMenuItem: PermissionMenuItem;
  items: MenuItem[];
  companySubscription: Subscription;
  numAssociation: string;
  initMenuByPermissios() {
    this.getPermittedMenuItems(this.fullMenuItems).then(menuItems => {
      this.items = menuItems.filter(item=>item.items && item.items.length > 0);
      // if (this.items && this.items.length > 0)
      //   this.activeMenuItem = this.items[0];
      this._cdr.markForCheck();
    });
  }
  async getPermittedMenuItems(items: PermissionMenuItem[]): Promise<MenuItem[]> {
    let permittedItems = items.filter(item => !this.companyCode ? item.allowWithoutCompany : item);
    permittedItems = await asyncFilter(permittedItems,
      async (item) => !item.permissionType || await this.permissionsService.permissionExists(item.permissionType).toPromise());

    return await Promise.all(permittedItems.map(async (item) => {
      const menuItem = { label: item.label, routerLink: item.routerLink, icon: item.icon, items: null };
      if (item.items) {
        menuItem.items = await this.getPermittedMenuItems(item.items);
      }
      return menuItem;
    }));
  }


  initMenu() {
    this.fullMenuItems = this.menuService.initMenu(this.companyCode, this.numAssociation);
  }
  screenBlocked: boolean = false;

  ngAfterViewInit() {
    // if (this.items && this.items.length > 0)
    //   this.activeMenuItem = this.items[0];
    // this._cdr.markForCheck();

  }
  blockScreen() {
    this.screenBlocked = true;
    this._cdr.markForCheck();
  }

  onSelectSubMenuItem(status) {
    this.unBlockScreen();
  }

  unBlockScreen() {
    this.screenBlocked = false;
    this._cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
}

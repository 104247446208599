import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EnumService } from "./enum.service";




@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule

  ],
  exports:[
  
  ],
  providers: [
    EnumService
    ]
})

export class EnumModule {
  static forChild(): ModuleWithProviders<EnumModule> {
    return {
      ngModule:EnumModule,
      providers:[
        EnumService
      ]
    }
  }
}

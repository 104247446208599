import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  // Retrives form values by iterating form controls
  // This function replaces the using of form.values, because form.values is missing values of controls which their value was declared as an inner object (like disabled, etc.)
  getFormValues(form: any) {
    if (!form.controls) return form.value;
    if (form.controls.length >= 0) {
      var array=[];
      form.controls.forEach(element => {
        array.push(element.value);
      });
      return array;
    }
    return Object.assign({}, ...Object.entries(form.controls).map(([key, val]) => ({ [key]:this.getFormValues(val)  })));
  }

  // Usual actions that should run after saving a form
  afterFormSaved(form: FormGroup) {

    // Mark form as undirty and untouched
    form.markAsPristine();
    form.markAsUntouched();
  }
}

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectAllOnFocusService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.listenToFocusEvents();
  }

  listenToFocusEvents() {
    this.renderer.listen('document', 'focusin', (event: FocusEvent) => {
      const target = event.target as HTMLInputElement;
      if (target && (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA')) {
        target.select();
      }
    });
  }
}




import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';


@Component({
    selector: 'customFilter',
    template: `
    <div style="width: 100%">
        <p-multiSelect  class="select" 
            [(ngModel)]="selectedValues" [options]="values" (ngModelChange)="onSelect()" display="chip" #input 
            optionLabel="name" optionValue="id" appendTo="body"></p-multiSelect>
       </div>
  `,
    styles: [`
    :host ::ng-deep .p-multiselect {    
        width: 100%
    }
`]
})
export class CustomListFilterComponent implements IFilterAngularComp {
    params!: IFilterParams;
    filterText = '';
    selectedValues: [] = [];
    values;

    @ViewChild('input', { read: ViewContainerRef })
    public input!: ViewContainerRef;

    agInit(params: any): void {
        this.params = params;
        if (Array.isArray(params.values)) {
            this.values = params.values;
            this.removeAllOption();
        }
        else {
            params.values.subscribe(data => {
                this.values = data;
                this.removeAllOption();
            });
        }
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        // make sure each word passes separately, ie search for firstname, lastname   
        return true;
    }

    isFilterActive(): boolean {
        return this.selectedValues != null && this.selectedValues.length > 0;
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }

        return { filterType: "set", values: this.selectedValues };
    }

    setModel(model: any) {
        this.selectedValues = model == null ? null : model.value;
    }

    onSelect() {
        this.params.filterChangedCallback();
    }

    private removeAllOption() {
        if (this.values) {
            let vals = [...this.values];
            let allIndex = vals.findIndex(v => v.name == "הכל");
            if (allIndex > -1) {
                vals.splice(allIndex, 1);
                this.values = vals;
            }
        }
    }
}
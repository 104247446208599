<div class="form-group creditCard" [formGroup]="formGroup" >
    <div class="p-field p-float-label ltr" [ngClass]="showSwipeButton ? 'p-inputgroup' : ''" style="display: flex;">
        <button style="border: 1px solid #0F74B5;width: 3rem;" *ngIf="showSwipeButton" type="button" class="text-sm p-0" pButton pRipple
         (click)="openSwipeCardWindow()"
         icon="swipeCardIcon" 
         class="bg-white p-1 no-label"
                    iconPos="left" 
                    pTooltip="{{'CREDIT_CARD.SWIPE_CARD' | translate}}"
                    tooltipPosition="bottom"
         ></button>
        <input class="creditInput" [ngClass]="showSwipeButton ? 'inputWithButton' : ''" id="creditCard" pInputText name="creditCard" formControlName="CreditCard"
            autocomplete="cc-number" maxlength="23" #ccNumber class="w-full creditInput" (keyup)="creditCardNumberSpacing()"
            (blur)="onBlur()" type="tel"/>
        <label for="creditCard" style="direction: rtl;">{{'CREDIT_CARD.CREDIT_NUMBER' | translate}}</label>
        <p class="{{showSwipeButton!=true ? 'issuerImg' :loadingIssuer!=currentIssuer? 'issuerImgWithSwipe':'issuerLoadingImgWithSwipe'}}" [ngClass]="currentIssuer"></p>

    </div>
</div>
<!-- CreditCard -->



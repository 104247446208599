<div class="grid m-auto mt-3 flex flex-column">
    <div *ngIf="hasExampleFile||exampleFileURL" class="m-auto text-center">להורדת קובץ דוגמא
        <button (click)="createExampleFile()" 
            class="underline downloadExampleBtn fw-700 p-0 p-component ">לחץ כאן</button>
    </div>
    <p style="color: red;" class="m-auto">{{warning}}</p>
    <p style="white-space: pre-line">{{moreInfo}}</p>
    <p-fileUpload [disabled]="disabled" class="m-auto" #fubauto name="demo[]" [showUploadButton]="false" (onSelect)="onFileChange($event)" 
    (onClear)="onFileRemoved($event)"  (onRemove)="onFileRemoved($event)"
        accept=".xls,.xlsx" chooseLabel="{{chooseLabel}}" cancelLabel="ביטול" [previewWidth]="0"
        invalidFileTypeMessageSummary="{{'UPLOAD.ERRORS.INVALID_FILE_TYPE_DETAILS'| translate}}"
        invalidFileSizeMessageDetail="" invalidFileTypeMessageDetail=""></p-fileUpload>
</div>  
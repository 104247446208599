import {
  ChangeDetectorRef, Component,
  ComponentFactoryResolver,
  ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, Type, ViewChild, ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicPopupMode } from 'shared/src/enum';
import { popupData } from '../../model/popupData.model';

@Component({
  selector: 'dynamic-popup',
  templateUrl: './dynamic-popup.component.html',
  styleUrls: ['./dynamic-popup.component.scss'],
})
export class DynamicPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  // Defines primeNg visible property
  private _visible: boolean = false;
  @Input() set visible(value: boolean) {
    if (this._visible != value) {
      this._visible = value;
      if (this._visible)
        this.getContentMethods();
    }
  }
  get visible(): boolean {
    return this._visible;
  }

  @Input() acceptOnEnter: boolean = true;
  // Text for accept button
  @Input() acceptLabel: string = 'GENERAL.OK';

  // Icon for accept button
  // * Will be hidden if accept label is set:
  @Input() acceptIcon: string = '';

  // Tooltip for accept button
  @Input() acceptTooltip: string = '';

  // Defines primeNg appendTo property
  @Input() appendTo: string = 'body';

  // Text for cancel button
  @Input() cancelLabel: string = 'GENERAL.CANCEL';

  // Icon for accept button
  // * Will be hidden if cancel label is set:
  @Input() cancelIcon: string = 'pi pi-times';

  // Tooltip for cancel button
  @Input() cancelTooltip: string = '';

  @Input() class: string = '';

  // Whether to close modal on save
  @Input() closeOnSave: boolean = true;

  // Defines primeNg dismissableMask property: 
  @Input() dismissableMask: boolean = false;

  // Text for popup header
  @Input() header: string = '';

  // Defines primeNg modal property
  @Input() modal: boolean = true;

  // Mode - defines the icon in the header
  @Input() mode: DynamicPopupMode = DynamicPopupMode.Success;

  // Whether to display icon in the header
  @Input() showIcon: boolean = true;

  // Defines primeNg style property
  // * Has no effect on mobile
  @Input() style: any = {};

  // Defines primeNg style property on mobile
  @Input() mobileStyle: any = {};
  // Text for popup sub header
  @Input() subHeader: string = '';

  // Whether to spread buttons on all footer width
  @Input() spreadButtons: boolean = true;

  // Whether to display accept button
  @Input() showAcceptButton: boolean = true;

  // Whether to display cancel button
  @Input() showCancelButton: boolean = true;

  //Whether the submit button allowed
  @Input() disabled: boolean = false;

  //Whether to activate a cancel function when closing
  @Input() callCancelOnClose: boolean = true;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() accept: EventEmitter<any> = new EventEmitter();
  @Output() callBack: EventEmitter<any> = new EventEmitter();

  @ContentChild("content") content: any;

  @ViewChild("container", { read: ViewContainerRef }) container: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver) { }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

  open(data: { component: Type<any>, componentData?: {}, popupData: popupData }) {
    this.closeModal();
    let factory: any =
      this.resolver.resolveComponentFactory(data.component);
    let ref = this.container.createComponent(factory);
    this.content = ref.instance;
    Object.keys(data.popupData).forEach(key => {
      if (this[key]?.hasOwnProperty("observers")) {
        this[key] = new EventEmitter();
        this.subscriptions.add(this[key].subscribe(data.popupData[key]));
      }
      else
        this[key] = data.popupData[key];
    })
    Object.keys(data.componentData).forEach(key => {
      ref.instance[key] = data.componentData[key];
    })
    this.visible = true;
    this.cdr.markForCheck();
  }

  acceptMethod(): any { };

  checkDisabled() {
    return false || this.disabled;
  }

  closeModal() {
    if (this.callCancelOnClose && this.cancel)
      this.cancel.emit();
    this.close();
  }

  close() {
    this.container.clear();
    this.visible = false;
    this.cdr.markForCheck();
  }

  callCancel() {
    if (this.cancel)
      this.cancel.emit();
    this.close();
  }

  async callAccept() {
    if (!this.visible)
      return;

    if (this.content?.acceptAsync) {
      this.subscriptions.add(this.content.acceptAsync?.subscribe(res => {
        if (res)
          this.afterAccept(res);
      }));
    }
    // Call accept method, and await to result if needed:
    let result = await this.acceptMethod();

    if (!this.content?.acceptAsync) {
      if (result != false)
        this.afterAccept(result);
    }

  }

  getModeIcon() {
    switch (this.mode) {
      case DynamicPopupMode.Success:
        return "check";

      case DynamicPopupMode.Error:
        return "times";

      case DynamicPopupMode.Warn:
        return "info-circle";

      case DynamicPopupMode.Add:
        return "plus-circle";

      case DynamicPopupMode.Info:
        return "file";
      case DynamicPopupMode.Edit:
        return "pencil";
      case DynamicPopupMode.SendMail:
        return "send";
     case DynamicPopupMode.EditUser:
        return "user-edit";}
  }

  onMobile() {
    return window.innerWidth < 790;
  }

  private afterAccept(res) {
    console.log(this.accept.name)
    if (this.accept) {
      this.accept.emit(res);

      // Close on save if needed:
      if (this.closeOnSave) {
        this.closeModal();
      }
    }

    else if (res) //result of saved is true - succeded
      this.closeModal();
  }

  private getContentMethods() {
    setTimeout(() => {
      if (this.content) {
        this.initDisableMethod();
        this.initAcceptMethod();

      }
    }, 100);
    if (this.content) {
      this.listenToContentEvents();
    }
  }

  private initAcceptMethod() {
    // if (this.content.instance) {
    //   this.acceptMethod = this.content.instance.acceptMethod?.bind(this.content.instance) ?? this.acceptMethod;
    // }
    // else
    this.acceptMethod = this.content.acceptMethod?.bind(this.content) ?? this.acceptMethod;
  }

  private initDisableMethod() {
    this.checkDisabled = this.content.disabled?.bind(this.content) ?? this.checkDisabled;
  }

  private listenToContentEvents() {
    this.subscriptions.add(this.content.cancel?.subscribe(() => {
      this.callCancel();
    }));

    // this.content.accept?.subscribe(() => {
    //   this.callAccept();
    // })
  }


}


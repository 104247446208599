import { createAction, props } from '@ngrx/store';

export const loadProjects = createAction('loadProjects');
export const loadProjectsSuccess = createAction('loadProjectsSuccess', props<{ projects: any[] }>());
export const loadProjectsWithPaymentPages = createAction('loadProjectsWithPaymentPages');
export const loadProjectsWithPaymentPagesSuccess = createAction('loadProjectsWithPaymentPagesSuccess', props<{ projectsWithPaymentPages: any[] }>());
export const loadRedirectPages = createAction('loadRedirectPages');
export const loadRedirectPagesSuccess = createAction('loadRedirectPagesSuccess', props<{ redirectPages: any[] }>());
export const loadPaymentPages = createAction('loadPaymentPages');
export const loadPaymentPagesSuccess = createAction('loadPaymentPagesSuccess', props<{ paymentPages: any[] }>());
export const loadTerminals = createAction('loadTerminals');
export const loadTerminalsSuccess = createAction('loadTerminalsSuccess', props<{ terminals: any[] }>());
export const loadPaymentTypes = createAction('loadPaymentTypes');
export const loadPaymentTypesSuccess = createAction('loadPaymentTypesSuccess', props<{ paymentTypes: any[] }>());
export const loadCustomerFields = createAction('loadCustomerFields');
export const loadCustomerFieldsSuccess = createAction('loadCustomerFieldsSuccess', props<{ customerFields: any[] }>());
export const loadTerminalsCurrencies = createAction('loadTerminalsCurrencies');
export const loadTerminalsCurrenciesSuccess = createAction('loadTerminalsCurrenciesSuccess', props<{ terminalsCurrencies: any[] }>());
export const loadCompanyDynamicFields = createAction('loadCompanyDynamicFields');
export const loadCompanyDynamicFieldsSuccess=createAction('loadCompanyDynamicFieldsSuccess', props<{ companyDynamicFields: any[] }>())
export const clearStoreAfterCompanyChanged = createAction('clearStoreAfterCompanyChanged');




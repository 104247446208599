// app/button-cell-renderer.component.ts

import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { IAfterGuiAttachedParams, ICellRendererParams } from "@ag-grid-community/core";
import { Component, OnDestroy } from "@angular/core";
import { _createStoreReducers } from "@ngrx/store/src/store_module";


@Component({
  selector: 'btn-cell-renderer',
  template:
    `<button pButton (click)="btnClickedHandler()" [label]="params.context" tooltipPosition="bottom" [pTooltip]="params.tooltip" class="p-button-outlined" [ngClass]="!params.context ? 'p-button-icon-only ' : (params.icon ? 'has-both-icon-and-label' : '')" [icon]="params.icon"></button>`,
  styles: [
    ` .has-both-icon-and-label {
      display: flex;
      gap: .6rem;
      cursor: pointer;
    }`
  ]
})
//{{params.context}}</div>
export class BtnCellRenderer implements ICellRendererAngularComp, OnDestroy {
  refresh(params: ICellRendererParams): boolean { return false }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Method not implemented.");
  }

  constructor() { }

  params: any;
  agInit(params: any): void {
    this.params = params;

  }
  ngAfterViewInit() {
  }

  btnClickedHandler() {
    this.params.clicked(this.params);
  }

  ngOnDestroy() {
  }
}
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalizationService } from 'projects/shared/src/public-api';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../../app-state.index';
import { PermissionType } from '../../permissions/permission-type.enum';

@Injectable()
export class MenuService {
  constructor(private store: Store<AppState>,
    private localizationService: LocalizationService
  ) { }

  private menuSource = new Subject<string>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();
  company$: Observable<any>;
  company: any;
  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next();
  }

  initMenu(companyCode: string, numAssociation: string) {
    return [
      {
        label: this.localizationService.Translate(numAssociation?.startsWith('580') ? 'MENU.DONORS' : 'MENU.CUSTOMERS'),
        icon: 'sm_customers.png',
        items: [
          {
            label: this.localizationService.Translate(numAssociation?.startsWith('580') ? 'MENU.DONORS' : 'MENU.CLIENT_LIST'),
            routerLink: [`/kesher/${companyCode}/customers`], icon: 'pi pi-users'
          },
          //{ label: this.localizationService.Translate('MENU.MASAV'), routerLink: [`/kesher/${companyCode}/masav`], icon: 'pi pi-dollar' }]

        ]
      },
      {
        label: this.localizationService.Translate('MENU.MONEY'),
        icon: "sm_payment.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.OBLIGATION'),
            routerLink: [`/kesher/${companyCode}/obligations`], icon: 'pi pi-money-bill'
          },
          {
            label: this.localizationService.Translate('MENU.TRANSACTION'),
            routerLink: [`/kesher/${companyCode}/transactions`],
            icon: 'pi pi-dollar',

          },
          {
            label: this.localizationService.Translate('MENU.REPORTS'),
            routerLink: [`/kesher/${companyCode}/reports`],
            icon: 'pi pi-dollar',

          },
          {
            label: this.localizationService.Translate('MENU.MANAGERS_REPORT'),
            routerLink: [`/kesher/${companyCode}/kesher-manager-reports`],
            icon: 'pi pi-list',
            allowWithoutCompany: true,
            permissionType: PermissionType.MANAGMENT_MODULE,
            tooltip: "מוצג רק למנהלי קשר",

          }
        ],
      },
      {
        label: this.localizationService.Translate('MENU.PAYMENT_PAGE'),
        icon: "forms.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.PAYMENT_PAGES'),
            routerLink: [`/kesher/${companyCode}/projects`],
            icon: 'pi pi-credit-card'
          },
          {
            label: this.localizationService.Translate('MENU.CATEGORY_MANAGMENT'),
            routerLink: [`/kesher/${companyCode}/category-managment`],
            icon: 'pi pi-file'
          },

        ],
      },
      {
        label: this.localizationService.Translate('MENU.CAMPAIGNS'),
        icon: "campaign-settings.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.CAMPAIGNS_OPTIONS'),
            routerLink: [`/kesher/${companyCode}/campaigns`],
            icon: 'pi pi-credit-card'
          },
        ]
      },
      {
        label: 'מכירות',
        icon: "sale-managment.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.SALES_MANAGEMENT'),
            routerLink: [`/kesher/${companyCode}/salesManagment`],
            icon: 'pi pi-credit-card'
          },
          {
            label: this.localizationService.Translate('MENU.PRODUCTS_CATEGORIES'),
            routerLink: [`/kesher/${companyCode}/categories`], icon: 'pi pi-credit-card'
          },
          {
            label: this.localizationService.Translate('MENU.PRODUCTS'), routerLink: [`/kesher/${companyCode}/salesProducts`],
            icon: 'pi pi-credit-card'
          },
          {
            label: this.localizationService.Translate('MENU.CLUB_MEMBERS'),
            routerLink: [`/kesher/${companyCode}/club-members`],
            icon: 'pi pi-credit-card'
          },
        ]
      },
      {
        label: this.localizationService.Translate('MENU.MANAGMENT'),
        icon: "kesher-new-icon.png",
        tooltip: "מוצג רק למנהלי קשר",
        items: [

          {
            label: this.localizationService.Translate('MENU.GENERAL_REPORTS'),
            routerLink: ['/management/general-reports'],
            icon: 'pi pi-list',
            allowWithoutCompany: true,
          },
          {
            label: this.localizationService.Translate('MOBILES.MOBILES'),
            routerLink: ['/management/mobiles'],
            icon: 'pi pi-mobile',
            allowWithoutCompany: true,
            permissionType: PermissionType.MOBILES
          },
          {
            label: this.localizationService.Translate('TRANSACTIONS.DUPLICATE_TRANSACTIONS.DUPLICATE_TRANSACTIONS'),
            routerLink: ['/management/duplicate-transactions'],
            icon: 'pi pi-dollar',
            allowWithoutCompany: true
          },
          {
            label: this.localizationService.Translate('MENU.BROADCAST_FILES'),
            routerLink: ['/management/broadcast-files'],
            icon: 'pi pi-money-bill',
            allowWithoutCompany: true,
            permissionType: PermissionType.PROACTIVE_BROADCAST
          },
          {
            label: this.localizationService.Translate('MENU.PROACTIVE_BROADCAST'),
            routerLink: ['/management/proactive-broadcast'],
            icon: 'pi pi-money-bill',
            allowWithoutCompany: true,
            permissionType: PermissionType.PROACTIVE_BROADCAST
          },
          {
            label: this.localizationService.Translate('MENU.TEMPORARY'),
            routerLink: ['/management/temporary'],
            icon: 'pi pi-exclamation-triangle',
            allowWithoutCompany: true,
          },
          {
            label: this.localizationService.Translate('MENU.ACCESS_TRACKING'),
            routerLink: ['/management/access-tracking'],
            icon: 'pi pi-list',
            allowWithoutCompany: true,
          },
          {
            label: this.localizationService.Translate('MENU.USERS'),
            routerLink: ['/management/users'],
            icon: 'pi pi-list',
            allowWithoutCompany: true,
          }
          // ,
          // {
          //   label: this.localizationService.Translate('MENU.GENERAL_TRANSACTIONS_ANALYSIS'),
          //   routerLink: ['/management/general-transactions-analysis'],
          //   icon: 'pi pi-list',
          //   allowWithoutCompany: true,
          // }           
        ], allowWithoutCompany: true, permissionType: PermissionType.MANAGMENT_MODULE
      },
      {
        label: this.localizationService.Translate('MENU.FAULT_MANAGEMENT'),
        icon: "sm_setting.png",
        items: [
          {
            label: "קריאות שירות",
            routerLink: ['/technicians/technician'],
            icon: 'pi pi-mobile',
            allowWithoutCompany: true,
            permissionType: PermissionType.TECHNICIAN
          }
        ], allowWithoutCompany: true, permissionType: PermissionType.TECHNICIAN
      },
      {
        label: this.localizationService.Translate('MENU.KEHILOT'),
        icon: "transparent-kehilot.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.SPECIAL_LABELS'),
            routerLink: [`/kesher/${companyCode}/manage-labels/special`],
            icon: 'pi pi-mobile', allowWithoutCompany: true
          },
          {
            label: this.localizationService.Translate('MENU.PRESS_COUNTER'),
            routerLink: [`/kesher/${companyCode}/labels-press-history`],
            icon: 'pi pi-mobile', allowWithoutCompany: true,
            permissionType: PermissionType.MANAGMENT_MODULE
          }
        ], allowWithoutCompany: false, permissionType: PermissionType.MANAGE_LABELS
      },

      {
        label: this.localizationService.Translate('MENU.ADDITIVES'),
        icon: "sm_general.png",
        items: [
          {
            label: this.localizationService.Translate("MENU.RECEIPT_SYSTEM"),
            routerLink: [`/kesher/${companyCode}/receiptSystem`],
          },
          {
            label: this.localizationService.Translate('MENU.ADDITIONAL_ACTIONS_OPTIONS.DIGITAL_FORMS'),
            routerLink: [`/kesher/${companyCode}/digital-forms`],
            icon: 'pi pi-file'
          },
          {
            label: this.localizationService.Translate("MENU.KESHER_PHONE"),
            routerLink: [`/kesher/${companyCode}/SMSPhone`],
          }
          ,
          {
            label: this.localizationService.Translate('MENU.BANK_AUTH'),
            routerLink: [`/kesher/${companyCode}/bankAuth`],
            icon: 'pi pi-list',
            // permissionType: PermissionType.AUTH_BANK_PAGE
          }
        ]
        , allowWithoutCompany: true
      },
      {
        label: this.localizationService.Translate('MENU.DEVELOPERS'),
        icon: "sm_general.png",
        items: [
          {
            label: this.localizationService.Translate('MENU.API_FOR_DEVELOPERS'),
            routerLink: [`/kesher/${companyCode}/api-developers`]
          },
          {
            label: this.localizationService.Translate('MENU.WOOCOMMERCE_PLUGIN'),
            routerLink: [`/kesher/${companyCode}/woocommercen-plugin`]

          },
          {
            label: this.localizationService.Translate('MENU.KESHER_PHONE'),
            routerLink: [`/kesher/${companyCode}/SMSPhoneAdvertisement`]
          },
        ]
      },
      {
        label: this.localizationService.Translate('MENU.ACCOUNTANCY'),
        icon: "sm_general.png",
        tooltip: "מוצג רק למנהלי קשר",
        items: [
          {
            label: this.localizationService.Translate('MENU.GENERAL'),
            routerLink: [`/kesher/${companyCode}/summary`],
            icon: 'pi pi-money-bill',
            permissionType: PermissionType.PAYMENT_SUMMERY
          },
          {
            label: this.localizationService.Translate('MENU.CUSTOMER_LEDGER'),
            routerLink: [`/kesher/${companyCode}/customerLedger`],
            icon: 'pi pi-money-bill',
            permissionType: PermissionType.PAYMENT_SUMMERY
          },
          // {
          //   label: this.localizationService.Translate('MENU.KESHER_PRODUCTS'),
          //   routerLink: [`/kesher/${companyCode}/kesherProductsReports`],
          //   icon: 'pi pi-money-bill',
          //   permissionType: PermissionType.PAYMENT_SUMMERY
          // },
          {
            label: this.localizationService.Translate('MENU.EXCEL_REPORTS'),
            routerLink: [`/kesher/${companyCode}/excelReports`],
            icon: 'pi pi-money-bill',
            permissionType: PermissionType.PAYMENT_SUMMERY
          },
          // {
          //   label: this.localizationService.Translate('MENU.ACCOUNTANCY_ANALYSIS'),
          //   routerLink: [`/kesher/${companyCode}/accountancyAnalysis`],
          //   icon: 'pi pi-money-bill',
          //   permissionType: PermissionType.PAYMENT_SUMMERY
          // }
        ], allowWithoutCompany: false,
      }
    ];
}
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'tel-or-mail',
  templateUrl: './tel-or-mail.component.html',
  styleUrls: ['./tel-or-mail.component.scss']
})
export class TelOrMailComponent implements OnInit {

  constructor() { }

  //#region @Input/@Output Members
  private _telOrMailNum: string;
  @Output() telOrMailNumChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() get telOrMailNum(): string {
    return this._telOrMailNum;
  }
  set telOrMailNum(val: string) {
    this._telOrMailNum = val;
    this.telOrMailNumChange.emit(val);
  }
  //#endregion

  //#region Angular lifecycle Methods
  ngOnInit(): void {
  }
  //#endregion
}

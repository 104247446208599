import { Injectable } from '@angular/core';
import {  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionType } from './permission-type.enum';
import { PermissionsService } from './permissions.service';

@Injectable()
export class PermissionsGuard implements CanActivate {
    constructor(private permissionsService: PermissionsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :Observable<boolean> {
        let permissionType = route.data["permissionName"] as PermissionType;
        return permissionType && this.permissionsService.permissionExists(permissionType)
    }


}

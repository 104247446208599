import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {


  constructor() { }

  readExcelFile(file: File, sheetName: string, columnNames: any[], headerRow: number = 0): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let jsonData: any[] = [];
      const resultList: any[] = [];

      fileReader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array', cellDates: true });
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Find the column indexes for the provided column names
        const columnIndexes: number[] = [];
        for (let columnName of columnNames) {
          const columnIndex: number = jsonData[headerRow - 1].indexOf(columnName.name);
          columnIndexes.push(columnIndex);
        }

        // Create a list of objects with the specified column names and values
        if (jsonData[jsonData.length - 1] == "")
          jsonData.pop()
        for (let i = headerRow; i < jsonData.length; i++) {
          const row: any[] = jsonData[i];
          const resultItem: any = {};
          for (let j = 0; j < columnIndexes.length; j++) {
            const columnIndex: number = columnIndexes[j];
            const columnName: string = columnNames[j].value;
            let cellValue: any;
            switch (columnNames[j].type) {
              case "string": { cellValue = row[columnIndex]?.toString(); break; }
              case "tel": { cellValue = this.addingPrefix0IfNone(row[columnIndex]); break; }
              case "int": { cellValue = row[columnIndex] ? Number(row[columnIndex]) : 0; break; }
              default: { cellValue = row[columnIndex]; break; }
            }
            resultItem[columnName] = cellValue ?? null;
          }
          resultList.push(resultItem);
        }
        resolve(resultList);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  addingPrefix0IfNone(value: any): any {
    if (value == null)
      return value
    if (value?.toString().charAt(0) == '0')
      return value?.toString();
    else return "0" + value?.toString()
  }
  createExcelFile(data: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const workbook = { Workbook: { Views: [{ RTL: true }] }, Sheets: {}, SheetNames: [] }
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      //const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet);

      // Generate the Excel file and trigger the file download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      resolve(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    });
  }
}
<p-card (click)="interested()"> 
    <i class="pi pi-circle-on m-2" *ngIf="message.isLooking == 0"></i>  
    <ng-template pTemplate="header">       
        <span class="header">{{message.popupTitle}}</span>   
    </ng-template>
    <p class="description">{{message.popupDescription}}</p>
    <ng-template pTemplate="body">
        <button  pButton pRipple type="button" label="{{'MENU.INTERESTED'|translate}}" class="p-button-raised p-button-text"></button>
    </ng-template>
    <ng-template pTemplate="footer">
        <p style="font-size: small;">{{message.companyName}}</p>
    </ng-template>
</p-card>

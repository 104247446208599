
export class TransactionsSearchBaseModel {
    captchaToken: string;
}

export class TransactionsByCreditCardModel extends TransactionsSearchBaseModel {
    date: Date;
    sum: number;
    creditCardNum: string;
    creditCardCompanyId: number;  //???byenum
}
export class TransactionsByBankAccountModel extends TransactionsSearchBaseModel {
    date: Date;
    sum: number;
    bankNum: number;
    branchNum: number;
    bankAccount: string;
}
export class TransactionsByMailOrPhoneModel extends TransactionsSearchBaseModel {
    userMailOrPhone: string;
}



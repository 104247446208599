<div class="flex flex-column">
    <span class="fw-700">
        {{ (editAble ? 'TERMINALS.DEFAULT_TERMINAL.EDITABLE_TITLE' : 'TERMINALS.DEFAULT_TERMINAL.UN_EDITABLE_TITLE' )|
        translate }}
    </span>
<span class="fs-small pb-2 text-gray-400" *ngIf="editAble">שים לב! בהגדרת מסוף הינך מבטל את ההגדרה הקיימת, מופיע בסוגריים. </span>

    <form [formGroup]="defaultTerminalsForm" class="flex">
       
        <div class="flex flex-column  gap-1">
            <span class="fw-700">בעסקאות רגילות</span>
        <div class="flex justify-content-between flex-row">
            <p-checkbox *ngIf="editAble" name="defaultTerminal" formControlName="defaultTerminal" [binary]="true"
                label="כרטיסים רגילים"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסים רגילים</span>
                <div *ngIf="defaultTerminals?.regullar?.terminalNumber&&defaultTerminal!=true" class="fs-xsmall text-gray-400">
                    <span>
                        [{{defaultTerminals.regullar.terminalNumber.substring(0,defaultTerminals.regullar.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.regullar.name}}]</span>
                </div>
            </label>
        </div>
        <div class="flex justify-content-between  flex-row">
            <p-checkbox *ngIf="editAble" name="defaultAmexTerminal" formControlName="defaultAmexTerminal"
                [binary]="true" label="כרטיסי אמקס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי אמקס</span>
                <div *ngIf="defaultTerminals?.amex?.terminalNumber&&defaultAmexTerminal!=true" class="fs-xsmall text-gray-400">
                    <span>
                        [{{defaultTerminals.amex.terminalNumber.substring(0,
                        defaultTerminals.amex.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.amex.name}}]
                    </span>
                </div>
            </label>
        </div>
        <div class="flex justify-content-between flex-row">
            <p-checkbox *ngIf="editAble" name="defaultDinersTerminal" formControlName="defaultDinersTerminal"
                [binary]="true" label="כרטיסי דיינרס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי דיינרס</span>
                <div *ngIf="defaultTerminals?.diners?.terminalNumber&&defaultDinersTerminal!=true" class="fs-xsmall text-gray-400">
                    <span>
                        [{{defaultTerminals.diners.terminalNumber.substring(0,
                        defaultTerminals.diners.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.diners.name}}]</span>
                </div>
            </label>
        </div>
    </div>
    <p-divider layout="vertical"></p-divider>

    <div class="flex flex-column  gap-1 ">
        <span class="fw-700"> בעסקאות הוק</span>
        <div class="flex justify-content-between flex-row">
            <p-checkbox *ngIf="editAble" name="hokDefaultTerminal" formControlName="hokDefaultTerminal" [binary]="true"
                label="כרטיסים רגילים"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסים רגילים</span>
                <div *ngIf="defaultTerminals?.hokRegullar?.terminalNumber&&hokDefaultTerminal!=true" class="fs-xsmall text-gray-400">
                    <span>
                        [{{defaultTerminals.hokRegullar.terminalNumber.substring(0,defaultTerminals.hokRegullar.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.hokRegullar.name}}]</span>
                </div>
            </label>
        </div>
        <div class="flex justify-content-between  flex-row">
            <p-checkbox *ngIf="editAble" name="hokDefaultAmexTerminal" formControlName="hokDefaultAmexTerminal"
                [binary]="true" label="כרטיסי אמקס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי אמקס</span>
                <span *ngIf="defaultTerminals?.hokAmex?.terminalNumber&&hokDefaultAmexTerminal!=true" class="fs-xsmall text-gray-400">
                    <span>
                        [{{defaultTerminals.hokAmex.terminalNumber.substring(0,
                        defaultTerminals.hokAmex.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.hokAmex.name}}]
                    </span>
                </span>
            </label>
        </div>
        <div class="flex justify-content-between flex-row">
            <p-checkbox *ngIf="editAble" name="hokDefaultDinersTerminal" formControlName="hokDefaultDinersTerminal"
                [binary]="true" label="כרטיסי דיינרס"></p-checkbox>
            <label [ngClass]="!editAble?'flex gap-1':''">
                <span [hidden]="editAble"> כרטיסי דיינרס</span>
                <div *ngIf="defaultTerminals?.hokDiners?.terminalNumber&&hokDefaultDinersTerminal!=true" class="fs-xsmall text-gray-400">
                   
                    <span >
                        [{{defaultTerminals.hokDiners.terminalNumber.substring(0,
                        defaultTerminals.hokDiners.terminalNumber.length-3)}}
                        -
                        {{defaultTerminals.hokDiners.name}}]</span>
                </div>
            </label>
        </div>
    </div>
    </form>
</div>
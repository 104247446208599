export enum PermissionType {
    MANAGMENT_MODULE = 1,
    // AUTH_BANK_PAGE = 2,
    CUSTOMERS_PAGE = 3,
    EDIT_PARENT_COMPANY = 4,
    EDIT_KESHER_SERVICES = 5,
    MOBILES = 6,
    MANAGE_LABELS = 7,
    PROACTIVE_BROADCAST=8,
    TECHNICIAN = 12,
    PAYMENT_SUMMERY=13,
    SALES_MANAGEMENT=14
};

<div class=" flex mb-2 p-fluid flex-column" style="border: 1px solid #1774b4;border-radius: 5px;">
   <div class="flex col-12 justify-content-between pb-0 pt-0" style="background: aliceblue;position: relative;">
    <div> {{index+1}} </div>
    <!-- <div (click)="remove()"> -->
        <!-- <p-button label="מחק יחידה" icon="pi pi-trash" ></p-button> -->
        <p-button label="מחק יחידה"  (onClick)="remove()"  styleClass="p-button-link p-0"></p-button>
    <!-- </div> -->
</div>
    <div class="col-12">
        <div *ngFor="let field of fields" class="p-1">
            <dynamic-field [field]="field" ></dynamic-field>
        </div>
    </div>
    
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../services/excel.service';
import { saveAs } from 'file-saver';
import { AdvMessageService, MessageTypes } from 'projects/shared/src/public-api';

type AOA = any[][];
@Component({
  selector: 'upload-excel-file',
  templateUrl: './upload-excel-file.component.html',
  styleUrls: ['./upload-excel-file.component.scss']
})
export class UploadExcelFileComponent implements OnInit {

  @Input() chooseLabel: string = "העלה קובץ";
  @Input() columnNames: any[];
  @Input() headerRow: number = 1;
  @Input() warning: string;
  @Input() sheetName: string = "Sheet1";
  @Input() moreInfo: string;
  @Input() hasExampleFile: boolean = true;
  @Input() exampleFileURL: string;
  @Input() disabled: boolean;
  @Output() newFileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private excelService: ExcelService,
    private messageService: AdvMessageService,

  ) { }

  ngOnInit(): void {
  }

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  // onFileChange(evt: any) {
  //   /* wire up file reader */
  //   //const target: DataTransfer = <DataTransfer>(evt.target);
  //   if (evt.files.length !== 1) throw new Error('Cannot use multiple files');
  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     /* read workbook */
  //     const bstr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

  //     /* grab first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  //     this.newFileUploaded.emit(this.data);
  //   };
  //   reader.readAsBinaryString(evt.files[0]);
  // }

  createExampleFile() {
    const downloadLink = document.createElement('a');
    if (this.exampleFileURL) {
      downloadLink.href = this.exampleFileURL;
      downloadLink.click();
    }
    else {
      const data = [this.columnNames.map(col => col.name)]; // Use the column names as the first row
      this.excelService.createExcelFile(data).then((blob: Blob) => {
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'example.xlsx';
        downloadLink.click();
      })
    }

  }
  onFileRemoved(event: any) {
    this.fileRemoved.emit();
  }
  onFileChange(event: any) {
    const file: File = event.files[0];

    this.excelService.readExcelFile(file, this.sheetName, this.columnNames, this.headerRow).then((resultList) => {
      // עשה משהו עם resultList
      this.newFileUploaded.emit(resultList);
    })
      .catch((error) => {
        // טפל בשגיאה
        console.log(error);
        this.messageService.showMessage(MessageTypes.info, "אין רשומות בקובץ");

      });

  }


}

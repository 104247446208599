import { Column } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ColumnType } from '../../grid-types';




@Component({
        selector: 'form-group-cell-renderer',
        template: `
        <div #rendrerWrapper>
  <div *ngIf="isLoaded && isEdit==true" [formGroup]="formGroup" class="form-group" (keydown)="onKeyActions($event)">
          <input  (blur)="onBlur()"   *ngIf="params.colDef.type==columnDefualt" class="p-filled"    [formControlName]="key" pInputText [id]="key" >
          <p-dropdown (onChange)="onBlurDropdown($event)"  *ngIf="params.colDef.type==columnEnum" [id]="key" [showClear]="!controlIsRequired" styleClass="w-full" [autoDisplayFirst]="false" [options]="list" [formControlName]="key" >
                <ng-template let-item pTemplate="selectedItem">
                        {{ item | gridEnumTranslate: params.colDef.customColumnParams.enumType:params.colDef.customColumnParams.enumName}}
                </ng-template>
                <ng-template let-item pTemplate="item">
                        {{ item | gridEnumTranslate: params.colDef.customColumnParams.enumType:params.colDef.customColumnParams.enumName}}
                </ng-template>
        </p-dropdown>


  </div>
  <div *ngIf="isLoaded" [formGroup]="formGroup" class="form-group">
  <p-checkbox  *ngIf="isLoaded && this.params.context.type==columnCheckbox" [binary]="true" (onChange)="onChecked($event)"  
  [formControlName]="key"></p-checkbox>
  </div>

  <label *ngIf="!isEdit && params.colDef.type==columnDefualt">{{params.valueFormatted ?? params.value }}</label>
  <label *ngIf="!isEdit && params.value!=null && params.colDef.type==columnEnum">{{params.value ? (params.value|gridEnumTranslate : params.colDef.customColumnParams.enumType:params.colDef.customColumnParams.enumName) : ''}}</label>

  </div>
  `,
        styles: [`

         errorMessage
                { 
                        line-height:12px!important;
                        font-size:10px!important }
                .p-filled{
                        height:30px
                }
                .form-group{
                        margin-bottom:0px;
                }
                .p-inputtext:read-only{
                        background: white;
                }
                .p-dropdown{
                        line-height:4px;
                        height:30px;
                }
        ::ng-deep {
                .p-dropdown .p-dropdown-clear-icon {
                        right: .75rem !important;
                }
        }
`]
})
export class FormGroupCellRenderer {

        refresh(): boolean {
                this.isEdit = true;
                setTimeout(() => {
                        document.getElementById(this.key)?.focus()
                }, 10);
                return true
        }

        formGroup: FormGroup;
        formArray: FormArray;
        key;
        isLoaded: boolean;
        isEdit: boolean;
        params: any;
        validation: ValidatorFn[];
        list: any[];
        columnEnum: ColumnType = ColumnType.Enum;
        columnCheckbox: ColumnType = ColumnType.Checkbox;
        columnDefualt: ColumnType = ColumnType.Defualt
        oldValue: any;
        controlIsRequired: boolean = false;
        private value;
        private ESCAPE_KEYCODE = 27;


        agInit(params: any) {
                this.formArray = params.context.formArray;
                this.params = params;
                this.list = params.context.list;
                this.validation = params.validators;
                this.controlIsRequired = this.validation?.find(validator => validator.name == "required")?.length > 0;
                if (this.formArray.controls.length == 0 || this.formArray.controls[this.formArray.controls.length - 1].get('id').value != params.rowIndex)//new row         
                        this.formArray.push(new FormGroup({
                                id: new FormControl(params.rowIndex)
                        }))
                this.formGroup = this.formArray.controls[this.formArray.controls.length - 1] as FormGroup;
                this.key = this.createKey(params.rowIndex, params.column);
                this.value = params.colDef.type != ColumnType.Enum ? params.value : Number(params.value);
                this.formGroup.addControl(this.key, new FormControl(this.value, { validators: this.validation, updateOn: 'blur' }))
                this.isLoaded = true;
        }


        onBlur() {
                this.formGroup.controls[this.key].markAsDirty();
                if (this.formGroup.valid) {
                        this.saveChanges(this.formGroup.controls[this.key].value);
                }
        }

        onBlurDropdown(event) {
                this.formGroup.get(this.key).markAsDirty();
                if (this.formGroup.get(this.key).valid == true) {
                        this.saveChanges(event.value)
                }
        }
        onChecked(event) {
                this.formGroup.get(this.key).markAsDirty();
                if (this.formGroup.get(this.key).valid == true) {
                        this.saveChanges(event.checked)
                }
        }
        checked
        saveChanges(newValue) {
                this.oldValue = this.params.value;
                this.params.data[this.params.column.colId] = newValue;
                this.params.value = newValue;
                if (this.oldValue != newValue)
                        this.params.context.onSaveChanges(this.params.node.data, this.params.column.colId)
                this.isEdit = false;
        }

        // Handles some key down actions:
        // Supported kes: 
        //        * Escape
        //        * no more keys implemented...
        onKeyActions(event) {
                if (event.keyCode == this.ESCAPE_KEYCODE) this.exitEditingWithotSave();
        }

        // Exit edit mode and return edited value to its original value:
        exitEditingWithotSave() {
                this.formGroup.get(this.key).patchValue(this.params.value);
                this.isEdit = false;
        }

        private createKey(rowId: string, column: Column): string {
                return `${rowId}${column.getColId()}`;
        }


}


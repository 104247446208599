<p-toast position="bottom-left" [preventOpenDuplicates]="true" [autoZIndex]="false">
    <!-- <ng-template let-message pTemplate="message">
        <div class="ui-toast-message-text-content">
            <i class="ui-toast-icon pi pi-check" style="font-size: 3rem"></i>
            <h4 class="ui-toast-summary">{{message.summary}}</h4>
            <div class="ui-toast-detail" [innerHtml]="message.detail"></div>
        </div>
    </ng-template> -->
</p-toast>

<p-confirmDialog (window:keyup.enter)="cd.accept()" (window:keyup.escape)="cd.reject()" class="ui-rtl" #cd [class]="cd.confirmation?.rejectVisible ? '' : 'reject-forbidden'" [autoZIndex]="false" [style]="{'max-width': '550px', 'min-height': '250px', 'max-height': '400px', 'min-width': '350px'}">
    <!-- <div class="my-4">
        <span style="white-space: pre-line;">{{cd.message}}</span>
    </div> -->
    <ng-template pTemplate="footer">
        <div class="flex w-full dynamic-popup-buttons mt-2 gap-1">
            <button *ngIf="cd.confirmation.rejectVisible" pButton class="fade dynamic-popup-cancel w-1/2 text-center m-0" (click)="cd.reject()"
                [label]="cd.confirmation.rejectLabel"></button>
            <button pButton class="fade dynamic-popup-accept text-center m-0" [class]="cd.confirmation.rejectVisible ? 'w-1/2' : 'w-full'" (click)="cd.accept()"
                [label]="cd.confirmation.acceptLabel"></button>
        </div>
    </ng-template>
</p-confirmDialog>
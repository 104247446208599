import { Injectable, Output, EventEmitter } from '@angular/core';
import { MessageTypes } from 'shared/src/enum';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalizationService } from 'shared/src/localization';

@Injectable()
export class AdvMessageService {

    @Output() onAddMessage: EventEmitter<void> = new EventEmitter<void>();

    constructor(private _confirmationService: ConfirmationService, private _messageService: MessageService,
        private _localizationService: LocalizationService) {
    }

    showMessage(messageType: MessageTypes, title: string, message = null, life = 3000) {
        const msg = { severity: MessageTypes[messageType], summary: title, detail: message, life: life, sticky: false };
        this._messageService.add(msg)
        this.onAddMessage.emit();
    }

    successMessage(message: string, life = 3000, shouldTranslate = false) {
        if (shouldTranslate) {
            message = this._localizationService.Translate(message);
        }
        this.showMessage(MessageTypes.success, this._localizationService.Translate('GENERAL.APPROVAL'), this._localizationService.Translate(message), life);
    }

    errorMessage(message: string, life = 3000, shouldTranslate = false) {
        if (shouldTranslate) {
            message = this._localizationService.Translate(message);
        }
        this.showMessage(MessageTypes.error, this._localizationService.Translate('GENERAL.ERROR'), message, life);
    }
    showConfirmation(title: string, message: string,
        accept?: Function, reject?: Function,
        // acceptVisible: boolean = true, rejectVisible: boolean = true,
        acceptLabelText = this._localizationService.Translate('GENERAL.OK'),
        rejectLabelText = this._localizationService.Translate('GENERAL.CANCEL'),
        rejectVisible = true
    ) {
        if (!title)
            title = this._localizationService.Translate('GENERAL.WARNING_BEFORE_ACTION');
        this._confirmationService.confirm({
            message: message ? this._localizationService.Translate(message) : "",
            header: this._localizationService.Translate(title),
            icon: 'fa fa-question-circle',
            acceptLabel: this._localizationService.Translate(acceptLabelText),
            rejectLabel: this._localizationService.Translate(rejectLabelText),
            rejectVisible: rejectVisible,
            accept: () => {
                if (accept !== null) {
                    accept();
                }
            },
            reject: () => {
                if (reject !== null) {
                    reject();
                }
            },

        });
    }

    close() {
        this._confirmationService.close();
    }
}

import { LocalizationService } from "shared/src/localization";
import { ErrorMessage } from "./models/error-message";


export const DEFAULT_ERRORS: ErrorMessage[] = [
  {
    error: "invalidPhone",
    format: (label, translator) => translator.Translate("ERRORS.INVALID_PHONE")
  },
  {
    error: "invalidDate",
    format: (label, translator) => translator.Translate("ERRORS.INVALID_DATE")
  },
  {
    error: "required",
    format: (label: string, translator: LocalizationService) => `${translator.Translate('ERRORS.REQUIRED')}`
  },
  {
    error: "pattern",
    format: (label: string, translator: LocalizationService) => `${label} ${translator.Translate('ERRORS.INVALID')}`
  },
  {
    error: "simpleChars",
    format: (label: string, translator: LocalizationService) => `${label} ${translator.Translate('ERRORS.SIMPLE_CHARS_ONLY')}`
  },
  {
    error: "minlength",
    format: (label, translator, error) =>
      ` ${label} ${translator.Translate('ERRORS.MIN_LENGTH')} ${error.requiredLength} ${translator.Translate('ERRORS.CHARS')}`
  },
  {
    error: "maxlength",
    format: (label, translator, error) =>
      ` ${label} ${translator.Translate('ERRORS.MAX_LENGTH')} ${error.requiredLength} ${translator.Translate('ERRORS.CHARS')}`
  },
  {
    error: "requiredTrue",
    format: (label, translator) => `${label} ${translator.Translate('ERRORS.REQUIRED')}`
  },
  {
    error: "email",
    format: (label, translator) => translator.Translate('ERRORS.MAIL')
  },
  {
    error: "max",
    format: (label, translator, error) => ` ${label} ${translator.Translate('ERRORS.MAX')} ${error.max}`
  },
  {
    error: "min",
    format: (label, translator, error) => `  ${label} ${translator.Translate('ERRORS.MIN')} ${error.min}`
  },
  {
    error: "missingRequiredParam",
    format: (label, translator, error) => `${translator.Translate('ERRORS.PARAM')} ${error} ${translator.Translate('ERRORS.REQUIRED')}`
  },
  {
    error: "telOrMailValidation",
    format: () => 'נא הכנס כתובת מייל תקינה או מספר טלפון'
  },
  {
    error: "correctPassword",
    format: (label, translator) => translator.Translate("ENUMS.Validators.ValidPass")
  },
  {
    error: "validURLParam",
    format: (label, translator) => translator.Translate("ENUMS.Validators.ValidURLParam")
  },
  {
    error: "passwordMismatch",
    format: (label, translator) => translator.Translate("ERRORS.PASSWORD_MISMATCH")
  },
  {
    error: "exist",
    format: (label, translator) => translator.Translate("ERROR_MESSAGES.USER_NAME_EXISTS")
  },
  {
    error: "unique",
    format: (label, translator) => `${label} ${translator.Translate("ERROR_MESSAGES.IS_NOT_UNIQUE")}`
  },
  {
    error: "notExistInList",
    format: (label, translator) => translator.Translate("ERROR_MESSAGES.NAME_IS_NOT_IN_LIST")
  },
  {
    error: "onlyNumbers",
    format: (label, translator) => `${translator.Translate("ERRORS.THIS_FIELD")} ${translator.Translate("ERRORS.ONLY_NUMBERS")}`
  },
  {
    error: "onlyCapitalLetters",
    format: (label, translator) => `${translator.Translate("ERRORS.THIS_FIELD")} ${translator.Translate("ERRORS.ONLY_CAPITAL_LETTERS")}`
  },
  {
    error: "biggerThan",
    format: (label, translator, error) => `${label} ${translator.Translate("ERRORS.BIGGER_THAN")}${error}`
  },
  {
    error: "lowerThan",
    format: (label, translator, error) => `${label} ${translator.Translate("ERRORS.LOWER_THAN")}${error}`
  },
  {
    error: "invalidTransactionDate",
    format: (label, translator) => translator.Translate('ERRORS.TRANSACTION_DATE')
  },
  {
    error: "invalidExpiry",
    format: (label, translator) => translator.Translate('ERRORS.EXPIRY')
  },
  {
    error: "wrongAccountNumber",
    format: (label, translator) => translator.Translate('ERRORS.WRONG_ACCOUNT_NUMBER')

  },
  {
    error: "wrongCompanyCode",
    format: (label, translator) => translator.Translate('ERRORS.WRONG_COMPANY_CODE')

  },
  {
    error: "longCompanyCode",
    format: (label, translator) => translator.Translate('ERRORS.LONG_COMPANY_CODE')

  },
  {
    error: "israeliId",
    format: (label, translator) => `${translator.Translate("ERRORS.TZ")}`
  },
  {
    error: "creditNumberNotValid",
    format: (label, translator, error) => `${translator.Translate("ERRORS.INVALID_CREDIT_CARD")}`
  },
  {
    error: "fullName",
    format: (label, translator) => `${translator.Translate("ERRORS.FULL_NAME")}`
  },
  {
    error: "hebrewLetters",
    format: (label, translator) => `${translator.Translate("ERRORS.THIS_FIELD")} ${translator.Translate("ERRORS.NO_HEBREW_LETTERS")}`
  },
  {
    error: "validUrl",
    format: (label, translator) => `${translator.Translate("ERRORS.THIS_FIELD")} ${translator.Translate("ERRORS.VALID_URL")}`
  },
  {
    error: "numAssociation",
    format: (label, translator) => ` ${translator.Translate("ERRORS.NUM_ASSOCIATION")}`
  },
  {
    error: "requireOneCheckboxToBeChecked",
    format: (label, translator) => ` ${translator.Translate("ERRORS.REQUIRE_ONE_CHECKBOX_TO_BE_CHECKED")}`
  },
  {
    error: "invalidVerificationCode",
    format: (label, translator) => translator.Translate("ERRORS.INVALID_VERIFICATION_CODE")
  },
  {
    error: "VerificationCodeRequired",
    format: (label, translator) => translator.Translate("ERRORS.VERIFICATION_CODE_REQUIRED")
  },
  {
    error: "VerificationCodeNotConfirmed",
    format: (label, translator) => translator.Translate("ERRORS.VERIFICATION_CODE_NOT_CONFIRMED")
  },
  {
    error: "multipleSelection",
    format: (label, translator) => translator.Translate("ERRORS.MULITPLE_SELECTION")
  },

];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateAccountNumberRequest } from '../external-models/validateAccountNumberRequest';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private httpClient: HttpClient) {
  }
  getBanksList() {
    return this.httpClient.get<{}>('/Bank/getBanksList');
  }
  getBranchesList() {
    return this.httpClient.get<{}>('/Bank/getBranchesList');
  }
  validateAccountNumber(request: ValidateAccountNumberRequest) {
    return this.httpClient.post('/Bank/validateAccountNumber', request);
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {

  constructor() { }

  copy(copyText: string) {
    var copyElement = document.createElement('input');
    copyElement.value = copyText;
    
    /* Select the text field */
    copyElement.select();
    copyElement.setSelectionRange(0, 99999); /* For mobile devices */
  
     /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyElement.value);
  
  }
}

import { createAction, props } from '@ngrx/store'
import { Role } from 'projects/shared/src/public-api';
import { AuthData } from '../../model/auth-data.model';

export const setCompany = createAction('[Auth Service] Login Set Company', props<{ companyId: number, companyCode: string, numAssociation: string, companyName: string, companyMail: string, isMyChildCompany: boolean, parentCompanyId?: number, imgPath: string,registeredEasyCountService:boolean,showDecodingToken:boolean }>());
export const setUser = createAction('[Auth Service] Login Set User', props<{ identity: number, role: Role, firstName: string, lastName: string, mail: string }>());
export const setAuthData = createAction('[Auth Service] Token', props<{ authData: AuthData }>());
export const clearUserAuthData = createAction('[Auth Service] Logout');
export const clearCompany = createAction('[Auth Service] clearCompany');
export const setCompaniesList = createAction('[Auth Service] Login Set CompaniesList', props<{ companiesList: [] }>());




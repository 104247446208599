import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
   }

  getCompanies(request: { listQuery: any, status: any }): Observable<any> {
    return this.httpClient.post("/Companies/all", request);
  }

  export(request: { exportGetDataParams: { listQuery: any, status: number }, fileName: string }): Observable<any> {
    return this.httpClient.post('/Companies/export', request, { responseType: "blob", headers: { 'Accept': 'application/octet-stream' } })
  }
  getAllMin(): Observable<any> {
    return this.httpClient.get("/Companies/getAllMin");
  }

  //#region add Company 
  addCompanyClicked: EventEmitter<void> = new EventEmitter();

  emitAddCompanyClickedEvent() {
    this.addCompanyClicked.emit();
  }
  getAddCompanyClickedEmitter() {
    return this.addCompanyClicked;
  }
  //#endregion

  checkIsParentcompany(): Observable<boolean> {
    return this.httpClient.get("/Companies/checkIsParentcompany").pipe(
      map((res: any) => res.entity)
    );
  }
  getInsertDateCompany(companyId: number): Observable<Date> {
    return this.httpClient.get(`/Companies/getInsertDateCompany/${companyId}`).pipe(
      map((res: any) => new Date(res.entity))
    );
  }
  changeCompanyState(companyId: number): Observable<any> {
    return this.httpClient.put('/Companies/changeCompanyState', { entity: companyId });
  }

  getDefaultTerminals(): Observable<any> {
    return this.httpClient.get('/Companies/getDefaultTerminals');
  }
  saveDefaultTerminals(request: any): Observable<any> {
    return this.httpClient.post('/Companies/saveDefaultTerminals', request);
  }
  getCompanyByCompanyCode(companyCode): Observable<any> {
    return this.httpClient.get(`/Companies/getCompanyByCompanyCode/${companyCode}`);
  }
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Injector, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'gridEnumTranslate', pure: false })
export class GridEnumTranslatePipe implements PipeTransform {


  private asyncPipe: AsyncPipe;


  constructor(private translate: TranslateService, injector: Injector) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }


  transform(value: number, enumType, customEnumName = null): string {
    return this.asyncPipe.transform(this.translate.get(`ENUMS.${customEnumName}.${enumType[Number(value)]}`)) as string;

  }

}

import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'date-cell-editor',
    template: `<p-calendar #calendar [ngModel]="dateValue"
     [style]="{width: '100%', height: '100%' }" 
     (ngModelChange)="onDateChange($event)" dateFormat="dd/mm/yy"></p-calendar>`,
    styles: [`p-calendar { width: 100%; height: 100% } `]
})
export class DateCellEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public dateValue: Date;

    @ViewChild('calendar') public calendar: Calendar;

    agInit(params: any): void {
        this.params = params;
        this.dateValue = this.params.value ? new Date(this.params.value) : new Date();
    }

    getValue(): any {
        return moment(this.dateValue).set({ hour: 12 }).toDate();
    }

    isPopup(): boolean {
        return false;
    }

    focusIn(): void {
        setTimeout(() => {
            this.calendar.inputfieldViewChild.nativeElement.focus();
        });
    }
    ngAfterViewInit() {
        if (this.calendar)
            this.calendar.el.nativeElement.parentElement.style.width = "100%";
    }

    onDateChange(event: any): void {
        this.dateValue = event; // Ensure the updated value is assigned to dateValue
    }
}

import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HtmlHelperService {

  constructor(
    private sanitizer: DomSanitizer, 
  ) { }

  getHTML = (html) => this.sanitizer.bypassSecurityTrustHtml(html)

}

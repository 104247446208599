import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DecodingTokenModel } from 'shared/src/common';

@Injectable()
export class ClearingService {

  constructor(private httpClient: HttpClient) {

  }
  decodingToken(request: DecodingTokenModel) {
    return this.httpClient.post('/Clearing/DecodingToken', request);
  }

  getCardIssuer(creditCardNumber: number) {
    return this.httpClient.post('/Clearing/getCardIssuer' , {creditCardNumber});
  }

  resenedTransactionByToken(token: string): Observable<any> {
    return this.httpClient.get('/Clearing/resenedTransactionByToken/' + token);
  }
}

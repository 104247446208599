import { Component } from '@angular/core';

import { AgFloatingFilterComponent } from '@ag-grid-community/angular';
import { IFloatingFilterParams } from '@ag-grid-community/core';
// [style.color]="params.color"  $event min="0" &gt
@Component({
  selector: 'number-component',
  template: `
    <input
     
      style="width: 90px"
      type="number"
      
      [(ngModel)]="currentValue"
      (input)="onInputBoxChanged()"
    />`,
})
export class NumberFloatingFilterComponent
  implements AgFloatingFilterComponent {
  params: IFloatingFilterParams;
  currentValue: Number | null | string = null;
  style: any;

  agInit(params: IFloatingFilterParams): void {
    this.params = params;

    //this.style = {
     // color: params.color,
    //};
  }

  onParentModelChanged(parentModel: any) {
    // When the filter is empty we will receive a null value here
    if (!parentModel) {
      this.currentValue = null;
    } else {
      this.currentValue = parentModel.filter;
    }
  }

  onInputBoxChanged() {
    if (!!!this.currentValue) {
      // Remove the filter
      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    this.currentValue = Number(this.currentValue);
    this.params.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged('equals', this.currentValue);
    });
  }
}
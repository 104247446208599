import { Directive, HostListener } from '@angular/core';
import { LocalizationService } from 'shared/src/localization';

const DROPDOWN_EMPTY_MESSAGE_SELECTOR = ".p-dropdown-empty-message";
const MULTI_SELECT_EMPTY_MESSAGE_SELECTOR = ".p-multiselect-empty-message";

@Directive({
  selector: '[options],emptyMessage'
})
export class PrimeEmptyMessageDirective {

  constructor(
    private localizationService: LocalizationService
  ) {}

  //////////////////////////////////////////////////////////////// Listeners:

   /**
    Listens to a simple dropdown control opening:
   */
  @HostListener('onShow', ['$event']) openDropdown() {
    this.setCorrectEmptyMessage(DROPDOWN_EMPTY_MESSAGE_SELECTOR);
  }

   /**
    Listens to a multi select control opening:
   */
  @HostListener('onPanelShow', ['$event']) openMultiSelect() {
    this.setCorrectEmptyMessage(MULTI_SELECT_EMPTY_MESSAGE_SELECTOR);
  }

  /**
    Listens to a multi select control filtering:
  */
  @HostListener('onFilter', ['$event']) multiSelectFilter() {
    setTimeout(() => this.setCorrectEmptyMessage(MULTI_SELECT_EMPTY_MESSAGE_SELECTOR), 10);
  }


  //////////////////////////////////////////////////////////////// Helper functions:

  private getTranslation(message: string): string {
    return this.localizationService.Translate(message);
  }

  private setCorrectEmptyMessage(selector: string) { 
    let emptyMessagePanel = document.querySelector(selector);
    if(emptyMessagePanel?.innerHTML?.includes("No results found")) {
      document.querySelector(selector).innerHTML = this.getTranslation("GENERAL.NO_RESULTS");
    }
  }

}

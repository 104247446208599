import { AfterViewInit, Directive, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';

@Directive({
  selector: '[gridDateMask]'
})
export class GridDateMaskDirective implements AfterViewInit {

  @Input("maskFormat") maskFormat: string;


  constructor(private primeCalendar: Calendar) { }
  ngAfterViewInit() {
    let mask = this.maskFormat == null ? 'dd/mm/yyyy' : this.maskFormat;
    new Inputmask({ alias: "datetime", inputFormat: mask }).mask(this.getHTMLInput());
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }
}

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';


@Component({
    selector: 'master-details-renderer',
    template: `<ng-container #viewContainer></ng-container>`
})
export class MasterDetailsRendererComponent implements ICellRendererAngularComp {

    @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer: ViewContainerRef;
    component;
    data;
    rowNode;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) { }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    agInit(params: ICellRendererParams): void {
        this.component = params.value;
        this.data = params.data;
        this.rowNode = params.node;
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {

    }

    ngAfterViewInit(): void {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        let aComponentRef = this.viewContainer.createComponent(componentFactory);
        const instance = aComponentRef.instance as any;
        instance.data = this.data;
        if (instance.onCloseDetails?.subscribe) {
            instance.onCloseDetails.subscribe(() => {
                if (this.rowNode && this.rowNode.parent)
                    this.rowNode.parent.setExpanded(false);
            });
        }
        if (instance.onDataChange?.subscribe) {
            instance.onDataChange.subscribe(() => {
                this.rowNode.parent.parent.beans.agStackComponentsRegistry.gridOptionsWrapper.gridOptions.api.refreshServerSideStore({ purge: true });
            });
        }
        this.cdr.detectChanges();
    }

}

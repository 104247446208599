import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ButtonDisplayOption, ButtonEnableOption, ButtonType, CustomButton, DropdownFilter, MenuItemOptions, StatusOptions } from '../grid-types';
import { saveAs as importedSaveAs } from "file-saver";
import { ExportType } from '../../enum';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GridHeaderComponent implements OnInit {

  constructor(private _messageService: MessageService, private translate: TranslateService,
    // private sidebarService: SideBarService
  ) { }
  //#region Private Members
  @Input() note = '';
  private buttonType = ButtonType;
  private pageName: string = "GridHeaderComponent";
  selectedMenuItem: MenuItemOptions;
  //#endregion

  //#region @Output Members
  @Output() customButtonClick: EventEmitter<CustomButton> = new EventEmitter<CustomButton>();
  @Output() dropdownfilterChange: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  //#region @Input Members
  @Input() selectedItems: any;
  @Input() dropdownfilter: DropdownFilter;
  @Input() buttons: any[];
  @Input() sumHeaderCount: number;
  @Input() sumHeaderText: string;
  @Input() status: Partial<StatusOptions>;
  @Input() statusList: any[];
  @Input() enumType: any;
  @Input() enumName: any;
  @Input() statusIconEnumType: any;
  @Input() showSearch: boolean = true;
  @Input() showCreditNumberSearch: boolean = false;
  @Input() displayTitle: boolean = true;
  @Input() searchText: string;
  @Input() onLoading: boolean = false;
  @Input() addRowSettings: any = null;
  @Input() gridTitle: string = null;
  //#endregion

  //#region @Input/@Output Members-two binding
  private _selectedStatus: any
  @Input() set selectedStatus(val: any) {
    this._selectedStatus = val;
    this.selectedStatusChange.emit(val);
  }
  get selectedStatus() { return this._selectedStatus }
  @Output() selectedStatusChange: EventEmitter<string> = new EventEmitter<string>();


  private _freeSearchText
  @Input() set freeSearchText(val: string) {
    if (this.freeSearchText != val) {
      this._freeSearchText = val;

      this.freeSearchTextChange.emit(val);
    }
  }
  get freeSearchText() { return this._freeSearchText }
  @Output() freeSearchTextChange: EventEmitter<string> = new EventEmitter<string>();

  private _searchCreditNumberText
  @Input() set searchCreditNumberText(val: string) {
    if (this.searchCreditNumberText != val) {
      this._searchCreditNumberText = val;
      this.searchCreditNumberTextChange.emit(val);
    }
  }
  get searchCreditNumberText() { return this._searchCreditNumberText }
  @Output() searchCreditNumberTextChange: EventEmitter<string> = new EventEmitter<string>();

  /** Injected header components */
  @Input() anotherSearch: TemplateRef<any>;
  @Input() subTitle: TemplateRef<any>;
  @Input() project: TemplateRef<any>;
  @Input() calander: TemplateRef<any>;
  @Input() subStatusMenu: TemplateRef<any>;
  @Input() leftSubStatusMenu: TemplateRef<any>;
  @Input() isSummeryTable: boolean;
  @Input() headerMoreInformation: any;


  buttonTypeExport = ButtonType.Export;
  buttonTypeAdd = ButtonType.New;


  @Output() rowAdded: EventEmitter<any> = new EventEmitter<any>();

  //#endregion

  //#region Angular lifecycle Methods
  ngOnInit(): void {

  }



  //#endregion
  @ViewChildren(OverlayPanel) overlayPanels: QueryList<OverlayPanel>;

  //#region Events Methods
  onClick(button: CustomButton, originalEvent) {
    if (button.menuItems != null) {
      if (button.menuItems.length == 1) {
        if (button.buttonType == this.buttonType.Export) {
          let fileName: string;
          fileName = button.menuItems[0].fileName ?? (button.menuItems[0].getFileName ? button.menuItems[0].getFileName() : button.menuItems[0].label);
          fileName = this.translate.instant(fileName ?? button.menuItems[0].label);
          this.export(button.menuItems[0].onClick, fileName, button.menuItems[0].exportType);
        }
      }
      else {
        const index = this.buttons.filter(x => x.menuItems != null).findIndex(b => b === button);
        // Use the index to get the corresponding overlay panel component
        const overlayPanel = this.overlayPanels.toArray()[index];
        overlayPanel.toggle(originalEvent);
      }
    }
    else {
      this.customButtonClick.emit(button);
    }
  }

  menuItemClicked() {

  }

  showAddRow() {
    return this.addRowSettings?.allowAdd && (!this.addRowSettings.allowAddCondition || this.addRowSettings.allowAddCondition())
  }

  addNewRow() {
    if (this.addRowSettings?.newRowForm) {
      // Add a new row by fill external component form:
      //todo projects - cannot call sidebar because it is part of kesher-site project
      // this.sidebarService.setContentSideBar(this.addRowSettings.newRowForm);
      // const closeSub = this.sidebarService.onClose.subscribe((content: any) => {
      //   closeSub.unsubscribe();
      //   this.rowAdded.emit(content);
      // });
    }
    else if (this.addRowSettings?.onAddRow) {
      // Call user func:
      this.addRowSettings.onAddRow();
    }
    else {
      console.error('Wrong initialize of addRowSettings: There is no value for onAddRow or newRowForm properties');
    }
  }

  dropdownChange(event) {
    this.dropdownfilterChange.emit(event);
  }
  getDisplayCond(button) {
    return button.displayCond(this.selectedItems);
  }
  getDisplayOption(button) {
    switch (button.displayOptions) {
      case ButtonDisplayOption.always:
        return true;
      case ButtonDisplayOption.inMultiSelect:
        if (this.selectedItems && this.selectedItems.length > 1) {
          return true;
        }
        else {
          return false;
        }
      case ButtonDisplayOption.inSingleSelect:
        if (this.selectedItems && ((this.selectedItems.length && this.selectedItems.length == 1) || ((!this.selectedItems.length) && this.selectedItems.length != 0))) {
          return true;
        }
        else {
          return false;
        }
      case ButtonDisplayOption.inSingleOrMultiSelect:
        if (this.selectedItems && this.selectedItems.length && this.selectedItems.length > 0) {
          return true;
        }
        else {
          return false;
        }
    }
  }
  getDisplayButton(button: CustomButton) {
    if (button.displayCond) {
      return this.getDisplayCond(button) && this.getDisplayOption(button);
    }

    return this.getDisplayOption(button);


  }
  getDisableButton(button: CustomButton) {
    if (button.enableCond) return button.enableCond(this.selectedItems);
    switch (button.enableOptions) {
      case ButtonEnableOption.always:
        return false;
      case ButtonEnableOption.inSingleSelect:
        if (this.selectedItems && ((this.selectedItems.length && this.selectedItems.length == 1) || ((!this.selectedItems.length) && this.selectedItems.length != 0))) {
          return false;
        }
        else
          return true;
      case ButtonEnableOption.inMultySelect:
        if (this.selectedItems?.length) {
          return false;
        }
        else
          return true;
    }
  }

  getButtonTooltip(button: any): string {
    if (!button.disableTooltip) return button.tooltip;

    if (this.getDisableButton(button)) {
      return button.disableTooltip;
    }
    else {
      return button.tooltip;
    }
  }
  runStatusChanged(event: Event) {
    if (this.status?.onChange)
      this.status?.onChange(event);
  }
  export(onclick: () => any, fileName: string, exportType: ExportType) {
    onclick().subscribe(blob => {
      if (blob == null) {
        const msg = { severity: "info", summary: null, detail: this.translate.instant('INFO_MESSAGES.NO_DOWLOAD_DATA_FOUND'), life: 3000, sticky: false };
        this._messageService.add(msg)
        // this.onAddMessage.emit();
        //this._advMessageService.showMessage(MessageTypes.info, null, this._gridTranslateService.Translate('INFO_MESSAGES.NO_DOWLOAD_DATA_FOUND'));
      }
      else {
        var fileType = exportType == ExportType.PDF ? "pdf" : "xlsx";
        importedSaveAs(blob, `${fileName}.${fileType}`);
      }
    });
  }
  clickOnMenuItems(button: CustomButton) {
    if (button.buttonType == ButtonType.Export) {
      let fileName: string;
      // button.menuItems[0].fileName ?? (button.menuItems[0].getFileName ? button.menuItems[0].getFileName() : button.menuItems[0].label);
      fileName = this.selectedMenuItem.fileName ?? (this.selectedMenuItem.getFileName ? this.selectedMenuItem.getFileName() : this.selectedMenuItem.label);
      fileName = this.translate.instant(fileName ?? this.selectedMenuItem.label);
      this.export(this.selectedMenuItem.onClick, fileName, this.selectedMenuItem.exportType)
    }
    else if (this.selectedMenuItem && this.selectedMenuItem.onClick)
      this.selectedMenuItem.onClick();

    //button.command();
  }
  //#endregion
  // export(button: CustomButton,fileName:string){
  //   button.onClick().subscribe(blob => {
  //      importedSaveAs(blob,`${fileName}.xlsx` );
  //    });
  //}
}

import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(private title: Title, private meta: Meta) {}

    updateMeta(route: string, META_INFO){
        if(route.indexOf("/",1) > -1){
            let index = route.indexOf("/",1);
            route = route.slice(0, index);
        }
      if(Object.prototype.hasOwnProperty.call(META_INFO, route)){
        const {title, description} = META_INFO[route];
        this.updateTitle(title);
        this.updateDescription(description)
      }
    }
  
    updateTitle(title: string) {
      if (title) {
        this.title.setTitle(title);
      }
    }
  
    updateDescription(description: string) {
      if (description) {
        this.meta.updateTag({ name: 'description', content: description });
      }
    }

}

import {  Component,OnInit } from '@angular/core';

@Component({
  selector: 'custom-message',
  templateUrl: './customMessage.component.html',
  styleUrls: ['./customMessage.component.scss']
})
export class CustomMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}

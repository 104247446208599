export class AuthData {
    token: string;
    userMailOrPhone:string;
    lastEntranceDate: Date;

    constructor(token: string = null, userMailOrPhone: string = null, lastEntranceDate: Date = null) {
        this.token = token;
        this.userMailOrPhone = userMailOrPhone;
        this.lastEntranceDate = lastEntranceDate;
    }
}

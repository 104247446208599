import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CompanyStatus, LocalizationService, Role } from 'projects/shared/src/public-api';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { CompaniesService } from '../../services/companies.service';
import { AppAuthService } from '../../../kesher-shared/services/app-auth.service';
import { AppState, selecCompanyInCommonState, selectUserInCommonState } from '../../../app-state.index';
import { AdvMessageService, SideBarService, enumToArray } from 'projects/shared/src/public-api';
import { PermissionType } from '../../permissions/permission-type.enum';
import { PermissionsService } from '../../permissions/permissions.service';
import { ButtonDisplayOption, ButtonEnableOption, ColumnOptions, ColumnType, FieldType, FilterType, GridOptions, RowSelectionType, SortType, newButton, defualtColumnOptions, defualtGridOptions, emptyButton, exportButton, loadDataParameter, pipeRendererParams } from 'projects/grid/src/projects';

@Component({
  selector: 'company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyComponent implements OnInit {

  isMobile: boolean = window.innerWidth > 470 ? false : true;

  @Input() displayCompaines: boolean = false;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  //#region  Public Members
  options: GridOptions;
  companyStatus = CompanyStatus;
  companyStatusList: any[];
  companies: any = [];
  columns: ColumnOptions[];
  userInfo$: Observable<any>;
  company$: Observable<any>;
  loadDataParameters: loadDataParameter;
  selectedData: any;
  companyId: number;
  allowedPremiumControls: boolean;
  //#endregion
  @ViewChild("grid") grid: any;
  @ViewChild("newCompany") newComapny: any;
  companyCode: number = null;
  testCompany: boolean = false;
  payCompany: boolean = false;

  //#region Private members
  private pageName: string = "CompanyComponent";
  //#endregion

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private companyService: CompaniesService,
    private localizationService: LocalizationService,
    public authService: AppAuthService,
    private sideBarService: SideBarService,
    protected messageService: AdvMessageService,
    private permissionService: PermissionsService,
    private _advMessageService: AdvMessageService
  ) { }

  //#region Angular lifecycle Methods
  ngOnInit() {
    this.companyStatusList = enumToArray(CompanyStatus);
    this.userInfo$ = this.store.select(selectUserInCommonState);
    this.store.select(selecCompanyInCommonState)
      .subscribe((data: any) => {
        if (data)
          this.companyId = data.companyId;
      });

    this.initColumns();
    this.initGridOptions();
  }

  loadData(): Observable<any> {
    this.initLoadDataParameters();

    let request = {
      listQuery: this.loadDataParameters,
      status: this.loadDataParameters.status,
      testCompany: this.testCompany,
      payCompany: this.payCompany
    }
    return this.companyService.getCompanies(request).pipe(map((res: any) => {
      var index = res.entities.findIndex(p => p.id == this.companyId);
      var row = this.grid.getRowByRowIndex(index)
      setTimeout(() => {
        this.grid.agGrid.api.ensureIndexVisible(index, 'top')
        this.grid.selectRowByIndex(index)

      }, 50);
      return res;
    }))
  }
  setDataSource() {
    this.grid.setDataSource();
  }
  export() {
    let request = {
      exportGetDataParams: {
        listQuery: this.loadDataParameters,
        status: this.loadDataParameters.status
      },
      fileName: this.localizationService.Translate('MENU.COMPANIES_LIST')
    }
    return this.companyService.export(request);
  }


  rowDoubleClicked(row: any = null) {
    if (!row) row = this.selectedData;
    this.displayCompaines = false;
    let companyID = row.kesherUniq;
    this.companyId = row.id
    this.router.navigate([`/kesher/${companyID}/home`]);
  }

  onBeforeDialogHide(event) {
    this.loadDataParameters.freeSearchText = "";
    this.companyCode = null;
    this.onClose.emit();
  }

  //#endregion

  //#region Private Methods
  private async initGridOptions() {
    this.allowedPremiumControls = this.permissionService.isKesherAdminRole();

    this.userInfo$.subscribe(user => {
      this.options = {
        ...defualtGridOptions,
        rowSelectionType: RowSelectionType.single,
        displaySidebarFilter: false,
        getRowStyle: (params) => {
          if (params.data && !params.data?.isActive) return { color: "red", backgroundColor: "#fff0f0" }
        },

        contextMenu: (params) => this.buildContextMenu(params),

        buttons: this.getButtonsList(user),
        selectFirstRow: true,
        status: (this.allowedPremiumControls == true) ? {
          statusList: this.companyStatusList,
          selectedStatus: this.companyStatus.All,
          statusEnumType: this.companyStatus,
          statusEnumName: "CompanyStatus",
        } : null

      };
    });
  }
  private buildContextMenu(params) {
    var buttonsList = [];
    this.allowedPremiumControls = this.permissionService.isKesherAdminRole();
    let isActive = params.node.data.isActive == this.companyStatus.Active;
    let method = isActive ? "deleteRowData" : "activeCompany";
    if (this.allowedPremiumControls == true) {
      buttonsList.push(
        {
          name: this.getTranslation("USER." + (!params.node.data.isActive ? "ENABLE" : "DISABLE")) + " ( " + this.getTranslation("USER.PRESENTED_TO_CONTACT_MANAGERS") + ")",
          action: () => {
            this._advMessageService.showConfirmation(
              this.getTranslation("LOGIN.WARNING"),
              this.getMessageByKey(method),
              () => this.changeCompanyState(params.node.data),
              null
            )
          },
          icon: '<i class="pi pi-' + (!params.node.data.isActive ? "check" : "times") + '"></i>',
          allowWithoutCompany: true,
          permissionType: PermissionType.MANAGMENT_MODULE,
        },

      )
    }

    return buttonsList;

  }
  getMessageByKey(action: string): string {
    switch (action) {
      case "deleteRowData":
        return this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.ON_CONFIRMATION_ENSURE', { y: this.getCurrentCompanyName() });
      case "activeCompany":
        return this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.BEFORE_REACTIVATE', { y: this.getCurrentCompanyName() });
      default:
        return "";
    }
  }
  getCurrentCompanyName(): string {
    return (this.selectedData.name ? (`${this.selectedData.name ?? ''}`) : '')
  }
  getButtonsList(user: any) {
    var buttons = [{
      ...emptyButton,
      label: "TOP_MENU.CHOOSE_COMPANY",
      displayOptions: ButtonDisplayOption.inSingleSelect,
      enableOptions: ButtonEnableOption.inSingleSelect,
      onClick: () => this.rowDoubleClicked()
    }];

    if (user && user.role == Role.KesherUser)
      buttons = [...buttons,
      {
        ...exportButton,
        menuItems: [
          {
            label: this.localizationService.Translate('MENU.COMPANIES_LIST'),
            onClick: () => this.export()
          }]
      }];

    if (!this.isMobile)
      buttons = [...buttons, {
        ...newButton,
        label: "הקמת חברה",
        onClick: () => this.openNewCompanySettings()
      },]
    return buttons;
  }
  private initColumns() {
    this.columns = [
      {
        ...defualtColumnOptions,
        field: 'name',
        headerName: this.getTranslation('COMPANIES.NAME'),
      },
      {
        ...defualtColumnOptions,
        // sortable: false,
        field: 'kesherUniq',
        headerName: this.getTranslation('COMPANIES.KESHER_UNIQUE'),
        filter: FilterType.number
      },
      {
        ...defualtColumnOptions,
        field: 'numAssociation',
        headerName: this.getTranslation('COMPANIES.NUM_ASSOCIATION'),
      },
      {
        ...defualtColumnOptions,
        field: 'terminals',
        headerName: this.getTranslation('KEHILOT.TERMINALS'),
      },
      {
        ...defualtColumnOptions,
        field: 'mail',
        headerName: this.getTranslation('COMPANIES.MAIL'),
      },
      {
        ...defualtColumnOptions,
        field: 'users',
        headerName: this.getTranslation('COMPANIES.USERS'),
      },
      {
        ...defualtColumnOptions,
        field: 'founderCompany',
        headerName: 'הוקם ע"י',
      },
      {
        ...defualtColumnOptions,
        field: 'phone1',
        headerName: this.getTranslation('COMPANIES.PHONE1'),
      },
      {
        ...defualtColumnOptions,
        field: 'label',
        headerName: this.getTranslation('KEHILOT.LABEL'),
      },
      {
        ...defualtColumnOptions,
        field: 'mobile',
        headerName: this.getTranslation('KEHILOT.MOBILE'),
      },
      {
        ...defualtColumnOptions,
        field: 'labelManageres',
        headerName: this.getTranslation('KEHILOT.LABEL_MANAGERES'),
      },
      {
        ...defualtColumnOptions,
        field: 'address',
        headerName: this.getTranslation('COMPANIES.ADDRESS'),
      },
      {
        ...defualtColumnOptions,
        field: 'city',
        headerName: this.getTranslation('COMPANIES.CITY'),
      },
      {
        ...defualtColumnOptions,
        field: 'insertDate',
        headerName: this.getTranslation('COMPANIES.INSERT_DATE'),
        // filter: FilterType.date,
        type: ColumnType.Pipe,
        customColumnParams: {
          cellParams: {
            ...pipeRendererParams,
            pipe: {
              type: DatePipe,
              params: ['dd/MM/yyyy'],
            },
          }
        }
      },
      {
        ...defualtColumnOptions,
        field: 'defaultTerminalName',
        headerName: this.getTranslation('KEHILOT.DEFAULT_TERMINAL_NAME'),
      }
    ]
  }

  private getTranslation(translationWord, parameter?) {
    return this.localizationService.Translate(translationWord, parameter)
  }


  private initLoadDataParameters() {
    if (!this.loadDataParameters.sort?.length) {
      this.loadDataParameters.sort = [{ SortType: SortType.desc, Field: "insertDate" }]
    }
    if (this.companyCode) {
      this.loadDataParameters.filter[0] = {
        Field: "kesherUniq",
        FieldType: FieldType.string,
        Operator: 1,
        Value: [this.companyCode]
      }
    }

  }

  private openNewCompanySettings() {
    this.displayCompaines = false;
    // this.companyService.emitAddCompanyClickedEvent();
    this.sideBarService.setContentSideBar(this.newComapny, "newComapnySideBar");
  }
  //#endregion

  changeCompanyState(company) {
    this.companyService.changeCompanyState(company.id).subscribe((res: any) => {
      if (res.succeeded == true) {
        this.allowedPremiumControls = true;
        if (company.isActive == true) {
          this.grid.setDataSource();
          this.messageService.successMessage(this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.COMPANY_INACTIVE'), 5000, true);
        }
        else {
          this.grid.setDataSource();
          this.messageService.successMessage(this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.COMPANY_ACRIVE'), 5000, true);
        }
      }
      else {
        this.allowedPremiumControls = true;
        this.messageService.errorMessage(this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.COMPANY_ERROR_CHANGE_STATUS'), 5000, true);
      }
    });
  }
  searchByCompanyCode(code) {
    this.companyCode = code;

  }
}

<div class="col-4">
    <div class="gap-2">
        <div>
            <div *ngIf="!imgUrl || imgUrl?.length <= 0"
                class="uploadImageControl w-8rem h-8rem border-circle box-shadow-0-0-10-4">
                <img class="cameraImg" src="/assets/images/big_camera.png">
            </div>

            <p-image imageClass="w-8rem h-8rem border-circle box-shadow-0-0-10-4" *ngIf="imgUrl?.length > 0"
                [src]="imgUrl" width="100" [preview]="true"></p-image>

            <button pButton type="button" pTooltip="מחק תמונה" tooltipPosition="bottom"
                class="p-button-icon-only deleteButton p-button-rounded overflow-visible grid-button p-button-text"
                (click)="deleteGraphImage()" icon="pi pi-trash">

            </button>
        </div>
        <p-fileUpload styleClass="h-0" class="camera" chooseIcon="pi pi-pencil" pTooltip="בחר תמונה"
            [showCancelButton]="false" tooltipPosition="bottom" #logoUploader mode="basic" name="demo[]"
            accept="image/*" [customUpload]="true" [multiple]="false" [maxFileSize]="1000000"
            (onSelect)="uploadFile($event)">
        </p-fileUpload>
    </div>
</div>
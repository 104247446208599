import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { LocalizationService } from 'shared/src/localization';
import { FileType } from 'shared/src/enum';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { DocumentsService } from '../../services/documents.service';
import { AdvMessageService } from 'shared/src/common';


const MAX_FILE_SIZE = 1000000;// 1 MB
const FILE_LIMIT = 5;
const CUSTOM_UPLOAD = true;


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent implements OnInit {

  @Input() buttonLabel: string = 'UPLOAD.SELECT_FILES';
  @Input() control: any = new FormControl();
  @Input() accept: string = "image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.txt,.png";

  //#region limit
  private _limit: number = FILE_LIMIT;
  @Input() set limit(value: number) {
    if (value && value != this._limit) {
      this.fileLimit = this._limit = value;
    }
  }
  get limit(): number {
    return this._limit;
  }
//#endregion

  //#region maxFileSize
  private _maxFileSize: number = MAX_FILE_SIZE;
  @Input() set maxFileSize(value: number) {
    if (value != this._maxFileSize) {
      this._maxFileSize = value;
    }
  }
  get maxFileSize(): number {
    return this._maxFileSize;
  }
//#endregion
  
@Output() uploadFiles = new EventEmitter();

  // detect add of any file:
  @Output() newFileUploaded: EventEmitter<any> = new EventEmitter<any>();

  // detect add the maximum allowed files:
  @Output() allFilesUploaded: EventEmitter<any> = new EventEmitter<any>();

  // detect file removed:
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('uploader') uploader: any;

  fileLimit = FILE_LIMIT;
  customUpload = CUSTOM_UPLOAD;
  filesToUpload: any[] = [];
  observableBatch: any = [];
  fileList = [];

  constructor(
    private _localizationService: LocalizationService,
    private _advMessageService: AdvMessageService,
    private _cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private documentService: DocumentsService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let files = this.control.value;
    if (!files || !files.length) return;

    if (!Array.isArray(files)) files = [files];
    files?.forEach(file => {
      if (!file?.length) return;
      let url = file?.replace("https://ftp.kesherhk.co.il:3380/ftp/KesherClients", "ftp://37.187.88.38:3321/KesherClients/");
      this.documentService.downloadFile(url)
        .subscribe(res => {
          this.isImageUrl(file).then(isImage => {
            let fileName = (file as string).split('/').pop();
            fileName += isImage ? ".png" : ".pdf";
            this.fileList = [...this.fileList, {
              name: fileName,
              size: res.size <= this.maxFileSize ? res.size : this.maxFileSize,
              safeValue: file,
              type: "application/pdf",
              specificType: isImage ? FileType.Img : FileType.Docx,
              objectURL: { changingThisBreaksApplicationSecurity: file },
              hideExtention: true,
            }];
            this._cdr.markForCheck();
          })
        })
    });
  }

  clearFiles() {
    this.filesToUpload = [];
    this.uploader.clear();
    this.control.patchValue([]);
    this._cdr.markForCheck();
  }

  getFileIcon(file): string {
    switch (file.specificType) {
      case FileType.Excel:
        return "file-excel";

      case FileType.PDF:
        return "file-pdf";

      case FileType.Docx:
        return "file";
    }
  }

  getFiles(): Promise<any[]> {
    return new Promise<any[]>(resolve => {
      this.prepareUploadedFiles().then((res: boolean) => {
        if (res === true) {
          resolve(this.filesToUpload);
        }
        else {
          resolve(null);
        }
      });
    });
  }

  formatFileName(file: any) {
    if (file.hideExtention) {
      let fileName = file.name.split('.');
      fileName = fileName.slice(0, fileName.length - 1);
      return fileName.join('.');
    }
    else return file.name;
  }

  invalidFileSizeMessage = () => this._localizationService.Translate('UPLOAD.ERRORS.INVALID_FILE_SIZE_DETAILS') + this.maxFileSize / 1000000 + 'MB';

  isImage(file) {
    return file.specificType == FileType.Img;
  }

  removeFile(file: File) {
    const index = this.uploader.files.indexOf(file);
    this.uploader.files.splice(index, 1);
    let controlFiles = this.control.value;
    if (!Array.isArray(controlFiles)) controlFiles = [controlFiles];
    this.control.patchValue(controlFiles.filter((file, i) => i != index));
    this.fileRemoved.emit(file);
  }

  select(uploaderData) {
    if (this.uploader.files.length > this.fileLimit) {
      Array.from(uploaderData.files).forEach(file => {
        const index = this.uploader.files.indexOf(file);
        if (!this.checkIfFIleExists(file)) { this.uploader.files.splice(index, 1); }
      });
      this._advMessageService.errorMessage(
        this._localizationService.Translate('UPLOAD.ERRORS.FILE_LIMIT_ERROR_MESSAGE').replace('{0}', this.fileLimit)
      );
    }
    this.prepareSelectedFiles();
  }

  setUpFiles(file) {
    const reader = new FileReader();
    const myobservable = Observable.create((observer: any) => {
      reader.onload = () => {
        this.filesToUpload.push({
          filename: file.name,
          filetype: file.type,
          value: (reader.result as string).split(',')[1]
        });
        observer.next({ file });
        observer.complete();
      };
      reader.onerror = (error) => {
        this.filesToUpload.push({
          filename: file.name,
          filetype: file.type,
          error: error.target.error
        });
        observer.next({ file, error: error.target.error });
        observer.complete();
      };
    });
    this.observableBatch.push(myobservable);
    reader.readAsDataURL(file);
  }

  deleteLastUploadedFile() {
    let files = this.control.value;
    files = files.slice(0, files.length - 1);
    this.control.patchValue(files);
    this.fileList = files;
  }

  private checkIfFIleExists(file): boolean {
    for (let i = 0; i < this.fileList.length; i++) {
      if (file.name === this.fileList[i].name && file.size === this.fileList[i].size)//TODO -check if same files
        return true;
    }
    return false;
  }

  private convertUploadedFileToBase64(file, index) {
    const reader = new FileReader();
    reader.onload = () => {
      let base64Url = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.fileList[index].safeValue = base64Url;
      this.fileList[index].base64Value = reader.result;
      this.fileList[index].hideExtention = false;
      if (this.fileList.length < this.limit) {
        this.newFileUploaded.emit(this.limit > 1 ? this.fileList : this.fileList[0]);
      }
      else {
        this.newFileUploaded.emit(this.limit > 1 ? this.fileList : this.fileList[0]);
        this.allFilesUploaded.emit(this.limit > 1 ? this.fileList : this.fileList[0]);
      }
      this._cdr.markForCheck();
    };
    reader.onerror = (error) => {
      console.error(error)
    };
    reader.readAsDataURL(file);
  }

  private getFileTypeFromFileName(fileName): FileType {
    let extension = fileName.split(".").pop();
    switch (extension.toLowerCase()) {
      case "png":
      case "jpg":
      case "jpeg":
      case "svg":
        return FileType.Img;

      case "xlsx":
      case "csv":
        return FileType.Excel;

      case "docx":
      case "doc":
        return FileType.Docx;

      case "pdf":
      case "svg":
        return FileType.PDF;
      
      default:
        return FileType.UnSupported
    }
  }

  private isImageUrl(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  private prepareSelectedFiles() {
    this.uploader.files.forEach((file, i) => {
      this.fileList.push(file);
      this.setFileName();
      this.setFileSize();
      this.setFileType();
      this.convertUploadedFileToBase64(file, i);
      this.control.patchValue(this.limit > 1 ? this.fileList : this.fileList[0]);
    })
  }

  private prepareUploadedFiles(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.uploader && this.uploader.files &&
        this.uploader.files.length > 0) {
        this.filesToUpload = [];
        this.observableBatch = [];
        this.uploader.files.forEach(file =>
          this.setUpFiles(file));
        forkJoin(this.observableBatch).subscribe(
          (m) => {
            resolve(true);
          },
          (e) => {
          },
          () => {
          }
        );
      }
      else {
        resolve(true);
      }
    })
  }

  private setFileName() {
    let file = this.fileList[this.fileList.length - 1];
    file.fileName = file.name;
  }

  private setFileType() {
    let file = this.fileList[this.fileList.length - 1];
    file.specificType = this.getFileTypeFromFileName(file.name);
  }

  private setFileSize() {
    let file = this.fileList[this.fileList.length - 1];
    file.fileSize = file.size;
  }
}

import { CommonModule, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeHe from '@angular/common/locales/he';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { reducers } from './app-state.index';
import { CoreModule } from './core/core.module';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { HttpLoaderFactory, VendorsModule } from 'projects/vendors/src/lib/vendors.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptorProviders } from './kesher-shared/interceptors';
import { ProjectService } from './kesher/services/project.service';
import { PublicModule } from './public/public.module';

import { AdvMessageService, LocalizationService } from 'projects/shared/src/public-api';
import { ErrorHandling } from './kesher-shared/handlers/error-handling.handler';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GridModule } from 'projects/grid/src/lib/grid.module';

registerLocaleData(localeHe);

//I keep the new line
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    GridModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    PublicModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // SimpleNotificationsModule.forRoot(), // should be only in app module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRootOrChild(), VendorsModule.forRootOrChild(),
    StoreModule.forRoot(reducers),
    //StoreDevtoolsModule.instrument({
    //  maxAge: 25, // Retains last 25 states
    //  logOnly: environment.production, // Restrict extension to log-only mode
    //  autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    //}),
  ],
  providers: [
    HttpInterceptorProviders,
    LocalizationService,
    ProjectService,
    MessageService,
    AdvMessageService,
    ConfirmationService,
    DialogService,
    { provide: ErrorHandler, useClass: ErrorHandling },
    { provide: LOCALE_ID, useValue: 'he' },
    { provide: LocationStrategy, useClass: PathLocationStrategy } //PathLocationStrategy avoid # in url
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

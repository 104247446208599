import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  constructor() { }
  
  fastPaymentClicked: EventEmitter<void> = new EventEmitter();
  openAdversitingClicked: EventEmitter<number> = new EventEmitter<number>();
  openCloseMenu: EventEmitter<void> = new EventEmitter();

  emitFastPaymentClickedEvent() {
    this.fastPaymentClicked.emit();
  }
  getFastPaymentClickedEmitter() {
    return this.fastPaymentClicked;
  }

}

import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
    selector: 'numeric-cell',
    template: `
    <input 
    #input class="ag-input-field-input ag-text-field-input ag-cell-editor"
    (keydown)="onKeyDown($event)" placeholder="{{placeholderText}}"
    [(ngModel)]="value"/>`,
    styles: [`numeric-cell { width: 100%; }`]
})
export class NumericCellEditor
    implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value!: number;
    private cancelBeforeStart = false;
    public placeholderText = null;

    @ViewChild('input', { read: ViewContainerRef })
    public input!: ViewContainerRef;

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart = !!(
            params.charPress && '1234567890.'.indexOf(params.charPress) < 0
        );
    }

    setInitialState(params: ICellEditorParams) {
        let startValue;

        if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (params.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = params.charPress;
        } else {
            // otherwise we start with the current value
            if (params.value == "ללא הגבלה") {
                //startValue = 9999;
                this.placeholderText = "ללא הגבלה";
            }
            else startValue = params.value;

        }

        this.value = startValue;
    }

    getValue(): any {
        if (!this.value && this.placeholderText == "ללא הגבלה") {
            return 9999;
        }
        if (!this.value)
            return null;
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd(): boolean {
        return this.value > 1000000;
    }

    onKeyDown(event: any): void {
        if (event.key === 'Escape') {
            return;
        }
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedNumeric(event)
        ) {
            if (event.preventDefault) event.preventDefault();
        }
    }

    focusIn(): void {
        this.input.element.nativeElement.select();
    }
   
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        // window.setTimeout(() => {
        //     this.input.element.nativeElement.focus();
        // });
    }

    private isCharNumeric(charStr: string): boolean {
        return !!/\d/.test(charStr);
    }

    private isCharDecimal(charStr) {
        return '.'.indexOf(charStr) === 0;
    }

    private isKeyPressedNumeric(event: any): boolean {
        const charStr = event.key;
        return this.isCharNumeric(charStr) || this.isCharDecimal(charStr);
    }

    private deleteOrBackspace(event: any) {
        return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    }

    private isLeftOrRight(event: any) {
        return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    }

    private finishedEditingPressed(event: any) {
        const key = event.key;
        return key === KEY_ENTER || key === KEY_TAB;
    }
}
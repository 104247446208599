import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import * as moment from 'moment';

export abstract class OwnValidators {

    /**
     * validates if an input value can be a valid phone number or email address.
     * 
     */
    static validateTelOrMail(control: FormControl): any {
        if (!control.value?.length) return null;
        return control.value?.indexOf('@') > -1 ? (control.value?.match("^([A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0]{2,3})$") ? null : { telOrMailValidation: true }) : control.value?.match("^[0-9]{9,10}$") ? null : { telOrMailValidation: true };
    }

    /**
     * validates if password contains numbers, english chars and at least 8 chars
     * 
     */
    static correctPassword(control: FormControl): any {
        if (!control.value?.length || control.value?.length > 40) return null;
        return /^((?=.*[0-9])(?=.*[a-zA-Z]).{8,40})$/.test(control.value) ? null : { correctPassword: true };
    }

    /**
    * validates if password contains only numbers and/or english chars
    * 
    */
    static validURLParam(control: FormControl): any {
        if (!control.value?.length || control.value?.length > 40) return null;
        return /^[A-Za-z0-9]*$/.test(control.value) ? null : { validURLParam: true };
    }

    /**
     * Validates if string contains only simple chars
     * Simple chars can be:
     * - Hebrew or English letters (capitalized or not) 
     * - Numbers
     * - Specific chars: ["",'']
     */
    static simpleChars(control: FormControl) {
        if (!control.value?.length || control.value?.length > 40) return null;
        return /^[A-Za-z0-9א-ת\"\'\s/g]*$/.test(control.value) ? null : { simpleChars: true };
    }

    /**
     * validates if an input value is a valid phone number
     * 
     */
    static validPhone(control: FormControl) {
        if (!control.value?.length) return;
        return /^(\+|00)(\d{12,})$|^\d{7,11}$/.test(control.value) ? null : { invalidPhone: true };
    }

    /**
     * validates if an input value is a valid email address
     * 
     */
    static validMail(control: FormControl) {
        if (!control.value?.length) return;
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0]{2,4}$/.test(control.value.trim()) ? null : { email: true };
    }

    /**
     * validates if input value contains only Capital Letters
     * 
     */
    static onlyCapitalLetters(control: FormControl) {
        if (!control.value?.length) return;
        return /^[A-Z ]*$/.test(control.value) ? null : { onlyCapitalLetters: true };
    }
    /**
     * validates if input value contains only numbers
     * 
     */
    static onlyNumbers(control: FormControl) {
        if (!control.value?.length) return;
        return /^[0-9]*$/.test(control.value) ? null : { onlyNumbers: true };
    }

    /**
     * validates if an input value is a valid float number
     */
    static floatValue(control: FormControl) {
        if (!control.value?.length) return;
        return /^^\d*\.?\d*$/.test(control.value) ? null : { onlyNumbers: true };
    }

    /**
     * validates if a value is bigger than a given value
     */
    static biggerThan<T>(control: FormControl, target: T, targetName: string) {
        if (control.value == null || target == null) return;
        return control.value > target ? null : { biggerThan: targetName }
    }

    /**
     * validates if a value is lower than a given value
     */
    static lowerThan<T>(control: FormControl, target: T, targetName: string) {
        if (!control.value || !target) return;
        return control.value <= target ? null : { lowerThan: targetName }
    }

    /**
     * validates credit card expiry
     */
    static creditCardExpiry(control: FormControl) {
        if (!control.value) return;
        try {
            if (control.value.length == 5) throw new Error();
            var exp = new Date(control.value);
            return exp > new Date() ? null : { biggerThan: "תאריך נוכחי" };
        }
        catch (ex) {
            let expiry = control.value.split("/");
            let curYear = moment().format("YY");
            let curMonth = moment().format("MM");
            return (expiry[1] > curYear || (expiry[0] > curMonth && expiry[1] == curYear)) ? null : { biggerThan: "תאריך נוכחי" };
        }
    }


    /**
     * check if given value exist in a given list
     */
    static existInList(control: FormControl, list: any[], key: string, s: string = ""): any {
        if (!control.value || !list?.length) return;
        if (key == null) {
            return !list.includes(control.value) ? { notExistInList: true } : null
        }
        if (!(list?.find(el => el[key] == control.value?.[key] || el[key] == control.value))) {
            return { notExistInList: true }
        }
    }

    /**
     * check if all given list elements passes a given validation
     */
    static ValidListOf(validation: any, control: FormControl): any {
        if (!control.value?.length) return;
        let error = null;
        control.value.every(el => {
            let passed = validation({ value: el });
            if (passed) {
                error = passed;
                return false;
            }
            return true;
        })
        return error;
    }
    /*
    *Checking whether contains only one instance
    */
    static validationContainsOnlyOneInstance(control: FormControl, valuesList: any[]) {
        const intersection = control.value.filter((value: any) => valuesList.includes(value));
        if (intersection.length > 1) {
            return { multipleSelection: true };
        }
        return null;
    }

    /**
     * check if a given number is a valid israeli ID
     */
    static israeliIdentityValidator(control: FormControl) {
        // Enable empty:
        if (!control.value?.length) return;

        // Disable letters:
        if ((/[\u0590-\u05FF]/).test(control.value)) return { hebrewLetters: true };

        let id: any = String(control.value).trim();
        if (id.length > 9 || id.length < 5 || isNaN(id)) return { israeliId: true };
        // Pad string with zeros up to 9 digits
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

        return Array
            .from(id, Number)
            .reduce((counter, digit, i) => {
                const step = digit * ((i % 2) + 1);
                return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0 ? null : { israeliId: true };
    }

    /**
     * 
     * Check if a given value is not empty
     */
    static notEmpty(control: any) {
        if (!control.value?.length && !control.controls?.length) return { notEmpty: true }
        var iterate = control.controls?.length ? control.controls : control.value;
        return iterate.filter(x => x.length || x.value).length ? null : { notEmpty: true }
    }

    /**
     * 
     * Check if a given value is a valid url
     */
    static validUrl(control: FormControl) {
        if (!control.value?.length) return null;
        let url = control.value;
        try {
            url = new URL(url);
        } catch (_) {
            return { validUrl: true };
        }
        return (url.protocol === "http:" || url.protocol === "https:") ? null : { validUrl: true };
    }

    static validTransactionDate(control: FormControl): any {
        if (!control.value) return;
        return new Date(new Date(control.value).setHours(0, 0, 0, 0)) >= new Date(new Date().setHours(0, 0, 0, 0)) ? null : { invalidTransactionDate: true };
    }
    static validExpiry(control: FormControl): any {
        if (!control.value) return;
        return new Date(new Date(control.value).setHours(0, 0, 0, 0)) >= new Date(new Date().setHours(0, 0, 0, 0)) ? null : { invalidExpiry: true };
    }

    static fullName(control: FormControl) {
        if (!control.value?.length) return;
        return /^([A-Zא-תa-z0-9a-яА-Я\[\]\(\)\-\""\/\*\.' ].+\s).+$/.test(control.value) ? null : { fullName: true };
    }

    /**
     * Check if a given value is a valid date
     */
    static validDate(control: FormControl): any {
        if (!control.value) return;
        try {
            new Date(control.value);
            return null;
        }
        catch (ex) {
            return { invalidDate: true };
        }
    }

    /**
     * Check if url contains a param:
     */
    static containsParam(control: FormControl, key: string): any {
        if (!control.value) return;
        let params = control.value.split('?')[1];
        let contains = false;
        if (params) {
            contains = !params.split('&').every(param => {
                let data = param.split("=");
                if (data[0]?.toLowerCase() == key) return !data[1]?.length;
                else return true;
            });
        }
        return contains ? null : { missingRequiredParam: true };
    }

    //עוסק מורשה/ח.פ /עמותה
    static validNumAssociation(control: FormControl): any {
        if (!control.value?.length) return;
        return /^[5][0-9](\d{7})*$/.test(control.value) ? null : { numAssociation: true };
    }

    // check for a value few validators 
    //return all validators error or only yhe first vlaiteo sent
    //important the oredr of the validator if use returnAll=false
    static multipleValidator(control: FormControl, validators: any[], returnAll: boolean = true) {
        var failed = true;
        var error: {};
        var errors = {};
        validators.every(v => {
            error = v(control);
            if (!Object.keys(error ?? {}).length) {
                failed = false;
                return false;
            }
            else {
                //checking adding eroors to object return
                if (returnAll || !Object.keys(errors).length) {
                    errors = {
                        ...errors,
                        ...error
                    }
                }
            }
            return true;
        })
        return failed ? errors : null
    }
    static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
        return function validate(formGroup: FormGroup) {
            let checked = 0;

            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];

                if (control.value === true) {
                    checked++;
                }
            });

            if (checked < minRequired) {
                return {
                    requireOneCheckboxToBeChecked: true,
                };
            }

            return null;
        };
    }
}



<button class="w-full md:w-auto p-button-icon-only px-0 p-button-outlined" type="button" pButton pRipple
    [ngClass]="differentDesign ? 'whiteDesign' : 'defaultDesign'">
    <div class="flex align-items-center gap-1-4">
        <div class="flex px-1" (click)="setRanges(rangeDates.PrevMonth,null,null)"
            pTooltip="{{'ENUMS.RangeDates.PrevMonth'|translate}}" tooltipPosition="right">

            <i class="pi pi-chevron-right"></i>
        </div>
        <div class="flex align-items-center gap-1/2" (click)="op.toggle($event)">

            <span> {{ fromDate | date: 'dd/MM/yyyy'}} - {{toDate | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="flex px-1" (click)="setRanges(rangeDates.NextMonth,null,null)"
            pTooltip="{{'ENUMS.RangeDates.NextMonth'|translate}}" tooltipPosition="left">
            <i class="pi pi-chevron-left"></i>
        </div>
    </div>
</button>


<p-overlayPanel #op [showCloseIcon]="true">
    <ng-template pTemplate>
        <div class="flex flex-column gap-1/2">
            <div class=" header flex p-3">
                <div style="width:9rem">
                    <label>{{'DATES.FROM_DATE' |translate}}</label>
                    <p-calendar [readonlyInput]="isMobile==true?true:false" (onInput)="deSelectSummeryRow()"
                        (onSelect)="deSelectSummeryRow()" dateMask inputId="calendarFromDate" dateFormat="dd/mm/yy"
                        [maxDate]="toDate" [(ngModel)]="fromDate">
                    </p-calendar>
                </div>
                <div class="flex flex-column align-items-center justify-content-between">
                    <i class="pi pi-arrow-left"></i>
                    <button class="p-button-outlined todayButton" pButton
                        (click)="setRanges(rangeDates.CurrentDay,op,$event)"
                        label="{{'ENUMS.RangeDates.CurrentDay'|translate}}"></button>
                </div>
                <div style="width:9rem">
                    <label>{{'DATES.TO_DATE' |translate}}</label>
                    <p-calendar [readonlyInput]="isMobile?true:false" (onInput)="deSelectSummeryRow()"
                        (onSelect)="deSelectSummeryRow()" dateMask inputId="calendarToDate" dateFormat="dd/mm/yy"
                        [minDate]="fromDate" [maxDate]="toDateMaxDate" [(ngModel)]="toDate">
                    </p-calendar>
                </div>
            </div>
            <div class="flex flex-row justify-content-between">
                <button pButton type="button" icon="pi pi-arrow-right"
                    (click)="setRanges(rangeDates.PrevMonth,null,null)" class="w-4rem  p-button-outlined"></button>
                <button pButton class="p-button-outlined  w-15rem label"
                    (click)="setRanges(rangeDates.CurrentMonth,op,$event)">{{'ENUMS.RangeDates.CurrentMonth'
                    |translate}}</button>
                <button pButton type="button" icon="pi pi-arrow-left"
                    (click)="setRanges(rangeDates.NextMonth,null,null)" class="w-4rem  p-button-outlined"></button>
            </div>
            <button pButton class="p-button-outlined label"
                (click)="setRanges(rangeDates.LastThirtyDays,op,$event)">{{'ENUMS.RangeDates.LastThirtyDays'
                |translate}}</button>
            <button pButton class="p-button-outlined label" (click)="setRanges(rangeDates.Quarterly,op,$event)">
                {{'ENUMS.RangeDates.Quarterly'
                |translate}}</button>
            <button pButton class="p-button-outlined  label " (click)="setRanges(rangeDates.YearAgo,op,$event)">
                {{'ENUMS.RangeDates.YearAgo'
                |translate}}</button>

        </div>
    </ng-template>
</p-overlayPanel>
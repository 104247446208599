import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { LocalizationService } from "./localization.service";
import { EnumTranslatePipe } from "./enum-translate.pipe";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { EnumModule } from "shared/src/enum";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    EnumTranslatePipe,
  ],
  imports: [
    CommonModule,
    EnumModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],

  exports: [
    TranslateModule,
    EnumTranslatePipe,
  ],
  providers: [   
    LocalizationService
  ]
})

export class LocalizationModule {
  static forChild(): ModuleWithProviders<LocalizationModule> {
    return {
      ngModule: LocalizationModule,
      providers: [
        LocalizationService,
        EnumTranslatePipe,
      ]
    }
  }
}

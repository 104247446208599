import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonService } from '../services/common.service';

@Injectable()
export class LoadingBarInterceptor implements HttpInterceptor {

    constructor(private commonService: CommonService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has("hiddenLoading"))
            return next.handle(req);

        if (req.headers.has("use-spinner-loading")) {
            this.commonService.spinnerBlockScreen();
        }
        else {
            this.commonService.blockScreen();
        }
        return next.handle(req).pipe(
            finalize(() => {
                this.commonService.unblockScreen();
            }));
    }
}

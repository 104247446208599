import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataFieldType, FieldOption } from 'shared/src/enum';

@Component({
  selector: 'dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.css'],
})
export class DynamicFieldComponent implements OnInit {
  @ViewChild('numberInput', { static: false }) numberInput: any;

  isUpdateValidations = 0;
  private _formGroup: FormGroup = new FormGroup({})

  @Input() set formGroup(data: FormGroup) {
    this._formGroup = data;
  }

  get formGroup() { return this._formGroup }

  private _field: any;
  @Input() set field(data: any) {
    this._field = data;
    if (this._field?.fieldType == DataFieldType.Date)
      this.dateValue = this.getDate(this._field?.value);
    else if (this._field?.fieldType == DataFieldType.Number) {
      this._field.value = this._field.value?.replace(/,/g, '');
      //  this._field.value = Number(this._field.value);

    }
    if (this._formGroup) {
      let fieldControl = new FormControl({
        value: this.field.value ? this.field.value : null,
        disabled: this.field.fieldOption == FieldOption.ReadOnly ? true : false
      },
        [this.field.fieldOption == FieldOption.Required ? Validators.required : Validators.nullValidator, Validators.maxLength(250)]
      );

      this.formGroup.addControl(this.field.fieldId.toString(), fieldControl);
      this.isUpdateValidations++;
    }

  }

  get field() { return this._field }
  @Input() flexStyle: boolean = false;
  @Output() changeField: EventEmitter<any> = new EventEmitter<any>();

  fieldType = DataFieldType;
  dateValue: Date;
  now: Date = new Date();

  constructor(private datePipe: DatePipe) { }


  ngOnInit(): void {
  }

  onChangeField(ev) {
    switch (this.field.fieldType) {
      case DataFieldType.Date:
        if (this.dateValue)
          this.field.value = this.datePipe.transform(this.dateValue, 'dd/MM/yyyy');
        else
          this.field.value = '';
        break;
      case DataFieldType.Dropdown:
        this.field.value = ev.value;
        break;
      case DataFieldType.Number:
        this.field.value = ev.target.value;
        break;
      default:
        this.field.value = ev.target.value;
        break;
    }
    this.changeField.emit(this.field);
  }
  getDate(dateString) {
    if (!dateString)
      return null;
    // Split the date string into day, month, and year parts
    const dateParts = dateString.split('/');
    const day = +dateParts[0];
    const month = +dateParts[1] - 1; // Month is zero-based (0-11)
    const year = +dateParts[2];

    // Create a new Date object using the extracted parts
    const date = new Date(year, month, day);
    return date;
  }
  onClearField() {
    this.dateValue = null;
  }

}


import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { VerificationService } from '../../services/verification.service';
import { VerificationType } from 'shared/src/enum';
import { AdvMessageService, verificationCodeModel } from 'shared/src/common';


@Component({
  selector: 'phone-or-mail-verification',
  templateUrl: './phone-or-mail-verification.component.html',
  styleUrls: ['./phone-or-mail-verification.component.scss']
})
export class PhoneOrMailVerificationComponent implements OnInit {
  @Input() phoneNumber: string;
  @Input() phoneOrMail: VerificationType;
  @ViewChild("codeVerify") codeVerify: any;
  @Output() afterVerification: EventEmitter<boolean> = new EventEmitter<boolean>();

  verificationCodeDetails: verificationCodeModel;
  verificationCode = "";


  constructor(private cdr: ChangeDetectorRef,
    private verificationService: VerificationService,
    private messageService: AdvMessageService,
  ) { }

  ngOnInit(): void {

  }
  ngOnChanges() {
    if (this.phoneNumber) {
      this.verificationCodeDetails = {
        verificationCodeSend: true,
        encryptedNumMailOrPhone: this.phoneNumber,
        verificationType: this.phoneOrMail
      };
      this.sendVerificationCode();
    }
  }
  showVerifyButton: boolean = false;
  sendVerificationCode() {
    this.codeVerify?.formGroup?.controls['VerificationCode']?.patchValue('');
    this.verificationService.sendVerificationPhoneOrEmail(this.phoneOrMail, this.phoneNumber).subscribe((res: any) => {
      if (res.succeeded) {
        this.showVerifyButton = true;
      }
    });
    this.cdr.detectChanges();
  }

  verificationOk() {
    this.verificationCode = this.codeVerify.formGroup.get('VerificationCode').value.toString();

    this.verificationService.isValidVerificationCode({ UserMailOrPhone: this.verificationCodeDetails.encryptedNumMailOrPhone, VerificationCode: this.verificationCode }).subscribe((res: any) => {
      if (res == true) {
        this.afterVerification.emit(true);
      }
      else
        this.messageService.errorMessage("קוד אימות שגוי!");
    })
  }
  onVerificationCodeChange(event) {
    this.verificationCode = event;
  }
  onVerificationCodeCancel() {
    this.verificationCodeDetails.verificationCodeSend = false;
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory, VendorsModule } from 'projects/vendors/src/lib/vendors.module';
import { AppAuthService } from './services/app-auth.service';
import { VerificationService } from './services/verification.service';
import { AuthGuard } from './guards/auth.guard';
import { CompanyGuard } from './guards/company.guard';
import { SharedModule } from 'projects/shared/src/public-api';
import { StoreModule } from '@ngrx/store';
import { commonReducer } from './state/state.index';
import { GridReloadAtCompanyChangedDirective } from './directives/grid-reload-at-company-changed.directive';
import { ReportCardSharedComponent } from './common/report-card-shared/report-card-shared.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CompanyCodeService } from './components/company-details/validators/companyCode/validator';
import { ReminderComponent } from './components/reminder/reminder.component';
import { CompanyDefaultTremianlComponent } from '../kesher-shared/components/company-default-tremianl/company-default-tremianl.component';
import { UserDetailsComponent } from '../kesher/components/user-details/user-details.component';
import { GeneralTransactionsAnalysisComponent } from './components/general-transactions-analysis/general-transactions-analysis.component'; 
import { ChartModule } from 'primeng/chart';

@NgModule({
  declarations: [
    GridReloadAtCompanyChangedDirective,
    ReportCardSharedComponent,
    CompanyDetailsComponent,
    ReminderComponent,
    UserDetailsComponent,
    CompanyDefaultTremianlComponent,
    GeneralTransactionsAnalysisComponent,
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule.forRootOrChild(),
    VendorsModule.forRootOrChild(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forFeature('kesher-shared', commonReducer),
    ChartModule
  ],
  exports: [
    GridReloadAtCompanyChangedDirective,
    ReportCardSharedComponent,
    CompanyDetailsComponent,
    CompanyDefaultTremianlComponent,
    UserDetailsComponent,
    GeneralTransactionsAnalysisComponent
  ]
})
export class KesherSharedModule {
  static forRootOrChild(): ModuleWithProviders<KesherSharedModule> {
    return {
      ngModule: KesherSharedModule,
      // the singletone providers - one instance for all modules
      providers: [HttpClient,
        AuthGuard, CompanyGuard,
        AppAuthService, VerificationService,
        CompanyCodeService
      ]
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }
  getProjectsNamesList(): Observable<any[]> {

    return this.get('/projects/getProjectsNamesList', false).pipe(
      map((res: any) => {
        // res.entities = [{ id: 0, name: this._localizationService.Translate("ALL") }, ...res.entities];
        return res.entities;
      }));

  }
  getProjectsNamesListWithPaymentPages(): Observable<any[]> {

    return this.get('/projects/getProjectsNamesListWithPaymentPages', false).pipe(
      map((res: any) => {
        return res.entities;
      }));

  }
  getCompanyProjects(request: { listQuery: any, status: number, fromDate: Date, toDate: Date }): Observable<any> {
    return this.httpClient.post("/projects/getCompanyProjects", request);
  }
  updateProject(project: any) {
    return this.httpClient.put('/projects/updateProject', { Entity: project });
  }

  getProjectSettings(id: number) {
    return this.httpClient.get("/projects/getProjectSettings/" + id);
  }
  getProjectPaymentPages(projectId: number): Observable<any> {
    return this.httpClient.get('/projects/getProjectPaymentPages/' + projectId);
  }
  changeArciveState(projectId: number): Observable<any> {
    return this.httpClient.put('/projects/changeArciveState', { Entity: projectId });
  }
  getProjectNumOfBasicSettings() {
    return this.httpClient.get("/projects/getProjectNumOfBasicSettings");
  }
  getNextProjectNum() {
    return this.httpClient.get("/projects/getNextProjectNum");
  }

  notSendMailFromEzCount(projectId: number): Observable<any> {
    return this.httpClient.put('/projects/notSendMailFromEzCount', { Entity: projectId });
  }

  saveNewProject(request: { num: string, name: string }): Observable<any> {
    return this.httpClient.post("/projects/saveNewProject", { Entity: request });
  }


}


import { ErrorHandler, Inject, Injectable, Injector } from "@angular/core";
import { AdvMessageService, MessageTypes } from "projects/shared/src/public-api";
import { AppAuthService } from "../services/app-auth.service";

@Injectable()
export class ErrorHandling implements ErrorHandler {

    constructor(@Inject(Injector) private readonly injector: Injector) {
    }

    // Need to get AdvMessageService from injector rather than constructor injection to avoid cyclic dependency error 
    private get advMessageService() {
        return this.injector.get(AdvMessageService);
    }

    // Need to get AppAuthService from injector rather than constructor injection to avoid cyclic dependency error 
    private get authService() {
        return this.injector.get(AppAuthService);
    }

    handleError(error: any): void {
        if (error) {
            switch (error.status) {
                case undefined: // client error
                    //TODO if its client error, send to server for write in log
                    console.error(error);
                    break;
                case 401:
                    console.log('The authentication session expires or the user is not authorised.');
                    // logout and refresh the page (then the login page will be called with return url to current url)
                    this.authService.clientLogout();
                    break;
                case 504: this.advMessageService.showMessage(MessageTypes.warn,"ERROR_MESSAGES.TIME_OUT");
                    break;

                default:
                    this.advMessageService.errorMessage("ERROR_MESSAGES.UNKNOWN_ERROR", null, true);
            }
        }


    }

}
import { Component } from '@angular/core';
import { AgFloatingFilterComponent } from '@ag-grid-community/angular';

@Component({
   selector: 'custom-date-filter',
   template:
    `
    <p-calendar [(ngModel)]="rangeDates" [minDate]="minDate" [maxDate]="maxDate" [defaultDate]="today" 
          [showButtonBar]="true" (ngModelChange)="onInputBoxChanged()" selectionMode="range" (onClearClick)="resetFilterDates()">
        // <ng-template pTemplate="footer">
        //     <div class="flex"><i class="pi pi-replay mx-auto" (click)="resetFilterDates()"></i></div>
        // </ng-template>
    </p-calendar>
   `
})

export class CustomDateFloatFilterComponent
  implements AgFloatingFilterComponent {
  params: any;
  currentValue: Number | null | string = null;
  style: any;
  rangeDates: Date[];
  _rangeDates: [
      Date,
      Date
  ];
  minDate: Date;
  maxDate: Date;
  today: Date = new Date();

  agInit(params: any): void {
    this.params = params;
    this.minDate = params.filterParams.minDate;
    this.maxDate = params.filterParams.maxDate;
  }
  onParentModelChanged(parentModel: any) {
    if (this.rangeDates) {
      this.rangeDates = [parentModel.minDate, parentModel.maxDate];
    }
  }
  onInputBoxChanged() {
    if(this.rangeDates && Object.values(this.rangeDates).filter(d => d).length < 2) return;
    // TODO: reset date filter
    // if (!!!this.currentValue) {
    //   // Remove the filter
    //   this.params.parentFilterInstance((instance: any) => {
    //     instance.onFloatingFilterChanged(null, null);
    //   });
    //   return;
    // }

    this.params.parentFilterInstance((instance: any) => {
        instance.appliedModel = {minDate: this.rangeDates?.[0], maxDate: this.rangeDates?.[1], filterType: "dateRange"};
        instance.dateFilterParams.filterChangedCallback();
    });
  }

  onClearClick(){

  }

  resetFilterDates() {
    this.rangeDates = this._rangeDates;
    this.onInputBoxChanged();
  }
} 
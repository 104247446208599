import { ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[infoPanel]'
})
export class InfoPanelDirective {

  constructor(
    private hostElement: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }

  @Input() control: FormControl;
  @Input() value: string;

  parentNode: any;
  parentParentNode: any;

  ngOnInit() {
    this.listenToControlDisableChanges();
    this.listenToControlValueChanges();
    if(this.control && (this.control.enabled)) {
      this.toggleVisibility();
    }
    else this.toggleVisibility(true);
  }

  private listenToControlDisableChanges() { 
    this.control?.registerOnDisabledChange((disabled) => {
      this.toggleVisibility(disabled);
    })
  }

  private listenToControlValueChanges() {
    this.control?.valueChanges.subscribe(value => {
      this.toggleVisibility(this.control.disabled && value?.toString().length);
    })
  }

  private setFirstOnRow() { 
    let siblings = this.hostElement.nativeElement.parentNode.parentNode.querySelectorAll("info-panel:not(.hidden)");
    siblings.forEach((s,i) => {
      if(i % 3 + 1 == 1) {
        s.classList.add('first-on-row');
      }
      else { 
        s.classList.remove('first-on-row');
      }
    })
  }

  private toggleVisibility(shouldDisplay: boolean = false) {
    if(shouldDisplay) {
      this.hostElement.nativeElement.parentNode.classList.remove('hidden');
    }
    else {
      this.hostElement.nativeElement.parentNode.classList.add('hidden');
    }
    this.setFirstOnRow();
  }

}

import { AfterViewInit, Directive, Input } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';

@Directive({
  selector: '[dateMask]'
})
export class DateMaskDirective implements AfterViewInit {

  @Input("maskFormat") maskFormat: string;


  constructor(private primeCalendar: Calendar) { }
  ngAfterViewInit() {
    let mask = this.maskFormat == null? 'dd/mm/yyyy': this.maskFormat;
    new Inputmask({ alias: "datetime", inputFormat: mask }).mask(this.getHTMLInput());
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }

  getDateMask(): string {
    if (this.primeCalendar.timeOnly) {
      return '99:99';
    } else if (this.primeCalendar.showTime) {
      return '99/99/9999 99:99';
    } else {
      return '99/99/9999';
    }
  }
}

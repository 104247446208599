import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from "@ngrx/store";
import { CoreCommonReducer, CoreCommonState } from "./reducers/core-common.reducer";

import * as fromRoot from '../../app-state.index'

const reducers = {
    commonState: CoreCommonReducer,
};

interface CoreState {
    commonState: CoreCommonState;
}

const reducer: ActionReducer<CoreState> = combineReducers(reducers);

function coreReducer(state: any, action: any) {
    return reducer(state, action);
}

interface CoreAppState extends fromRoot.AppState {
    kesher: CoreState
}
export { coreReducer, CoreAppState };

//set selectors
export const selectCoreState = createFeatureSelector<CoreState>('core');
export const selectCoreCommonState = createSelector(selectCoreState, (coreState) => coreState.commonState);
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from "@ngrx/store";
// import { User } from "../model/user.model";
import { userReducer, AuthUserState } from "./reducers/auth-user.reducer";
import { AuthData } from "../model/auth-data.model";


const reducers = {
    authUserState: userReducer,
    // commonState: SharedCommonReducer,
};

interface CommonState {
    authUserState: AuthUserState;
    // commonState: SharedCommonState;
}

const reducer: ActionReducer<CommonState> = combineReducers(reducers);

function commonReducer(state: any, action: any) {
    return reducer(state, action);
}

export { commonReducer, CommonState };

// export selectors
export const selectAuthData = (state: CommonState) => state.authUserState.authData as AuthData;
export const selectUserInfo = (state: CommonState) => state.authUserState.userInfo;
export const selectCompany = (state: CommonState) => state.authUserState.company;
export const selectRole = (state: CommonState) => state.authUserState.userInfo?.role;




export const selectKesherSharedState = createFeatureSelector<CommonState>('kesher-shared');



import { Component, Input, OnInit } from '@angular/core';
import { AdversitingStatusEnum } from 'projects/shared/src/public-api';
import { TopbarService } from '../../topbar/topbar.service';
import { MessagesBoardService } from '../messages-board/messages-board.service';

@Component({
  selector: 'message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss']
})
export class MessageCardComponent implements OnInit {
  @Input() message: any;
  isInterested: boolean = false;
  
  constructor(private messagesBoardService: MessagesBoardService, private topbarService:TopbarService) { }

  ngOnInit(): void {
  }

  interested(){
    this.isInterested = true;
    this.messagesBoardService.updatesStatusAdversitings({ ids: [this.message.id], status: AdversitingStatusEnum.IsInterested }).subscribe(() => { });
    this.topbarService.openAdversitingClicked.emit(this.message.id);
  }
}

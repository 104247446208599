import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateResolve } from 'projects/shared/src/public-api';
import { TransactionSearchComponent } from './components/transaction-search/transaction-search.component';
import { MainComponent } from './main.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '', component: MainComponent
    },
    {
      path: 'TransactionSearch', component: TransactionSearchComponent, resolve: {
        transactionSearchTranslations: TranslateResolve
      },
      data: { translateKey: 'TRANSACTION' }
    }
  ])
  ],
  exports: [RouterModule]
})
export class PublicRoutingModule { }

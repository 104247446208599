import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { VendorsModule } from 'vendors';
import { HeaderComponent } from './components/header/header.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { TransactionSearchComponent } from './components/transaction-search/transaction-search.component';
import { MainComponent } from './main.component';
import { PublicRoutingModule } from './public-routing.module';
import { PublicCommonService } from './public.common.service';
import { TransactionsService } from './services/transaction.service';


@NgModule({
  imports: [
    CommonModule, FormsModule,
    PublicRoutingModule,
    SharedModule, VendorsModule
  ],
  declarations: [MainComponent, HeaderComponent, PublicFooterComponent, TransactionSearchComponent],
  providers: [PublicCommonService, TransactionsService],
})
export class PublicModule { }

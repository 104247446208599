import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[bodyAppendedClass]'
})
export class BodyAppendedClassDirective {

  @Input() bodyAppendedClass: string = 'bodyAppended';

  constructor(
    private el: ElementRef
  ) { }

  /**
   * Injects a class to overlay panel parent to use deep classes without override other overlay panels
   */
  @HostListener('onShow', ['$event']) appendClass() {

    // Find unique selector of the match panel of all panels on document
    let matchSelector = Array.from(this.el.nativeElement.classList).find((className: string) => className.startsWith("ng-tns-c"));
    
    // Prepare complete selector
    let selector = `.${this.el.nativeElement.localName}-content.${matchSelector}`;

    // Find the match element on document
    let element = document.querySelector(selector);

    // Injects class to parent element
    (element?.parentNode as any).classList.add(this.bodyAppendedClass);
  }

}

<p-progressBar class="mainProgress" mode="indeterminate" *ngIf="isLoading$ | async"></p-progressBar>
<div class="layout-wrapper main">

    <router-outlet></router-outlet>

    <div *ngIf="spinnerLoading$ | async">
        <p-blockUI [blocked]="true" [target]="blockUIElement" [style]="{position: 'absolute', 'z-index': 1500 }"></p-blockUI>
        <p-progressSpinner class="progress" strokeWidth="4"></p-progressSpinner>
    </div>

    <custom-message></custom-message>
    <delay-message></delay-message>

    <app-blockable-div #dd>
    </app-blockable-div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesBoardService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getMessagesBoard() {
    return this.httpClient.get<{}>(`/Adversiting/getAdversitingsBoard`);
  }

  getNewMessage(occupationId?: number) {
    return this.httpClient.get<{}>(`/Adversiting/getNewMessage`);
  }

  updatesStatusAdversitings(request: {status: number, ids: number[]}) : Observable<any>  {
    return this.httpClient.put(`/Adversiting/updatesStatusAdversitings`, request);
  }

  getAdvertiseNowLink() {
    return this.httpClient.get<{}>(`/Adversiting/getPublishAdversitingFormId`);
  }
}
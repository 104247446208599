import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'changes',
  templateUrl: './changes.component.html',
  styleUrls: ['./changes.component.scss']
})
export class ChangesComponent implements OnInit {

  constructor() { }

  //#region public members
  @Input() changes: any[];
  //#endregion

  //#region Angular lifecycle Methods
  ngOnInit(): void {
  }
  //#endregion

  //#region event methods
  //#endregion

}

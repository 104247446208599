import { IToolPanelAngularComp } from "@ag-grid-community/angular";
import { IToolPanelParams } from "@ag-grid-community/core";
import { Component, ViewEncapsulation } from "@angular/core";

@Component({
   selector: 'quick-scan',
   template: 
    `<ng-container [ngTemplateOutlet]="el"></ng-container>`,
   styles: [`
        quick-scan {
            width: 100%;
            padding-top: 1rem
        }
        

    `],
    encapsulation: ViewEncapsulation.None
})
export class QuickScanToolPanel implements IToolPanelAngularComp {

    el: any;
    private params: IToolPanelParams;

    agInit(params: IToolPanelParams): void {
        this.params = params;
        this.params.api.addEventListener('modelUpdated', this.updateTemplate.bind(this));
    }

    updateTemplate() {
        this.el = (this.params.api as any).gridOptionsWrapper.gridOptions.customToolPanels?.find(t => t.id == "quickScan")?.template;
    }
}

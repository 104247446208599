import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  openGmailChat() {
    const email = 'kesherhk@gmail.com'; // כתובת האימייל שאליה רוצים לשלוח הודעה
    const chatUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=${email}`;
    window.open(chatUrl, '_blank');
  }
  whatsappClick(){
    window.open("https://api.whatsapp.com/send?phone=972737272050", '_blank');
  }
  showContactIcons = false;

  toggleContactIcons() {
    this.showContactIcons = !this.showContactIcons;
  }
}

import { Directive, Input, Self, Inject, ViewContainerRef, InjectionToken, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

export const COMMON = new InjectionToken('common_interface');

@Directive({
  selector: '[gridReloadData]'
})
export class GridReloadDataDirective {

  @Input('gridReloadData') grid
  component: any;
  gridReloadTriggerArray: any[];


  private _gridReloadTrigger: string;
  @Input() set gridReloadTrigger(data: string) {
    if (data != this.gridReloadTrigger) {
      this._gridReloadTrigger = data;
      this.gridReloadTriggerArray = this._gridReloadTrigger.split(",");
    }
  }
  get gridReloadTrigger() { return this._gridReloadTrigger }



  private subscriptions: Subscription = new Subscription();

  constructor(private _view: ViewContainerRef) {
    let index = (<any>_view)._hostTNode.directiveStart;
    this.component = (<any>_view)._hostLView[index];
  }
  _lastEventData;
  ngOnInit() {
    this.gridReloadTriggerArray?.forEach((element) => {
      this.subscriptions.add(this.component[element].subscribe((event) => {
        if (!this._lastEventData && !event) {
          return;
        }
        if (this._lastEventData !== event) {
          this._lastEventData = event;
          this.grid.refreshData();
        }
      }));
    });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class DelayMessageService {

    @Output() onOpenDelayMessage: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCloseDelayMessage: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {

    }

    showDelayMessage() {
        this.onOpenDelayMessage.emit();
    }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LocalizationService } from "shared/src/localization";

@Injectable({ providedIn: 'root' })
export class TranslateResolve implements Resolve<string[]> {
  constructor(private localizationService: LocalizationService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | any {
    let translateKey = route.data['translateKey'];
    if (!translateKey) {
      console.error(`TranslateResolve failed because 'translateKey' not found in route data. please add as so: 
     resolve: { translations: TranslateResolve},data: { translateKey: 'TRANSLATE_KEY' }`);
    }
    return translateKey ? this.localizationService.TranslateAsync(translateKey) : null;
  }
}
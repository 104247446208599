import { AgGridModule } from '@ag-grid-community/angular';
import { NgModule } from '@angular/core';
import { CustomDateFilterComponent } from './ag-grid-dynamic/framework-components/components/custom-date-filter.component';
import { CustomListFilterComponent } from './ag-grid-dynamic/framework-components/components/custom-list-filter.component';
import { GridLoaderComponent } from './ag-grid-dynamic/framework-components/components/grid-loader/grid-loader.component';
import { DateCellEditor } from './ag-grid-dynamic/framework-components/editors/date-cell-editor';
import { NumericCellEditor } from './ag-grid-dynamic/framework-components/editors/numeric-cell-editor';
import { SelcetCellEditor } from './ag-grid-dynamic/framework-components/editors/select-cell-editor';
import { CustomDateFloatFilterComponent } from './ag-grid-dynamic/framework-components/filters/custom-date-float-filter.component';
import { NumberFloatingFilterComponent } from './ag-grid-dynamic/framework-components/filters/number-floating-filter.component';
import { BtnCellRenderer } from './ag-grid-dynamic/framework-components/renderers/button-cell-renderer.component';
import { CheckBoxIconRenderer } from './ag-grid-dynamic/framework-components/renderers/checkbox-renderer.component';
import { EnumTranslateColumnComponent } from './ag-grid-dynamic/framework-components/renderers/enum-translate-column.component';
import { FormGroupCellRenderer } from './ag-grid-dynamic/framework-components/renderers/form-group-cell-renderer.component';
import { MasterDetailsRendererComponent } from './ag-grid-dynamic/framework-components/renderers/master-details-renderer.component';
import { PipeRendererComponent } from './ag-grid-dynamic/framework-components/renderers/pipe-renderer.component';
import { TemplateRendererComponent } from './ag-grid-dynamic/framework-components/renderers/template-renderer.component';
import { ColumnHeaderTemplateComponent } from './ag-grid-dynamic/framework-components/templates/column-header-template.component';
import { FirstColumnTemplateComponent } from './ag-grid-dynamic/framework-components/templates/first-column-template.component';
import { QuickScanToolPanel } from './ag-grid-dynamic/framework-components/tool-panels/quick-sacn-tool-panel.component';
import { AgGridDynamicComponent } from './ag-grid-dynamic/grid-dynamic/ag-grid-dynamic.component';
import { GridHeaderComponent } from './ag-grid-dynamic/grid-header/grid-header.component';
import { ListSearchComponent } from './list-search/list-search.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FreeSearchComponent } from './free-search/free-search.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import {  SelectButtonModule } from 'primeng/selectbutton';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { GridReloadDataDirective } from './directives/grid-reload-data.directive';
import { InputTextModule } from 'primeng/inputtext';
import { GridEnumTranslatePipe } from './pipes/grid-enum-translate.pipe';
import { ViewObjectDetailsComponent } from './view-object-details/view-object-details.component';
import { DialogModule } from 'primeng/dialog';
import { DelayEventDirective } from './directives/delay-event.directive';
import { GridDateMaskDirective } from './directives/mask/grid-date-mask.directive';



@NgModule({
  declarations: [
    DelayEventDirective,
    ViewObjectDetailsComponent,
    GridEnumTranslatePipe,
    FreeSearchComponent,
    GridDateMaskDirective,
    NumericCellEditor,
    SelcetCellEditor,
    DateCellEditor,
    QuickScanToolPanel,
    GridLoaderComponent,
    FirstColumnTemplateComponent,
    ColumnHeaderTemplateComponent,
    ListSearchComponent,
    GridHeaderComponent, 
    CustomListFilterComponent,
    CustomDateFilterComponent,    
    AgGridDynamicComponent,
    BtnCellRenderer,
    MasterDetailsRendererComponent,
    CheckBoxIconRenderer,
    EnumTranslateColumnComponent,
    FormGroupCellRenderer,
    PipeRendererComponent,
    TemplateRendererComponent,
    CustomDateFloatFilterComponent,
    NumberFloatingFilterComponent,
    DynamicPipe,
    GridReloadDataDirective,
    GridEnumTranslatePipe
  ],

  imports: [
    DialogModule,
    InputTextModule,
    OverlayPanelModule,
    ListboxModule,
    SelectButtonModule,
    TranslateModule,
    CheckboxModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    MultiSelectModule,
    AgGridModule,
    TableModule,
    SkeletonModule,
    DropdownModule,
    TooltipModule,
    ButtonModule,
  ],
  exports: [
    GridDateMaskDirective,
    GridReloadDataDirective,
    FreeSearchComponent,
    NumericCellEditor,
    SelcetCellEditor,
    DateCellEditor,
    ListSearchComponent,
    CustomListFilterComponent,
    CustomDateFilterComponent,
    AgGridDynamicComponent,
    DynamicPipe 
  ]
})
export class GridModule { }

import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { APIInterceptor } from './interceptors/api-url.interceptor';

import { FormGroupComponent } from './form-validations/components/form-group/form-group.component';
import { MessagesComponent } from './form-validations/components/messages/messages.component';
import { FormControlDirective } from './form-validations/directives/form-control.directive';
import { FormValidationDirective } from './form-validations/directives/form-validation.directive';

import { CalendarComponent } from './components/calendar/calendar.component';
import { AppAutofocus } from './directives/auto-focus.directive';
import { DelayClickDirective } from './directives/delay-click.directive';
import { PasswordViewPipe } from './pipes/password-view.pipe';
import { MaxValidatorDirective } from './validators/max/max-validator.directive';
import { MinValidatorDirective } from './validators/min/min-validator.directive';

import { AmountInputComponent } from './components/amount-input/amount-input.component';

import { TelOrMailComponent } from './components/telOrMail/tel-or-mail.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FilterPipe } from './pipes/filter.pipe';

import { TelOrMailDirective } from './validators/telOrMail/telOrMail.directive';

import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';

import { CaptchaComponent } from './components/captcha/captcha.component';
import { CanLeaveComponentGuard } from './guards/can-leave-component.guard';


import { VendorsModule } from 'vendors';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { ObservableFilterPipe } from './pipes/observable-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { SharedCommonModule } from 'shared/src/common';
import { AutoEmailCompletes } from './components/auto-email-ending/auto-email-ending.component';
import { CampaignDetailsComponent } from './components/campaign-details/campaign-details.component';
import { CreditNumberComponent } from './components/credit-number/credit-number.component';
import { SwipeCardComponent } from './components/credit-number/swipe-card/swipe-card.component';

import { DynamicFieldComponent } from './components/dynamic-field/dynamic-field.component';
import { DynamicPopupComponent } from './components/dynamic-popup/dynamic-popup.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { FormControlComponent } from './components/form-control/form-control.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { PasswordComponent } from './components/password/password.component';
import { PhoneOrMailVerificationComponent } from './components/phone-or-mail-verification/phone-or-mail-verification.component';
import { ProductFieldsComponent } from './components/product-template/product-fields/product-fields.component';
import { ProductTemplateComponent } from './components/product-template/product-template.component';
import { RangeDatesComponent } from './components/range-dates/range-dates.component';
import { RangeDatesService } from './components/range-dates/range-dates.service';
import { SearchCreditNumberComponent } from './components/search-credit-number/search-credit-number.component';
import { SelectOptionComponent } from './components/select-option/select-option.component';
import { StepsComponent } from './components/steps/steps.component';
import { TargetComponent } from './components/target/target.component';
import { UploadExcelFileComponent } from './components/upload-excel-file/upload-excel-file.component';
import { UploadLogoComponent } from './components/upload-logo/upload-logo.component';

import { UploadMediaComponent } from './components/upload-media/upload-media.component';
import { BodyAppendedClassDirective } from './directives/body-appended-class.directive';
import { CheckExsistDirective } from './directives/check-exsist.directive';
import { ContainerDirective } from './directives/container.directive';
import { FontDirective } from './directives/font.directive';
import { IfControlEnabledDirective } from './directives/if-control-enabled.directive';
import { InfoPanelDirective } from './directives/info-panel.directive';
import { DateMaskDirective } from './directives/input-masks/date-mask.directive';
import { ExpiryMaskDirective } from './directives/input-masks/expiry-mask.directive';
import { FormControlMaskDirective } from './directives/input-masks/form-control-mask.directive';
import { PhoneMaskDirective } from './directives/input-masks/phone-mask.directive';
import { LabelStyleDirective } from './directives/label-style.directive';
import { LocaleMonthDirective } from './directives/locale-month.directive';
import { MoveNextByMaxLengthDirective } from './directives/move-next-by-max-length.directive';
import { PrimeEmptyMessageDirective } from './directives/prime-empty-message.directive';

import { UniqueDirective } from './directives/unique.directive';
import { FormValidationsService } from './form-validations/services/form-validations.service';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { FractionPipe } from './pipes/fraction.pipe';
import { RemoveEmptiesPipe } from './pipes/remove-empties.pipe';
import { BankService } from './services/bank.service';
import { ClearingService } from './services/clearing.Service';

import { CopyToClipboardService } from './services/copy-to-clipboard.service';

import { BankAccountNumberService } from './validators/bankAccountNumber.service';
import { UploadPdfFileComponent } from './components/upload-pdf-file/upload-pdf-file.component';
const recaptchaSettings: RecaptchaSettings = { siteKey: '6LdIbu4UAAAAAKucuAnTqVrhxCVPrKeV-d8ZLTxQ' }


@NgModule({
  imports: [
    CommonModule,
    SharedCommonModule,
    FormsModule, ReactiveFormsModule,
    // TranslateModule.forChild({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    VendorsModule.forRootOrChild(),
    RecaptchaV3Module, RecaptchaFormsModule,
    //StoreModule.forFeature('shared', commonReducer),
  ],
  exports: [
    SharedCommonModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,

    CaptchaComponent,
    // TranslateModule,
    StepsComponent,
    SelectOptionComponent,
    MaxValidatorDirective,
    MinValidatorDirective,
    TelOrMailDirective,
    FilterPipe,
    FormsModule,
    ReactiveFormsModule,

    DateMaskDirective,
    ExpiryMaskDirective,
    ImageEditorComponent,

    AppAutofocus,
    PasswordViewPipe,
    CalendarComponent,

    DelayClickDirective,
    UploadFileComponent,
    AmountInputComponent,
    TelOrMailComponent,
    ObservableFilterPipe,
    SafePipe,
    RangeDatesComponent,
    FormControlComponent,
    InfoPanelComponent,
    FilterListPipe,
    FormGroupComponent,
    FormControlDirective,
    FormValidationDirective,
    MessagesComponent,
    PasswordComponent,
    CreditNumberComponent,
    FontDirective,
    LocaleMonthDirective,
    InfoPanelDirective,
    FractionPipe,
    ContainerDirective,
    MoveNextByMaxLengthDirective,
    UniqueDirective,
    FormControlErrorComponent,
    FormControlMaskDirective,
    CheckExsistDirective,
    PhoneMaskDirective,
    DynamicPopupComponent,
    PrimeEmptyMessageDirective,
    IfControlEnabledDirective,
    LabelStyleDirective,
    AutoEmailCompletes,
    BodyAppendedClassDirective,
    AccountDetailsComponent,

    UploadLogoComponent,
    UploadPdfFileComponent,
    CampaignDetailsComponent,
    UploadExcelFileComponent,
    DynamicFieldComponent,
    ProductTemplateComponent,
    TargetComponent,
    UploadMediaComponent,
    PhoneOrMailVerificationComponent,
  ],
  declarations: [
    CaptchaComponent,
    StepsComponent,
    SelectOptionComponent,

    MaxValidatorDirective,
    MinValidatorDirective,
    TelOrMailDirective,
    AppAutofocus,
    PasswordViewPipe,
    CalendarComponent,
    CheckExsistDirective,

    RangeDatesComponent,
    DelayClickDirective,
    UploadFileComponent,
    ImageEditorComponent,
    FilterPipe,
    FilterListPipe,
    AmountInputComponent,
    TelOrMailComponent,
    ObservableFilterPipe,
    SafePipe,
    RemoveEmptiesPipe,
    FormControlErrorComponent,
    FormControlComponent,
    PhoneMaskDirective,
    FormControlMaskDirective,
    DateMaskDirective,
    FormGroupComponent,
    FormControlDirective,
    FormValidationDirective,
    MessagesComponent,
    PasswordComponent,
    CreditNumberComponent,
    ExpiryMaskDirective,
    MoveNextByMaxLengthDirective,
    FontDirective,
    FractionPipe,
    ContainerDirective,
    UniqueDirective,
    SearchCreditNumberComponent,
    SwipeCardComponent,
    DynamicPopupComponent,
    StepsComponent,
    LocaleMonthDirective,
    InfoPanelComponent,
    InfoPanelDirective,
    IfControlEnabledDirective,
    PrimeEmptyMessageDirective,
    SelectOptionComponent,
    LabelStyleDirective,
    AutoEmailCompletes,
    BodyAppendedClassDirective,
    AccountDetailsComponent,

    UploadLogoComponent,
    CampaignDetailsComponent,
    UploadExcelFileComponent,
    DynamicFieldComponent,
    ProductTemplateComponent,
    ProductFieldsComponent,
    TargetComponent,
    UploadMediaComponent,
    PhoneOrMailVerificationComponent,
    UploadPdfFileComponent
  ],
  providers: [ClearingService, RangeDatesService]
})
export class SharedModule {
  static forRootOrChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,

      // the singletone providers - one instance for all modules
      providers: [
        SharedCommonModule,
        HttpClient,
        CanLeaveComponentGuard,
        DatePipe,
        CopyToClipboardService,
        FilterListPipe,
        BankService,
        BankAccountNumberService,
        FilterPipe,
        DecimalPipe,
        ObservableFilterPipe,
        SafePipe,
        FormValidationsService,
        ClearingService,
        { provide: RECAPTCHA_SETTINGS, useValue: recaptchaSettings },
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: recaptchaSettings.siteKey,
        },
      ]
    };
  }
}

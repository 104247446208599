import { AbstractControl, ValidatorFn } from '@angular/forms';
export const mailOrPhoneValidate = (telMail: string): ValidatorFn => {
    return (control: AbstractControl): {
        [key: string]: any;
    } | null => {
        let res;
        if (control.value != "") {
            if (control.value?.indexOf('@') > -1) {
                res = control.value?.match("^([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$");
            }
            else {
                res = control.value?.match("^[0-9]{9,10}$");
            }
            if (res == null)
                return { telMail: true }
            else
                return null;
        }
        else {
            return { telMail: true }
        }
    };
};

import * as UserActions from '../actions/user.actions';
import { createReducer, on, Action, createFeatureSelector } from '@ngrx/store';
import { AuthData } from '../../model/auth-data.model';
import { AUTH_DATA_STATE_KEY, COMPANY_STATE_KEY } from '../../model/local-storage-keys';
import { Company } from '../../model/company.model';
import { Role } from 'projects/shared/src/public-api';


let lastAuthData = JSON.parse(localStorage.getItem(AUTH_DATA_STATE_KEY)) as AuthData;


export interface AuthUserInfo {
    identity: number, role: Role, firstName: string, lastName: string, mail: string
}
export class AuthUserState {
    authData: AuthData;
    userInfo: AuthUserInfo;
    company: Company;
}
let initialState: AuthUserState = {
    authData: lastAuthData || new AuthData(),
    userInfo: null,
    company: null
}

const createUserReducer = createReducer(
    initialState,
    on(UserActions.setCompany, (state, { companyId, companyCode, numAssociation, companyName, companyMail, isMyChildCompany, parentCompanyId, imgPath,registeredEasyCountService,showDecodingToken }) => {
        const updatedState = { ...state, company: { ... { companyId, companyCode, numAssociation, companyName, companyMail, isMyChildCompany, parentCompanyId, imgPath,registeredEasyCountService,showDecodingToken } } };
        saveCompany(updatedState.company);
        return updatedState;
    }),
    on(UserActions.setUser, (state, { identity, role, firstName, lastName,mail }) => {
        return { ...state, userInfo: { ...{ identity, role, firstName, lastName,mail  } } };
    }),
    on(UserActions.setAuthData, (state, { authData }) => {
        saveAuthData(authData);
        return { ...state, authData: { ...authData } };
    }),
    on(UserActions.clearUserAuthData, (state) => {
        saveAuthData(null);
        return { ...state, authData: new AuthData(), userInfo: null };
    }),
    on(UserActions.clearCompany, (state) => {
        return { ...state, company: null }
    }),
    on(UserActions.setCompaniesList, (state, { companiesList }) => {
        return { ...state, ...{ companiesList } };
    }),

);
function saveAuthData(authData) {
    localStorage.setItem(AUTH_DATA_STATE_KEY, authData ? JSON.stringify(authData) : null);
}
function saveCompany(company) {
    localStorage.setItem(COMPANY_STATE_KEY, company ? JSON.stringify(company) : null);
}


export function userReducer(state: AuthUserState | undefined, action: Action) {
    return createUserReducer(state, action);
}


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppAuthService } from '../services/app-auth.service';
import { CompanySettingsService } from '../services/company-settings.service';
import { isNumeric } from 'shared/src/public-api';

@Injectable()
export class CompanyGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AppAuthService,
        private companyService: CompanySettingsService
    ) {
    }
    //#region Private members
    subscriptions: any = [];
    //#endregion

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        let company = this.authService.company;
        let router: any = route;
        let companyFromUrl = router.params.company;
        if (!companyFromUrl || companyFromUrl === 'null' || !isNumeric(companyFromUrl)) {
            this.router.navigate(['/login']);
            return of(false);
        }

        if (company?.companyCode != companyFromUrl) {
            return this.authService.changeCompany(companyFromUrl).pipe(map((res: any) => {
                if (res.succeeded) {
                    if (res.reminder)
                        setTimeout(() => {
                            this.companyService.openReminder(res.reminder);
                        }, 1000);
                    return true;
                }
                else {
                    this.router.navigate(['/login']);
                    return false;
                }
            }));
        }
        return of(true);
        //  });
    }
}

import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SideBarService {

  styleClass:string;
  constructor() {
  }
  //#region Subject Events
  public contentSideBar: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  public contentDeActivate: Subject<() => Promise<any>> = new Subject<() => Promise<any>>();
  public onClose: Subject<any> = new Subject<any>();
  //#endregion

  //#region Public Methods
  setContentSideBar(contentSideBar: TemplateRef<any>, styleClass:string = null) {
    this.styleClass = styleClass;
    this.contentSideBar.next(contentSideBar)
  }
  setContentDeActivate(contentDeActivate: () => Promise<any>) {
    this.contentDeActivate.next(contentDeActivate);
  }

  close() {
    this.setContentSideBar(null);
    this.setContentDeActivate(null);
    this.onClose.next();
  }
  //#endregion
}
import { DynamicPopupMode } from "shared/src/enum";

export interface popupData {
    header: string;
    style: {};
    mode: DynamicPopupMode,
    acceptLabel: string,
    acceptIcon: string,
    acceptTooltip: string,
    appendTo: string,
    cancelLabel: string,
    cancelIcon: string,
    cancelTooltip: string,
    closeOnSave: boolean,
    dismissableMask: boolean,
    modal: boolean,
    subHeader: string,
    spreadButtons: boolean,
    showAcceptButton: boolean,
    disabled: boolean,
    showCancelButton: boolean,
    accept: any,
    cancel: any,
    class: string,
    showIcon: boolean,
    mobileStyle:{}
}

export let defaultPopupData: popupData = {
    header: "",
    style: { width: '500px' },
    mobileStyle:{width:'90%'},
    mode: DynamicPopupMode.Success,
    acceptIcon: '',
    acceptLabel: 'GENERAL.OK',
    acceptTooltip: '',
    appendTo: 'body',
    cancelIcon: 'pi pi-times',
    cancelLabel: 'GENERAL.CANCEL',
    cancelTooltip: '',
    closeOnSave: true,
    dismissableMask: false,
    modal: true,
    subHeader: '',
    spreadButtons: true,
    showAcceptButton: true,
    disabled: false,
    showCancelButton: true,
    accept: null,
    cancel: null,
    class: null,
    showIcon: true
}
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';


@Component({
  selector: 'template-renderer',
  template: `
    <ng-container [ngTemplateOutlet]=component [ngTemplateOutletContext]="{$implicit: getData()}">
    </ng-container>
  `,
  styles: []
})
export class TemplateRendererComponent implements AgRendererComponent, OnDestroy {

  component: any;
  params: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnDestroy(): void {
  }

  refresh(params: ICellRendererParams): boolean { return false}

  agInit(params: any): void {
    this.params = params;
  }

  getData() {
    if(this.params.colDef.cellRendererParams?.colName) {
      return {colDef: this.params.colDef.cellRendererParams?.colName, data: this.params.node.data }
    }
    return this.params.node.data;
  }

  ngAfterViewInit() {
    this.component = this.params.view;
    this.cdr.markForCheck();
  }

}

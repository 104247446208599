import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoadingCommonState } from 'shared/src/public-api';
import { AppState } from '../app-state.index';
import { LARGE_SCREEN, MAX_ZOOM_SCREEN, SMALL_SCREEN, XLARGE_SCREEN } from '../kesher-shared/common/consts';

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    // encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class MainComponent implements OnInit {
    loginModel: { username: string, password: string };
    isLoading$: any;
    backgroundStyle: any
    zoom: any;
    constructor(private store: Store<AppState>) {
        this.screenZoom(window.innerWidth)
    }

    ngOnInit() {
        this.isLoading$ = this.store.select(selectLoadingCommonState);
    }
    onResize(event) {
        const screenWidth = event.target.innerWidth
        this.screenZoom(screenWidth);
    }
    screenZoom(screenWidth) {
        if (screenWidth >= MAX_ZOOM_SCREEN) {
            this.zoom = MAX_ZOOM_SCREEN / XLARGE_SCREEN;
        }
        if (screenWidth < MAX_ZOOM_SCREEN && screenWidth > LARGE_SCREEN) {
            this.zoom = screenWidth / XLARGE_SCREEN;
        }
        if (screenWidth <= LARGE_SCREEN && screenWidth > SMALL_SCREEN) {
            this.zoom = screenWidth / XLARGE_SCREEN * 1.222;
        }
        if (screenWidth <= SMALL_SCREEN) {
            this.zoom = screenWidth / SMALL_SCREEN;
        }
    }
}



<code-verification [(verificationCode)]="verificationCode" [displaySendAgainByMail]="false"
    #codeVerify (verificationCodeChange)="onVerificationCodeChange($event)"
    (verificationCodeCancel)="onVerificationCodeCancel()" (sendAgainVerificationCode)="sendVerificationCode()"
    [verificationCodeDetails]="verificationCodeDetails" [(verificationType)]="verificationCodeDetails.verificationType" [lightTitle]="false">
</code-verification>
<div *ngIf="showVerifyButton" class="my-2 gap-1 flex justify-content-center">
    <button pButton (click)="verificationOk()" style="margin-left: 10px;"
        [disabled]="!codeVerify?.formGroup?.valid">{{'LOGIN.VERIFICATIN_TITEL'|
        translate}}</button>
    <button pButton (click)="sendVerificationCode()">{{ 'VERIFICATION.SEND_AGAIN'| translate }}</button>
</div>
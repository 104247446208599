import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { SelectCellEditorParams, ValueDescrition } from './select-cell-editor-params';
import { Dropdown } from 'primeng/dropdown';

@Component({
    selector: 'select-cell-editor',
    template: `<p-dropdown [style]="{width: '100%', height: '100%' }" [showClear]="true" [autoDisplayFirst]="false"
                [options]="values" optionLabel="description" [(ngModel)]="selectedValue" appendTo="body" #dropdown>
            </p-dropdown>`,
    styles: [`p-dropdown { width: 100%; height: 100% }`]
})

export class SelcetCellEditor implements ICellEditorAngularComp, AfterViewInit {
    values;
    selectedValue: ValueDescrition;

    @ViewChild('dropdown') dropdown: Dropdown;

    agInit(params: SelectCellEditorParams) {
        this.values = params.values;
        if (this.values && params.value) {
            let selected = this.values.find(v => v.value == params.value);
            this.selectedValue = selected;
        }
        if (this.dropdown) {

        }
    }

    getValue() {
        return this.selectedValue?.value;
    }

    isPopup?(): boolean {
        return false;
    }

    focusIn(): void {
        this.dropdown.applyFocus();
    }

    ngAfterViewInit() {
        if (this.dropdown)
            this.dropdown.el.nativeElement.parentElement.style.width = "100%";
    }
    // focusOut?(): void {
    //     throw new Error('Method not implemented.');
    // }
    // afterGuiAttached?(): void {
    //     throw new Error('Method not implemented.');
    // }


}
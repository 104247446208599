
    <p-overlayPanel #op bodyAppendedClass="messages-panel" [dismissable]="false">
        <i class="pi pi-times closeButton" (click)="closeMenuItems()"></i> 
        <p-listbox [listStyle]="{'max-height':'417px'}" styleClass="border-0" [options]="messages" *ngIf="displayPopup"
            optionLabel="popupTitle" emptyMessage="עדיין לא פורסמו מודעות">
            <ng-template let-message pTemplate="item">
                <message-popup [message]="message"></message-popup>
            </ng-template>
        </p-listbox>
        <div class="p-2 card flex manage">
            <a [routerLink]="href" class="pt-3 mr-2" (click)="onGoToManagement()">ניהול</a>
            <button pButton pRipple type="button" class="mr-auto ml-3" label="פרסם עכשיו" (click)="navigateToAdvertiseNow()"></button>
        </div>
    </p-overlayPanel>

    <div class="message-footer w-full fixed bottom-0 py-3 flex gap-2 cursor-pointer" (click)="clickOnMenuItems($event)" type="text" #elm>
        <i class="pi pi-circle-on circleIcon" *ngIf="showRedButton"></i>
        <i *ngIf="!showRedButton"></i>
        <div class="text-lg text-white menuHeader">{{'MENU.MESSAGES_BOARD'|translate}}</div>
        <div class="mr-auto ml-3 text-white navigators">
            <i class="pi pi-angle-up"></i>
            <i class="pi pi-angle-down"></i>
        </div>
    </div>

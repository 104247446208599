export enum ServiceProductRegisterValidation {
    exist = 1,
    notExist,
    productTypeNotSupported,
    serviceHasNoProductType,
    ProductAlreadyRegisterToThisCompany
}
export enum ServiceProductType {
    terminal = 1,
    kehilotCashBox = 2,
    kehilotMobile = 3

}
export enum ServiceRegisterStatus {
    New = 1,
    Accepted,
    Canceled
}

export enum UpdateServiceCompanyItemPriceField {
    accepted = 1,
    companyDefaultPrice,
    companyDefaultStartChargeAction,
    discount,
    discountType,
    priceDetails_startChargeAction,
    priceDetails_maxChargeAction,
    priceDetails_maxChargePrice,
    priceDetails_paymentsCount,
    autoInvoice,
    comments
}
export enum UpdateChargeItemPriceField {
    price = 1,
    priceDetails_startChargeAction,
    priceDetails_maxChargeAction,
    priceDetails_maxChargePrice,
    priceDetails_paymentsCount
}

export enum UpdateFieldStatus {
    succees = 1,
    notSupportedField,
    invalidValue,
    valueEqualToExistValue
}
export enum ServiceDiscountType {
    none=0,
    sum = 1,
    percentage,
    fixedPrice,
    additionalSum
}
export enum ServiceChargeTime {
    immediately = 1,
    startOfMonth,
    endOfMonth
}
export enum ServiceChargeTypes {
    oneTime = 1,
    monthly,
    startOfMonth,
    actionsCount,
    percentages
}
export enum ServiceChargeByActionsItemType {
    creaditClearing = 1,
    cancelClearing,
    newSendFormByKesher,
    useNewForms,
    returns,
    documents
}
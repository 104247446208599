import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseResponseModel } from 'projects/kesher-site/src/app/kesher-shared/model/baseResponse.model';
import { CompanySettingsService } from '../../../../services/company-settings.service';

@Injectable()

export class CompanyCodeService {

    constructor(private companySettingsService: CompanySettingsService) { }


    asyncCompanyCodeValidator = (control: FormControl, isnew: boolean = false) => {

        return new Promise(
            (resolve, reject) => {
                const value = control.value;
                if (!value && isnew)
                    resolve(null)
                
                if (value?.length > 10)
                    resolve({ longCompanyCode: true })

                let response: BaseResponseModel;

                let request: any = { companyCode: control.value, isNew: isnew };
                this.companySettingsService.validateCompanyCode(request).subscribe(res => {
                    response = res as BaseResponseModel;
                    if (response.succeeded == true)
                        resolve(null);
                    else
                        resolve({ wrongCompanyCode: true });
                });
            }
        );

    }
}



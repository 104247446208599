<div #codeVerification>
    <div class="align-items-center flex flex-column" [ngClass]="lightTitle?'lightTitle':''">
        <label> {{ verificationCodeMsg }}</label>

        <label *ngIf="verificationCodeDetails.verificationType != 1">{{ 'LOGIN.MESSAGES.ENTER_CODE_VERIFICATION' |
            translate }}</label>
        <label *ngIf="verificationCodeDetails.verificationType == 1">{{ 'LOGIN.MESSAGES.ENTER_EMAIL_CODE_VERIFICATION' |
            translate }}</label>
    </div>
    <form [formGroup]="formGroup" (keydown.enter)="okClicked()">
        <div class="form-group ">
            <input *ngIf="verificationCodeDetails.verificationType != 1"  type="number"
                formControlName="VerificationCode"  pInputText   id="code" name="code" (blur)="inputFocus()"
                (change)="setModel()" />
            <input *ngIf="verificationCodeDetails.verificationType == 1" formControlName="VerificationCode" pInputText
                id="code" name="code" (blur)="inputFocus()" (change)="setModel()" />
        </div>
    </form>
</div>

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent implements OnInit {

  @ViewChild("logoUploader") logoUploader: any;
  @Input() imgUrl: string;
  @Output() result: EventEmitter<any> = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  uploadFile(event) {
    if (!event || !event.files) return;
    let reader = new FileReader();
    reader.onload = (_event) => {
      this.result.emit(reader.result);
    }
    reader.readAsDataURL(event.currentFiles[0]);
    this.logoUploader.clear();
  }

  deleteGraphImage() {
    this.result.emit(null);
  }
}

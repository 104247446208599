<div #telOrMail_Parent provide-parent-form>
    <div class="p-field w-full">
        <span class="p-input-icon-right w-full">
            <i class="pi pi-user"></i>
            <input pInputText id="telOrMail" name="telOrMail" #telOrMail="ngModel" class="w-full" required tel-mail [(ngModel)]="telOrMailNum"
                type="text" placeholder="{{'LOGIN.ENTER_MAIL_OR_PHONE' | translate}}"/>
            <small id="telOrMail-help" class="p-error" *ngIf="telOrMail.errors?.required && (telOrMail.touched)">
                {{'ERROR_MESSAGES.REQUIRED'|translate}}
            </small>
            <small id="telOrMail-help" class="p-error"
                *ngIf="telOrMail.errors?.telMail && (telOrMail.touched && telOrMail.dirty)">
                {{'ERROR_MESSAGES.MAIL_OR_PHONE_PATTERN_WORNG'|translate}}
            </small>
        </span>
    </div>
</div>

import { Injectable } from '@angular/core';
// import { ButtonType } from '../components/ag-grid-dynamic/grid-types';
import {
    AdverstingKehilotOccupation,
    BankAuthStatus,
    BooleanEnum,
    BroadcastFileStatus,
    CRMServices,
    CampiagnLInkType,
    CampignType,
    CardUsingOptions,
    CategoryStatus,
    ChargeoptionType,
    CompanyStatus,
    CreditFileStatus,
    CreditType,
    CurrencyType,
    CustomerFilterTabStatus,
    CustomerStatus,
    DataFieldType,
    DetailedObligationStatus,
    DetailedTransactionStatus,
    DigitalFormStatus,
    DigitalFormsField,
    DocumentTypes,
    DynamicFieldsType,
    ExportReportDates,
    FamilyClosenessType,
    IframeType,
    Immediacy,
    IncludsVat,
    ItemAlignment,
    KesherProductsCategory,
    Language,
    LinkageCurrency,
    MobileMode,
    MobileStatus,
    NotPassedTransactionStatus,
    ObligationStatus,
    ObligationViewStatus,
    ObligationWrongToTreatStatus,
    ObligationsSummaryType,
    OperationType,
    PackageType,
    PaymentPageFieldType,
    PaymentPageType,
    PaymentPagesStatus,
    PaymentType,
    PopupType,
    PrivateStatus,
    ProduceDocument, ProjectStatus,
    RangeDates,
    RavKavTypes,
    ReportStatus,
    ResolutionType,
    Role,
    Service,
    ServiceChargeTypeEnum,
    ServicePaymentType,
    SingleCompanyStatus,
    SpecialLabelTypes,
    StudyPlaceType,
    SummaryGroupOptions,
    SummarySaleGroupOptions,
    TeamStatus,
    TerminalStatus,
    TerminalTypes,
    TextAlign,
    TransactionSearchOptions,
    TransactionStatus,
    TransactionSumType,
    TransactionType,
    TransferType,
    TryAgainOptions,
    Validators,
    ViewType,
    WorngEzCountFileData
} from './model/enums';
import { ServiceChargeByActionsItemType, ServiceChargeTypes, ServiceDiscountType, ServiceProductType, ServiceRegisterStatus } from './model/kesher-enums';

@Injectable({ providedIn: 'root' })
export class EnumService {

    // enumsNames = {
    //   TransactionSumType: "TransactionSumType",
    //   CustomerStatus: { type: CustomerStatus, name: "CustomerStatus", values: [1, 2, 3, 4] }
    // }
    private enumNames: Map<any, any>;
    constructor() {
        this.enumNames = new Map();
        this.enumNames.set(TransactionSumType, { name: "TransactionSumType", list: [1, 2, 16, 19, 5] });
        this.enumNames.set(FamilyClosenessType, { name: "FamilyClosenessType", list: [1, 2] });
        this.enumNames.set(PrivateStatus, { name: "PrivateStatus", list: [1, 2, 3] });
        this.enumNames.set(RangeDates, { name: "RangeDates", list: [1, 2, 3, 4, 5, 6] });
        this.enumNames.set(TransactionSearchOptions, { name: "TransactionSearchOptions", list: [1, 2] });
        this.enumNames.set(CustomerStatus, { name: "CustomerStatus", list: [1, 2] });
        this.enumNames.set(TransactionStatus, { name: "TransactionStatus", list: [1, 2] });
        // this.enumNames.set(ButtonType, { name: "ButtonType", list: [1, 2, 3, 4, 5, 6, 7, 8, 9] });
        this.enumNames.set(CardUsingOptions, { name: "CardUsingOptions", list: [1, 2] });
        this.enumNames.set(CurrencyType, { name: "CurrencyType", list: [1, 2, 16, 19] });
        this.enumNames.set(ChargeoptionType, { name: "ChargeoptionType", list: [1, 2, 3, 4, 5, 6] });
        this.enumNames.set(CustomerFilterTabStatus, { name: "CustomerFilterTabStatus", list: [1, 2, 3, 4] });
        this.enumNames.set(DocumentTypes, { name: "DocumentTypes", list: [35, 39, 40] });
        this.enumNames.set(FamilyClosenessType, { name: "FamilyClosenessType", list: [1, 2] });
        this.enumNames.set(Immediacy, { name: "Immediacy", list: [0, 1, 2] });
        this.enumNames.set(MobileStatus, { name: "MobileStatus", list: [0, 1, 2, 3, 4] });
        this.enumNames.set(MobileMode, { name: "MobileMode", list: [0, 1, 2] });
        this.enumNames.set(ObligationStatus, { name: "ObligationStatus", list: [1, 2, 3, 4, 7, 8, 9] });
        this.enumNames.set(DetailedObligationStatus, { name: "DetailedObligationStatus", list: [1, 2, 3, 4, 5, 6, 7, 8, 9] });
        this.enumNames.set(ObligationViewStatus, { name: "ObligationViewStatus", list: [0, 1, 2, 3, 4, 5] });
        this.enumNames.set(ObligationWrongToTreatStatus, { name: "ObligationWrongToTreatStatus", list: [1, 2, 3] });
        this.enumNames.set(OperationType, { name: "OperationType", list: [0, 1, 2] });
        this.enumNames.set(PaymentPagesStatus, { name: "PaymentPagesStatus", list: [0, 1] });
        this.enumNames.set(PaymentType, { name: "PaymentType", list: [1, 2, 3, 4] });
        this.enumNames.set(PrivateStatus, { name: "PrivateStatus", list: [1, 2, 3] });
        this.enumNames.set(ProduceDocument, { name: "ProduceDocument", list: [1, 2, 3] });
        this.enumNames.set(ProjectStatus, { name: "ProjectStatus", list: [0, 1] });
        this.enumNames.set(RavKavTypes, { name: "RavKavTypes", list: [1, 2] });
        this.enumNames.set(RangeDates, { name: "RangeDates", list: [1, 2, 3, 4, 5, 6] });
        this.enumNames.set(ReportStatus, { name: "ReportStatus", list: [1, 2, 3, 4, 5] });
        this.enumNames.set(TransactionSearchOptions, { name: "TransactionSearchOptions", list: [1, 2] });
        this.enumNames.set(TransactionStatus, { name: "TransactionStatus", list: [1, 2] });
        this.enumNames.set(TransactionSumType, { name: "TransactionSumType", list: [1, 2, 16, 19, 5] });
        this.enumNames.set(TryAgainOptions, { name: "TryAgainOptions", list: [1, 2] });
        this.enumNames.set(Validators, { name: "Validators", list: [0] });
        this.enumNames.set(DigitalFormsField, { name: "DigitalFormsField", list: [1, 13, 14, 16, 17, 18, 19, 25, 30, 31, 32, 33, 34, 35] });
        this.enumNames.set(TextAlign, { name: "TextAlign", list: [0, 1, 2, 3] });
        this.enumNames.set(DigitalFormsField, { name: "DigitalFormsField", list: [0, 13, 14, 16, 17, 18, 19, 20, 24, 25, 26, 30, 31, 32, 33, 34, 35, 37] });
        this.enumNames.set(ObligationsSummaryType, { name: "ObligationsSummaryType", list: [1, 2] });
        this.enumNames.set(NotPassedTransactionStatus, { name: "NotPassedTransactionStatus", list: [1, 2, 4] });
        this.enumNames.set(BooleanEnum, { name: "BooleanEnum", list: [0, 1] });
        this.enumNames.set(Service, { name: "Service", list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] });
        this.enumNames.set(LinkageCurrency, { name: "LinkageCurrency", list: [0, 1, 2] });
        this.enumNames.set(BankAuthStatus, { name: "BankAuthStatus", list: [0, 1, 2, 3, -1] });
        this.enumNames.set(ServiceProductType, { name: "ServiceProductType", list: [1, 2, 3] });
        this.enumNames.set(ServiceRegisterStatus, { name: "ServiceRegisterStatus", list: [1, 2, 3] });
        this.enumNames.set(DigitalFormStatus, { name: "DigitalFormStatus", list: [0, 1, 2] });
        this.enumNames.set(Language, { name: "Language", list: [1, 2] });
        this.enumNames.set(PaymentPageFieldType, { name: "PaymentPageFieldType", list: [2, 7, 8, 9] });
        this.enumNames.set(DetailedTransactionStatus, { name: "DetailedTransactionStatus", list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 18, 19, 21] });
        this.enumNames.set(PaymentPageType, { name: "PaymentPageType", list: [1, 2] });
        this.enumNames.set(ServiceDiscountType, { name: "ServiceDiscountType", list: [0, 1, 2, 3, 4] });
        this.enumNames.set(ServiceChargeTypes, { name: "ServiceChargeTypes", list: [1, 2, 3, 4, 5] });
        this.enumNames.set(ServiceChargeByActionsItemType, { name: "ServiceChargeByActionsItemType", list: [1, 2, 3, 4, 5, 6] });
        this.enumNames.set(IncludsVat, { name: "IncludsVat", list: [0, 1] });
        this.enumNames.set(TransactionType, { name: "TransactionType", list: [1, 2] });
        this.enumNames.set(CreditType, { name: "CreditType", list: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11] });
        this.enumNames.set(ResolutionType, { name: "ResolutionType", list: [0, 1] });
        this.enumNames.set(SpecialLabelTypes, { name: "SpecialLabelTypes", list: [0, 1] });
        this.enumNames.set(Role, { name: "Role", list: [2, 3, 5, 8, 10] });
        this.enumNames.set(StudyPlaceType, { name: "StudyPlaceType", list: [1, 2, 3, 4, 5] });
        this.enumNames.set(SummaryGroupOptions, { name: "SummaryGroupOptions", list: [0, 1] });
        this.enumNames.set(TerminalStatus, { name: "TerminalStatus", list: [0, 1] });
        this.enumNames.set(ItemAlignment, { name: "ItemAlignment", list: [0, 1, 2] });
        this.enumNames.set(KesherProductsCategory, { name: "KesherProductsCategory", list: [1, 2, 3, 4] });
        this.enumNames.set(ServiceChargeTypeEnum, { name: "ServiceChargeTypeEnum", list: [1, 2, 3, 4, 5] });
        this.enumNames.set(AdverstingKehilotOccupation, { name: "AdverstingKehilotOccupation", list: [1, 2] });
        this.enumNames.set(ViewType, { name: "AdverstingKehilotOccupation", list: [1, 2] });
        this.enumNames.set(PopupType, { name: "PopupType", list: [1, 2, 3, 4, 5] });
        this.enumNames.set(ServicePaymentType, { name: "ServicePaymentType", list: [1, 2, 3, 4, 5, 6, 7, 8] });
        this.enumNames.set(TerminalTypes, { name: "TerminalTypes", list: [1, 2] });
        this.enumNames.set(PackageType, { name: "PackageType", list: [1, 2, 3] });
        this.enumNames.set(TeamStatus, { name: "TeamStatus", list: [0, 1, 2] });
        this.enumNames.set(TransferType, { name: "TransferType", list: [1, 2] });
        this.enumNames.set(DynamicFieldsType, { name: "DynamicFieldsType", list: [1, 2, 3, 6] });
        this.enumNames.set(DataFieldType, { name: "DataFieldType", list: [1, 2, 3] });
        this.enumNames.set(CreditFileStatus, { name: "CreditFileStatus", list: [1, 2, 3] });
        this.enumNames.set(CategoryStatus, { name: "CategoryStatus", list: [0, 1] });
        this.enumNames.set(SummarySaleGroupOptions, { name: "SummarySaleGroupOptions", list: [0, 1] });
        this.enumNames.set(SingleCompanyStatus, { name: "SingleCompanyStatus", list: [1, 2] });
        this.enumNames.set(CompanyStatus, { name: "CompanyStatus", list: [0, 1, 2] });
        this.enumNames.set(IframeType, { name: "IframeType", list: [1, 2] });
        this.enumNames.set(WorngEzCountFileData, { name: "WorngEzCountFileData", list: [1, 2, 3] });
        this.enumNames.set(CRMServices, { name: "CRMServices", list: [1, 2, 3] });
        this.enumNames.set(CampignType, { name: "CampignType", list: [1, 2] });
        this.enumNames.set(ExportReportDates, { name: "ExportReportDates", list: [1, 2, 3] });
        this.enumNames.set(CampiagnLInkType, { name: "CampiagnLInkType", list: [1, 2, 3] });
        this.enumNames.set(BroadcastFileStatus, { name: "BroadcastFileStatus", list: [0, 1, 2] });

    }

    getEnumName(enumType) {
        return this.enumNames.get(enumType)?.name;
    }
    getEnumValues(enumType) {
        return this.enumNames.get(enumType)?.list;
    }
}

const asyncFilter = async (arr, predicate) => Promise.all(arr.map(predicate))
    .then((results) => arr.filter((_v, index) => results[index]));


const isNumeric = (num) => {
    return !isNaN(num)
}
const groupBy = (array: any[], groupByField: string) => {
    return array.reduce((r, a) => {
        r[a[groupByField]] = [...r[a[groupByField]] || [], a];
        return r;
    }, {});
}

const enumToArray = (enumType: any): any[] => {
    let array = []
    const keys = Object.keys(enumType).filter(k => typeof enumType[k as any] === "number");
    keys.forEach(key => {
        array.push({ label: enumType[key as any], value: key });
    });
    return array;
}
const isAndroid = (): boolean => {
    return /Android|BlackBerry/.test(navigator.userAgent);
}

const isiOS = (): boolean => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export { asyncFilter, isNumeric, groupBy, enumToArray, isAndroid, isiOS };
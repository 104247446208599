import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserResolve } from './core/resolver/user-resolver';
import { AuthGuard } from './kesher-shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('projects/kesher-site/src/app/login/login.module').then(m => m.LoginModule) },
  { path: '', resolve: { user: UserResolve }, loadChildren: () => import('projects/kesher-site/src/app/core/core.module').then(m => m.CoreModule), canActivate: [AuthGuard] },
  { path: 'public', loadChildren: () => import('projects/kesher-site/src/app/public/public.module').then(m => m.PublicModule) },

  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

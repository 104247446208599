import { Localization } from 'shared/src/enum';
import * as SharedCommonActions from '../actions/‏‏shared-common.action'
import { createReducer, on, Action } from '@ngrx/store';



export class SharedCommonState {
    localization: Localization
    isLoading: boolean;
    spinnerLoading: boolean;
    
}
let initialState: SharedCommonState = {
    localization: null,
    isLoading: false,
    spinnerLoading: false,
   
}

const createSharedCommonReducer = createReducer(
    initialState,
    on(SharedCommonActions.setIsLoading, (state, { isLoading, divElement }) => {
        return {
            ...state, isLoading, divElement
        };
    }),
    on(SharedCommonActions.setSpinnerLoading, (state, { spinnerLoading, divElement }) => {
        return {
            ...state, spinnerLoading, divElement
        };
    }),
    on(SharedCommonActions.setLocalization, (state, { localization }) => {
        return {
            ...state, localization
        };
    }),
);

export function SharedCommonReducer(state: SharedCommonState | undefined, action: Action) {
    return createSharedCommonReducer(state, action);
}


import { SimpleChanges } from "@angular/core";
import { Directive, forwardRef, Input, OnChanges, OnInit } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";
import { mailOrPhoneValidate } from "./validator";

const MAIL_OR_PHONE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TelOrMailDirective),
  multi: true
};

@Directive({
  selector: '[tel-mail]',
  providers: [MAIL_OR_PHONE_VALIDATOR]
})
export class TelOrMailDirective implements Validator, OnInit, OnChanges {
  //#region  @Input/@Output Members
  @Input() telOrMail: string;
  //#endregion

  //#region private Members
  private validator: ValidatorFn;
  private onChange: () => void;
  //#endregion

  //#region  Angular lifecycle Methods
  ngOnInit() {
    this.validator = mailOrPhoneValidate(this.telOrMail);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const key in changes) {
      if (key === 'tel-mail') {
        this.validator = mailOrPhoneValidate(changes[key].currentValue);
        if (this.onChange) this.onChange();
      }
    }
  }
  //#endregion

  //#region Validator Implementation 
  validate(c: AbstractControl): { [key: string]: any } | null {
    return this.validator(c);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
  //#endregion
}

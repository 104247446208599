import {
    CommonState, commonReducer, selectAuthData, selectUserInfo, selectCompany,
    selectRole,
} from "./kesher-shared/state/state.index";
import { createSelector } from "@ngrx/store";

const reducers = {
    common: commonReducer,
};

interface AppState {
    common: CommonState
}

export { reducers, AppState };

export const selectCommon = (state) => state.common;
export const selecCompanyInCommonState = createSelector(selectCommon, selectCompany)
export const selectUserInCommonState = createSelector(selectCommon, selectUserInfo)
export const selectAuthDataCommonState = createSelector(selectCommon, selectAuthData)
export const selectRoleInCommonState = createSelector(selectCommon, selectRole)









import { AgRendererComponent } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';

// import { PipeRendererParams } from '../../grid-types';

@Component({
  selector: 'app-pipe-renderer',
  template: `
    <span class="area" *ngIf="!ignoreValue()" [ngClass]="cell.class">
      <span *ngIf="params.data"> {{ (params.data[cell.prefix.field] ?? '') + cell.prefix?.sep }} </span>
      <span class="main" *ngIf="cell.pipe.type"> {{ value | dynamic: cell.pipe.type:params.data:pipeParams ?? [] }} </span>
      <span class="main" *ngIf="!cell.pipe.type">{{value}}</span>        
      <div *ngIf="params.data"> {{ params.data[cell.suffix.field] ? (cell.suffix.sep ?? '') + params.data[cell.suffix.field] : ''}} </div>
    </span>
    <span *ngIf="ignoreValue()">{{ value }}</span>
  `,
  styles: [
    `
    .area.p-bold > span:not(.main) {
        font-weight: 800;
      }
    `
  ]
})
export class PipeRendererComponent implements AgRendererComponent, OnDestroy {
  seperator: any;
  params: any;
  value: string;
  cell: any;
  search: string = "";
  class: string[] = [];
  pipeParams: any[] = [];

  constructor() { }

  ngOnDestroy(): void {
  }
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.initView();
  }

  ignoreValue() {
    return this.value == this.cell.ignoreValue
  }

  private initView() {
    this.cell = this.params.cellParams;
    this.initPipeParams();
    this.initPrefixAndSuffix();
  }

  private initPipeParams() {
    if (this.cell.pipe.generateParamsByRowValue) {
      this.pipeParams = this.cell.pipe.generateParamsByRowValue(this.params.data);
    }
    else {
      this.pipeParams = this.cell.pipe.params;
    }
  }

  private initPrefixAndSuffix() {
    // if(this.params.data) {
    //   this.cell.prefix.value = this.params.data[this.cell.prefix.field];
    //   this.cell.suffix.value = this.params.data[this.cell.suffix.field];
    // }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { VerificationType } from 'shared/src/enum';
import { LocalizationService } from 'shared/src/localization';


export class verificationCodeModel {
  verificationCodeSend: boolean;
  encryptedNumMailOrPhone: string;
  verificationType: VerificationType;
}

@Component({
  selector: 'code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeVerificationComponent {

  @Output() verificationCodeCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() verificationCodeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() verificationTypeChange: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();
  @Output() sendAgainVerificationCode: EventEmitter<VerificationType> = new EventEmitter<VerificationType>();

  @ViewChild("form") form: NgForm;
  @ViewChild("codeVerification") codeVerification: ElementRef;


  constructor(private _cdr: ChangeDetectorRef, private localizationService: LocalizationService) { }

  verificationCodeMsg: string
  verificationTypeTranslate: string
  mailOrPhoneSendAgainLabel: string
  disableOkButton: boolean;
  formGroup: FormGroup;
  @Input() lightTitle:boolean=false;
  //#region Input members

  private _verificationCodeDetails: verificationCodeModel
  @Input() set verificationCodeDetails(val) {
    this._verificationCodeDetails = val;
    this.setTranslations(this.verificationCodeDetails.verificationType);
  }
  get verificationCodeDetails() { return this._verificationCodeDetails }

  private _verificationCode: string;
  @Input() set verificationCode(val) {
    if (val != this._verificationCode) {
      this._verificationCode = val;
      if (val) {
        this.disableOkButton = false;
      }
    }
  }
  get verificationCode() { return this._verificationCode }

  private _error: string;
  @Input() set error(val) {
    this._error = val;
    if (val) {
      this.clear();
      this.disableOkButton = true;
    }
  }
  get error() { return this._error }

  @Input() set verificationType(val: VerificationType) {
    this.verificationCodeDetails.verificationType = val;
  }
  get verificationType() { return this.verificationCodeDetails.verificationType }

  private _displaySendAgainByMail = true;
  @Input() set displaySendAgainByMail(val: boolean) {
    this._displaySendAgainByMail = val;
  }
  get displaySendAgainByMail() { return this._displaySendAgainByMail }
  //#endregion

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {  
    setTimeout(() => {
      this.codeVerification.nativeElement.querySelector('input')?.focus(); 
    }, 200);
  }
  //#region Events Methods

  sendAgainClicked(verificationType: VerificationType) {
    this.clear();
    this.verificationCodeDetails.verificationType = verificationType;
    this.sendAgainVerificationCode.emit(this.verificationCodeDetails.verificationType);
  }

  okClicked() {
    this.setModel();
    if (this.formGroup.valid) {
      var code = this.verificationCode.toString().padStart(5,'0');
      this.verificationCodeChange.emit(code);
    }
    else {
      this.formGroup.get("VerificationCode").markAsTouched();
      this.formGroup.get("VerificationCode").markAsDirty();
      this._cdr.detectChanges();
    }
  }

  cancelClicked() {
    this.verificationCodeCancel.emit();
  }

  setModel() {
    this.verificationCode = this.formGroup.get("VerificationCode").value;
  }

  //#endregion

  //#region Private Methods

  private initForm() {
    this.formGroup = new FormGroup({
      VerificationCode: new FormControl(null, [Validators.required])
    })
  }

  inputFocus(){
    setTimeout(() => {
      this.codeVerification.nativeElement.querySelector('input')?.focus(); 
    }, 200);
  }

  private setTranslations(val: VerificationType) {
    this.verificationTypeTranslate = this.verificationCodeDetails.verificationType == VerificationType.Mail ? this.getTranslation('USER.MAIL') : this.getTranslation('USER.PHONE');
    this.mailOrPhoneSendAgainLabel = this.verificationCodeDetails.verificationType == VerificationType.Mail ? this.getTranslation('VERIFICATION.SEND_PHONE') : this.getTranslation('VERIFICATION.SEND_MAIL')
    this.verificationCodeMsg = this.getTranslation('LOGIN.MESSAGES.CODE_VERIFICATIN_MSG', { 0: `${this.verificationTypeTranslate} : ${this.verificationCodeDetails.encryptedNumMailOrPhone}` });
  }

  private getTranslation(translationWord, parameter?) {
    return this.localizationService.Translate(translationWord, parameter)
  }

  private clear() {
    this.verificationCodeMsg = null;
    this.verificationCode = null;
    this.formGroup.reset();
    this._cdr.markForCheck();
  }

  //#endregion
}


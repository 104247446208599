import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumService, Localization } from 'shared/src/enum';


@Injectable({ providedIn: 'root' })
export class LocalizationService {
    onChangeLanguage = new EventEmitter<Localization>();
    constructor(private translate: TranslateService, private _enumService: EnumService) {
    }

    TranslateEnum(value: any, enumType: any, customEnumName: string = null) {
        const enumName = customEnumName ?? this._enumService.getEnumName(enumType);

        return this.translate.get(`ENUMS.${enumName}.${enumType[value]}`); //translate.instant dont work with nested values more then 2 levels, so should use async trnslate.get method
    }
    Translate(value: string, params: any = {}) {
        return this.translate.instant(value, params);
    }
    TranslateAsync(value: string, params: any = {}) {
        return this.translate.get(value, params);
    }
    changeLanguage(localization: Localization) {
        this.translate.use(localization);
        this.onChangeLanguage.emit(localization);
    }
    InitializeLanguage() {
        let language = Localization.He;
        // document.body.style.setProperty("--direction", 'left');
        this.translate.setDefaultLang(language);
        this.changeLanguage(language);
    }
}

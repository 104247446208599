import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransactionStatus } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { CreditCardCompanyModel } from '../../public/model/CreditCardCompanyModel';
import { TransactionsByBankAccountModel, TransactionsByCreditCardModel } from '../../public/model/TransactionSearchModel';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  constructor(private httpClient: HttpClient) {

  }
  getCreditCardCompanies(): Observable<any> {
    return this.httpClient.get<{
      Entities: CreditCardCompanyModel[]
    }>('/transaction/getCreditCardCompanies');
  }
  getTransactionsByCreditCard(request: TransactionsByCreditCardModel) {
    this.httpClient.post('/transaction/getTransactionsByCreditCard', request);
  }
  getTransactionsByBankAccount(request: TransactionsByBankAccountModel) {
    this.httpClient.post('/transaction/getTransactionsByBankAccount', request);
  }
  getTransactions(request: { listQuery: any, projectIds: number[], paymentPageIds: number[], fromDate: Date, toDate: Date, TransactionStatus: any, NotPassedTransactionStatus: any }): Observable<any> {
    return this.httpClient.post('/transaction/getTransactions', request);
  }
  getCustomerTransactions(customerId: number, obligationId: number = 0, request: any): Observable<any> {
    return this.httpClient.post("/transaction/getCustomerTransactions/" + customerId + '/' + obligationId, request);
  }
  sendReceiptsToEmail(customerId: number, request: any): Observable<any> {
    return this.httpClient.post("/transaction/sendReceiptsToEmail/" + customerId, request);
  }
  customerTransactionsSummary(customerId: number) {
    return this.httpClient.get("/transaction/customerTransactionsSummary/" + customerId);
  }
  removeFailedTranFromReport(request: { transactionId: number, chargeoptionType: any }) {
    return this.httpClient.put('/transaction/removeFailedTranFromReport', request)
  }
  cancelTranBeforeDeposit(transactionId: number) {
    return this.httpClient.put('/transaction/cancelTranBeforeDeposit', { Id: transactionId })
  }
  creditTransaction(request) {
    return this.httpClient.post('/transaction/creditTransaction', request)
  }
  export(request: { exportGetDataParams: { listQuery: any, projectIds: number[], paymentPageIds: number[], fromDate: Date, toDate: Date, TransactionStatus: any, NotPassedTransactionStatus: any }, fileName: string }): Observable<any> {
    return this.httpClient.post('/transaction/export', request, { responseType: "blob", headers: { 'Accept': 'application/octet-stream' } })
  }
  exportOptimizedAsakimSoftware(request: { exportGetDataParams: { projectIds: number[], paymentPageIds: number[], fromDate: Date, toDate: Date }, fileName: string }): Observable<any> {
    return this.httpClient.post('/transaction/exportTransactionAsakimFormat', request, { responseType: "blob", headers: { 'Accept': 'application/octet-stream' } })
  }
  getTransactionsSummeryPerMonth(request: { transactionStatus: any, NotPassedTransactionStatusList: any, filterDate: Date }): Observable<any> {
    return this.httpClient.post('/transaction/getTransactionsSummeryPerMonth', request);
  }
  exportTransactionsSummeryPerMonth(request: { exportGetDataParams: { TransactionStatus: TransactionStatus, NotPassedTransactionStatusList: any }, fileName: string }): Observable<any> {
    return this.httpClient.post('/transaction/exportTransactionsSummeryPerMonth', request, { responseType: "blob", headers: { 'Accept': 'application/octet-stream' } })
  }
  getAllReturnReasons(chargeOptionType: any): Observable<any> {
    return this.httpClient.get('/transaction/getAllReturnReasons/' + chargeOptionType);
  }
  checkPermissionForUpdateTransactionStatus(chargeOptionType: any, transactionId: number) {
    return this.httpClient.get("/transaction/checkPermissionForUpdateTransactionStatus/" + transactionId + "/" + chargeOptionType);
  }
  updateTransactionStatus(request: { id: number, chargeOptionType: any, status: any, reason: number, cancelDate: Date, comment: string }) {
    return this.httpClient.post("/transaction/updateTransactionStatus", request);
  }
  treatedDifferently(request: any): Observable<any> {
    return this.httpClient.post("/transaction/treatedDifferently", request);
  }
  deleteCashTransaction(transactionId: number): Observable<any> {
    return this.httpClient.delete('/transaction/deleteCashTransaction/' + transactionId);
  }
}




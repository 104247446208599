import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from '@ag-grid-community/core';

@Component({
  selector: 'enum-translate',


  template: `
  <label *ngIf="params.value!=null">{{params.value|gridEnumTranslate : params.colDef.customColumnParams.enumType:params.colDef.customColumnParams.enumName}}</label>
  `,
})
export class EnumTranslateColumnComponent implements ICellRendererAngularComp, OnDestroy {

  ngOnDestroy(): void {
  }
  refresh(params: ICellRendererParams): boolean {
    //params.api.refreshCells({ rowNodes: [params.node], columns: [params.column], force: true });

    return false;
    //throw new Error('Method not implemented.');
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  params: any;
  agInit(params: any): void {
    this.params = params;

  }

  // checkedHandler(event) {
  //     let checked = event.target.checked;
  //     let colId = this.params.column.colId;
  //     this.params.node.setDataValue(colId, checked);
  // }
}



















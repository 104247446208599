import { EventEmitter, Injectable, Type } from '@angular/core';
import { popupData } from '../../model/popupData.model';

@Injectable({
  providedIn: 'root'
})
export class DynamicPopupService {

  constructor() { }

  openDynamicPopupClicked: EventEmitter<{ component: Type<any>, popupData: popupData, componentData: {} }> = new EventEmitter();

  getOpenDynamicPopupClicked() {
    return this.openDynamicPopupClicked;
  }
}

export class PaymentPageHeader {
    name: string;
    description:string;
    logoPath:string;
    showShareButton?:boolean;
    shareButtonText:string;
    adUrl:string;
    topBanner:string;
    pageType?:boolean   
    defaultLanguage : number;
    startDate : Date; 
    endDate : Date;  
}


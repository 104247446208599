import { Language } from "shared/src/enum";

export class PaymentPageSettings {
    defaultSum:number;
    minSum:number;
    maxSum:number;
    defaultNumPayment:number;
    maxNumPayment:number;
    displayPaymentSectionFirst?:boolean;
    defaultCurrency?:number;
    allowsCurrencyLinkage?:boolean;
    commentLabel:string;
    defaultComment:string;
    defaultLanguage:Language;
    startDate?:Date;
    endDate?:Date;
    submitButtonText:string;
    showRecaptcha:boolean;
    isRegisteredForDocumentProduction:boolean;
    receiptNameDefaultValue:string;
    showSwipeCardButton?:boolean;
    hideTotalDetails?:boolean;
    disableChangeNumberPayments?:boolean;
    displayInKehilot?: boolean;
    allowRegisteredUser?: boolean;
    paymentCurrencies?:number[];
    allowDifferentNameForDocument?:boolean;
    showSiteRregulations?:boolean;
}
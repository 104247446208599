import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {


  
@ViewChild("recaptcha") recaptcha: RecaptchaComponent;


 

  constructor() { }

  private _captchaToken: string;
  @Output() captchaTokenChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() 
  get captchaToken(){return this._captchaToken}
  set captchaToken(val: string) {
    this._captchaToken = val;
    this.captchaTokenChange.emit(val);
  }
  
  ngOnInit(): void {
//this.recaptcha.reset();

  }

}

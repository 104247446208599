
import { createReducer, on, Action, createSelector } from '@ngrx/store';

export class CoreCommonState {
   
}

let initialState: CoreCommonState = {
}

const createCoreCommonReducer = createReducer(
    initialState,
);

export function CoreCommonReducer(state: CoreCommonState | undefined, action: Action) {
    return createCoreCommonReducer(state, action);
}


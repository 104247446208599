import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FreeSearchInListService {

  constructor() { }

  freeSearch(list: any[], freeSearchText: string): any[] {
    if (freeSearchText)
      return list.filter(item => this.itemContainsSearchValue(item, freeSearchText));
    return list;
  }

  // // Helper function to check if the item contains the search value
  private itemContainsSearchValue(item: any, searchValue: string): boolean {
    // Extract property values from the object and search through them
    const values = Object.values(item);
    for (const value of values) {
      if (value?.toString().toLowerCase().includes(searchValue?.toLowerCase())) {
        return true;
      }
    }
    return false;
  }
}

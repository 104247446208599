import { EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Bank } from 'projects/shared/src/lib/external-models/bank';
import { Branch } from 'projects/shared/src/lib/external-models/branch';
import { ValidateAccountNumberRequest } from 'projects/shared/src/lib/external-models/validateAccountNumberRequest';
import { BaseResponseModel } from '../model/baseResponse.model';
import { BankService } from '../services/bank.service';


@Injectable()
export class BankAccountNumberService {

    onAccountNumberValidatorFinish: EventEmitter<void> = new EventEmitter<void>();

    constructor(private bankService: BankService) { }

    asyncAccountNumberValidator = (control: FormControl) => {

        return new Promise(
            (resolve, reject) => {
                const value = control.value;

                if (!value || value.length < 4) {
                    return null;
                }

                let response: BaseResponseModel;

                let form: FormGroup = control.parent as FormGroup;
                if (!form)
                    return null;
                //form can contains bank control or Bank control
                let bank = null;
                if (form.controls['Bank'] && form.controls['Bank'].value)
                    bank = (form.controls['Bank'].value as Bank).value;
                else if (form.controls['bank'] && form.controls['bank'].value)
                    bank = (form.controls['bank'].value as Bank).value;

                //form can contains branch control or Branch control
                let branch = null;
                if (form.controls['Branch'] && form.controls['Branch'].value)
                    branch = (form.controls['Branch'].value as Branch).value;
                else if (form.controls['branch'] && form.controls['branch'].value)
                    branch = (form.controls['branch'].value as Branch).value;
                else if (form.controls['expiryOrBranch'] && form.controls['expiryOrBranch'].value)
                    branch = (form.controls['expiryOrBranch'].value as Branch).value;


                let request: ValidateAccountNumberRequest = { bank: bank, branch: branch, accountNumber: control.value };
                this.bankService.validateAccountNumber(request).subscribe(res => {
                    response = res as BaseResponseModel;
                    if (response.succeeded == true)
                        resolve(null);
                    else
                        resolve({ wrongAccountNumber: true });
                    setTimeout(() => {
                        this.onAccountNumberValidatorFinish.emit();
                    }, 10);

                });
            }
        );

    }

}



import { Component, ElementRef, ViewChild } from '@angular/core';

import { IDateParams } from '@ag-grid-community/core';
import { IDateAngularComp } from '@ag-grid-community/angular';
import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'app-custom-date',
    template: `<p-calendar appendTo="body" [dateFormat]="dateFormat" [(ngModel)]="date" (ngModelChange)="onDateChanged($event)"
                  [showButtonBar]="true" #calendar [view]="view" gridDateMask [maskFormat]="maskFormat" (onClearClick)="onClear()">
              </p-calendar>`
})
export class CustomDateFilterComponent implements IDateAngularComp {
    @ViewChild("flatpickrEl", { read: ElementRef }) flatpickrEl: ElementRef;
    @ViewChild("calendar") eInput: Calendar;
    date: Date;
    view = "date";
    dateFormat = "dd/mm/y";
    maskFormat = "dd/mm/yy";
    private params: IDateParams;
    private picker: any;

    agInit(params: IDateParams): void {
        this.params = params;
        if (this.params.filterParams.defaultOption == "monthly") {
            this.view = "month";
            this.dateFormat = "mm/y";
            this.maskFormat = "mm/yy";
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy() {

    }

    onDateChanged(selectedDates: any) {
        this.date = selectedDates || null;
        if (!this.date) {
            let inputValue = this.eInput.el.nativeElement.querySelector('input').value;
            if (inputValue || inputValue.length > 0)
                return;
        }
        if (this.date)
            this.date.setHours(6);
        this.params.onDateChanged();
    }

    onClear(){
        this.date = null;
        this.params.onDateChanged();
    }

    getDate(): Date {
        return this.date;
    }

    setDate(date: Date): void {
        this.date = date || null;

    }

    setInputPlaceholder(placeholder: string): void {
        //this.eInput.nativeElement.setAttribute('placeholder', placeholder);
    }

    setInputAriaLabel(label: string): void {
        // this.eInput.nativeElement.setAttribute('aria-label', label);
    }
}
on<form name="form" class="transactionSearch" #form="ngForm" (ngSubmit)="getTransactionBySerch()">
    <div class="transactionSearch_titel">
        <i class="pi pi-search"></i>
        <h2>{{'SEARCH_TRANSACTIONS.TRANSACTION_SEARCH' | translate}}</h2>
    </div>
    <div *ngIf="!verificationCodeDetails.verificationCodeSend" class="transactionSearch_form">
        <div class="rdbOptions">
            <div *ngFor="let option of transactionSearcOptionshList" class="p-field-checkbox">
                <p-radioButton [inputId]="option" name="option.label" [value]="option.label"
                    [(ngModel)]="selectedSearchOption">
                </p-radioButton>
                <label [for]="option.label">{{option.label | enumTranslate : transactionSearchOptions}}</label>
            </div>
        </div>
        <!------ BY SUM ------>
        <div *ngIf="selectedSearchOption== transactionSearchOptions.BySum">
            <p-dropdown name="payWayOptions" [options]="payWayOptionsList" optionLabel="label" optionValue="label"
                [(ngModel)]="selectedPayWayOption" (onChange)="getSelectedOption($event.value)">
                <ng-template let-item pTemplate="selectedItem">
                    <span>{{item.label|enumTranslate:payment}}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <span style="vertical-align:middle;">{{item.label|enumTranslate:payment}}</span>
                </ng-template>
            </p-dropdown>
            <!------ CREDIT CARD------>
            <div *ngIf="selectedPayWayOption == payment.CreditCard">
                <p-inputNumber mode="decimal" [(ngModel)]="transactionsByCreditCardModel.creditCardNum"
                    name="lastNumersTran" #lastNumersTran="ngModel" [required]="true" [useGrouping]="false"
                    [maxlength]="4" placeholder="{{'CREDIT_CARD.LAST_NUMBERS' | translate}}">
                </p-inputNumber>
                <small id="username2-help" class="p-error"
                    *ngIf="form.controls['lastNumersTran']?.invalid && (form.controls['lastNumersTran']?.dirty || form.controls['lastNumersTran']?.touched)">
                    <div *ngIf="form.controls['lastNumersTran']?.errors.required">
                        {{'ERROR_MESSAGES.REQUIRED'|translate}}
                    </div>
                </small>
                <p-dropdown name="creditCardOptions" [options]="(creditCardOptions$| async)?.entities"
                    placeholder="{{'CREDIT_CARD.SELECT_CREDIT_CARD_COMPANY' | translate}}" [required]="true"
                    [(ngModel)]="transactionsByCreditCardModel.creditCardCompanyId" optionLabel="name">
                </p-dropdown>
                <small id="username2-help" class="p-error"
                    *ngIf="form.controls['creditCardOptions']?.invalid && (form.controls['creditCardOptions']?.dirty || form.controls['creditCardOptions']?.touched)">
                    <div *ngIf="form.controls['creditCardOptions']?.errors.required">
                        {{'ERROR_MESSAGES.REQUIRED'|translate}}
                    </div>
                </small>
            </div>
            <!------ BANK DIRECT DEBIT------>
            <div *ngIf="selectedPayWayOption == payment.BankDirectDebit">
                <p-inputNumber name="bank" [(ngModel)]="transactionsByBankAccountModel.bankNum" mode="decimal"
                    inputId="withoutgrouping" [useGrouping]="false" [required]="true"
                    placeholder="{{'BANK_DIRECT_DEBIT.BANK' | translate}}">
                </p-inputNumber>
                <small id="username2-help" class="p-error"
                    *ngIf="form.controls['bank']?.invalid && (form.controls['bank']?.dirty || form.controls['bank']?.touched)">
                    <div *ngIf="form.controls['bank']?.errors.required">
                        {{'ERROR_MESSAGES.REQUIRED'|translate}}
                    </div>
                </small>
                <p-inputNumber name="branch" [(ngModel)]="transactionsByBankAccountModel.branchNum" mode="decimal"
                    inputId="withoutgrouping" [useGrouping]="false" [required]="true"
                    placeholder="{{'BANK_DIRECT_DEBIT.BRANCH' | translate}}">
                </p-inputNumber>
                <small id="username2-help" class="p-error"
                    *ngIf="form.controls['branch']?.invalid && (form.controls['branch']?.dirty || form.controls['branch']?.touched)">
                    <div *ngIf="form.controls['branch']?.errors.required">
                        {{'ERROR_MESSAGES.REQUIRED'|translate}}
                    </div>
                </small>
                <p-inputNumber name="accountNumbre" [(ngModel)]="transactionsByBankAccountModel.bankAccount"
                    mode="decimal" inputId="withoutgrouping" [useGrouping]="false" [required]="true"
                    placeholder="{{'BANK_DIRECT_DEBIT.ACCOUNT_NUMBER' | translate}}">
                </p-inputNumber>
                <small id="username2-help" class="p-error"
                    *ngIf="form.controls['accountNumbre']?.invalid && (form.controls['accountNumbre']?.dirty || form.controls['accountNumbre']?.touched)">
                    <div *ngIf="form.controls['accountNumbre']?.errors.required">
                        {{'ERROR_MESSAGES.REQUIRED'|translate}}
                    </div>
                </small>
            </div>
            <p-inputNumber mode="decimal" [(ngModel)]="sum" name="sumTran" #sumTran="ngModel" [minFractionDigits]="2"
                [maxFractionDigits]="5" [min]="0" placeholder="{{'SUM' | translate}}"></p-inputNumber>
            <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="date" name="calendarTran" #calendarTran="ngModel"
                [readonlyInput]="true"></p-calendar>
        </div>
        <!------ BY TEL OR MAIL ------>
        <div *ngIf="selectedSearchOption == transactionSearchOptions.ByTelOrMail">
            <tel-or-mail [(telOrMailNum)]="transactionsByMailOrPhoneModel.userMailOrPhone"></tel-or-mail>
        </div>
        <!-- how to biding - to what ?? -->
        <captcha class="captcha_width" name="captcha" [(captchaToken)]="transactionsSearchBaseModel.captchaToken">
        </captcha>
        <small id="username2-help" class="p-error" *ngIf="showCaptchaRrquierd">{{'SEARCH_TRANSACTIONS.CAPTCHA_REQUIERD'
            |
            translate}}</small>
        <p-button type="submit" label="{{'GENERAL.CONTINUE' | translate}}"></p-button>
    </div>
    <!------ CODE VERIFICATION ------>
    <div *ngIf="verificationCodeDetails.verificationCodeSend">
        <code-verification [(verificationCode)]="verificationModel.VerificationCode"
            (sendAgainVerificationCode)="sendVerificationCode()" (verificationCodeChange)="sendVerificationCode()"
            [verificationCodeDetails]="verificationCodeDetails" [lightTitle]="true">
        </code-verification>
        <!-- [encryptedNumMailOrPhone]="verificationCodeDetails?.encryptedNumMailOrPhone"
        [(verificationType)]="verificationModel.VerificationType" -->
    </div>
</form>
<div>
    <div *ngIf="gridTitle" class="col-fixed align-content-start">
        <span class="fs-xlarge fw-700" style="color:var(--bluColorA)"> {{gridTitle}}</span>
    </div>
    <div class="flex-column w-full">
        <div class="flex justify-content-between " style="flex-wrap:wrap-reverse">
            <div class="align-content-center align-items-center align-self-baseline flex grid">
                <div class="col-fixed" *ngIf="showSearch">
                    <free-search [(freeSearchText)]="freeSearchText" [searchText]="searchText"></free-search>
                </div>
                <div class="col-fixed" *ngIf="anotherSearch">
                    <ng-container [ngTemplateOutlet]="anotherSearch"></ng-container>
                </div>
                <div class="col-fixed">
                    <ng-container [ngTemplateOutlet]="calander"></ng-container>
                </div>
            </div>
            <div class=" justify-content-end flex">
                <div class="col flex justify-content-end align-items-center" *ngIf="buttons!=null && buttons.length>0">
                    <div class="buttonWarp flex gap-1 flex-wrap">
                        <div class="dispaly-inline  "
                            [ngClass]="project==null && statusList==null?'mt-0':isSummeryTable==true?'mt-3':'mt-0 md:mt-4.1'"
                            *ngFor="let button of buttons">
                            <button style="--iconUrl:url({{button.iconUrl}})" pButton *ngIf="getDisplayButton(button)"
                                type="button" [icon]="button.icon"
                                class="p-button-outlined overflow-visible grid-button"
                                [ngClass]="{'p-button-raised ':!button.label,' hidden sm:inline-block':button.buttonType==buttonTypeExport,'inline-block':button.buttonType!=buttonTypeExport,'no-label':button.label==null}"
                                iconPos="left" [label]="button.label | translate" [disabled]="getDisableButton(button)"
                                (click)="onClick(button,$event)" [pTooltip]="getButtonTooltip(button) | translate"
                                tooltipPosition="bottom">
                                <div *ngIf="getDisableButton(button)"
                                    class="absolute button-tooltip mr-5 px-2 py-2 rounded-normal text-center text-white"
                                    style="background: rgba(66, 66, 66,.8);margin-top: 2.3rem;font-size: 12px; width:7rem">
                                    {{
                                    getButtonTooltip(button) | translate }}</div>
                            </button>

                            <p-overlayPanel *ngIf="button.menuItems" #overlayPanels>
                                <p-listbox styleClass="border-0" [options]="button.menuItems"
                                    [(ngModel)]="selectedMenuItem" optionLabel="label"
                                    (click)="clickOnMenuItems(button)"></p-listbox>
                            </p-overlayPanel>
                        </div>

                    </div>
                </div>
                <!-- סכ"ה -->
                <div *ngIf="displayTitle" class=" flex justify-content-between align-items-center ">
                    <span style="color: #dcdada;">|</span>
                    <div class="ag-grid-sum-header"
                        style="font-weight: 900;color:black;font-size:1.1rem; min-width: 100px;text-align: end;">
                        {{sumHeaderCount | number}} {{sumHeaderText}}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-content-between flex-wrap pt-1 lg:flex-row flex-column" *ngIf="isSummeryTable!=true || statusList!=null">
            <div class="flex-1 grid">
                <div class="col-fixed flex align-items-center" *ngIf="project">
                    <div class="grid pb-0">
                        <ng-container [ngTemplateOutlet]="project"></ng-container>
                    </div>
                </div>
                <div *ngIf="statusList" [ngClass]="project?'col-7':'col-9'">

                    <div *ngIf="statusList" class="inline-block md:hidden  justify-content-center ">
                        <span class="p-float-label">
                            <p-dropdown [options]="statusList" optionLabel="title" [(ngModel)]="selectedStatus"
                                (ngModelChange)="runStatusChanged($event)" name="selectedStatus" optionLabel="label"
                                optionValue="label">
                                <ng-template let-item pTemplate="selectedItem">
                                    <span> {{item.label|gridEnumTranslate:enumType:enumName}} </span>
                                </ng-template>

                                <ng-template let-item pTemplate="item">
                                    {{item.label|gridEnumTranslate:enumType:enumName}}
                                </ng-template>
                            </p-dropdown>
                            <label for="selectedStatus">סטטוס</label>
                        </span>
                    </div>

                    <p-selectButton [(ngModel)]="selectedStatus" class="hidden md:inline-block"
                        (ngModelChange)="runStatusChanged($event)" name="selectedStatus" [options]="statusList"
                        optionLabel="label" optionValue="label">
                        <ng-template let-item pTemplate="selectedItem">
                            <span> {{item.label|gridEnumTranslate:enumType:enumName}} </span>
                            <i *ngIf="statusIconEnumType" class="pi my-auto"
                                [ngClass]="'pi-' + statusIconEnumType[enumType[item.label]]"
                                style="margin-right: 10px;"></i>

                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            {{item.label|gridEnumTranslate:enumType:enumName}}
                            <i *ngIf="statusIconEnumType" class="pi my-auto"
                                [ngClass]="'pi-' + statusIconEnumType[enumType[item.label]]"
                                style="margin-right: 10px;"></i>

                        </ng-template>
                    </p-selectButton>
                </div>
            </div>
            <div class="flex justify-content-end grid">
                <div class="col flex justify-content-end">
                    <ng-container [ngTemplateOutlet]="subTitle"></ng-container>

                    <div style="color:black" *ngIf="headerMoreInformation"> {{headerMoreInformation?.title}}
                        {{headerMoreInformation?.number}} {{headerMoreInformation?.details}}</div>
                </div>
            </div>
        </div>

        <div *ngIf="note!=''" class="font-bold mb-3">
            <span>{{note}}</span>
        </div>
        <div *ngIf="isSummeryTable!=true&&(subStatusMenu||leftSubStatusMenu)" class="grid" style="min-height: 3rem;">
            <div class="col-9 align-items-center">
                <ng-container [ngTemplateOutlet]="subStatusMenu"></ng-container>
            </div>
            <div class="col-3 flex justify-content-end align-items-center">
                <ng-container [ngTemplateOutlet]="leftSubStatusMenu"></ng-container>
            </div>
        </div>

        <div *ngIf="showAddRow()">
            <button pButton class="p-button-outlined bg" [label]="addRowSettings.addRowLabel | translate"
                (click)="addNewRow()" icon="pi pi-envelope" iconPos="left">
            </button>
        </div>
    </div>
</div>
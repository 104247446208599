import { Pipe, PipeTransform } from '@angular/core';
import { FieldTypes } from 'shared/src/enum';
import { DatePipe, DecimalPipe } from '@angular/common';
import { EnumTranslatePipe } from 'shared/src/localization';
import { LocalizationService } from 'shared/src/localization';
import { PasswordViewPipe } from './password-view.pipe';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private enumTranslatePipe: EnumTranslatePipe, private datePipe: DatePipe,
    public localizationService: LocalizationService, private passwordViewPipe: PasswordViewPipe) {
  }
  math = Math;
  transform(items: any[], searchDefinitions: any[], searchTerm: string): any[] {

    if (!searchTerm || !searchDefinitions || !items ||
      searchDefinitions.filter(fd => fd.field && fd.searchFunc).length === 0) {
      return items;
    }

    searchDefinitions = searchDefinitions.filter(fd => fd.field && fd.searchFunc);
    return items.filter(item =>
      searchDefinitions.filter(fd =>
        fd.field && fd.searchFunc && fd.field in item && fd.searchFunc(item[fd.field], searchTerm)
      ).length > 0
    )
  }
  buildSearchDefinitions(fields: FieldDefinitions[]) {
    return fields.map(f => ({ field: f.field, searchFunc: this.getFilterInstruction(f) }))
  }
  private getFilterInstruction(field: FieldDefinitions) {
    let searchFunc;
    if (field.filterByDisplayValue) {
      switch (field.type) {
        case FieldTypes.Password:
          searchFunc = (value, searchText) => this.passwordViewPipe.transform(value).includes(searchText) ? true : false
          break;
        case FieldTypes.EnumComboBox:
          searchFunc = (value, searchText) => this.enumTranslatePipe.transform(value, field.enumType, field.enumName).includes(searchText) ? true : false
          break;
        case FieldTypes.Date:
          searchFunc = (value, searchText) => this.datePipe.transform(value, 'dd.MM.yy').includes(searchText) ? true : false
          break;
        case FieldTypes.HebrewDate:
          searchFunc = (value, searchText) => this.datePipe.transform(value, 'MMMM yyyy').includes(searchText) ? true : false
          break;
        case FieldTypes.Amount:
          searchFunc = (value, searchText) => this.decimalPipe.transform(value, '1.2-2').includes(searchText) ? true : false
          break;
        case FieldTypes.Number:
          searchFunc = (value, searchText) => this.math.abs(value).toString().includes(searchText) ? true : false
          break;
        default:
          searchFunc = field.translateKey ? (value, searchText) => this.localizationService.Translate(field.translateKey + value).includes(searchText) ? true : false : (value, searchText) => value.toString().includes(searchText) ? true : false
          break;
      }
    }
    else {
      searchFunc = (value, searchText) => value.toString().includes(searchText) ? true : false
    }
    return searchFunc;
  }

}
export interface FieldDefinitions {
  field: string;
  filterByDisplayValue?: boolean;
  type?: FieldTypes;
  enumType?: any;
  enumName?: string;
  translateKey?: string;
}

<span class="p-input-icon-right p-input-icon-left w-full  form-group">
    <i class="pi pi-times" style="cursor: pointer;" (click)="clearSearch()"></i>
    <input *ngIf="!onlyNumbers" pInputText #inputSearch autofocus type="text" class=" w-full " delayEventName="onkeyup"
        id="freeSearchText" name="freeSearchText" [(ngModel)]="freeSearchText" (delayEvent)="freeSearchDelay()"
        (ngModelChange)="freeSearchChange()" [placeholder]="searchText ?? ('GENERAL.FREE_SEARCH_TEXT' | translate)" />
    <input *ngIf="onlyNumbers" pInputText #inputSearch autofocus type="text" class=" w-full " delayEventName="onkeyup"
        id="freeSearchText" name="freeSearchText" [(ngModel)]="freeSearchText" (delayEvent)="freeSearchDelay()"
        (ngModelChange)="freeSearchChange()" [placeholder]="searchText ?? ('GENERAL.FREE_SEARCH_TEXT' | translate)" pattern="^[0-9]*$"/>
    <i class="pi pi-search"></i>
</span>
<!-- <span class="error" *ngIf="!inputSearch?.nativeElement?.validity?.valid" style=" color: #B00020;">שדה זה יכול להכיל רק מספרים
</span> -->

<!-- 
<div class="form-group lg:col-3 md:col-6 s:col-12 p-field " *ngIf=" !(isNew&&!isKesherManager)">
    <span class="p-float-label">
      <input id="companyCode" pInputText formControlName="companyCode" class="w-full" />
      <label for="companyCode">{{ 'COMPANY_SETTINGS.COMPANY_DETAILS.COMPANY_CODE' | translate }}</label>
    </span>
  </div> -->
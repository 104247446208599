import { Store } from "@ngrx/store";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { OverlayPanel } from "primeng/overlaypanel";
import { AppState, selecCompanyInCommonState } from "projects/kesher-site/src/app/app-state.index";
import { AdversitingStatusEnum } from "projects/shared/src/public-api";
import { MessagesBoardService } from "./messages-board.service";
import { TopbarService } from "../../topbar/topbar.service";


@Component({
  selector: 'messages-board',
  templateUrl: './messages-board.component.html',
  styleUrls: ['./messages-board.component.scss']
})
export class MessagesBoardComponent implements OnInit {

  @ViewChild('op') op: OverlayPanel;
  @ViewChild('elm') elm: ElementRef;

  messages: any[];
  showRedButton: boolean = false;

  companyCode: string;
  href: string;
  url: string;
  isFirstTime = false;
  displayPopup = true;

  ngOnInit(): void {
    this.messagesBoardService.getNewMessage().subscribe((res: any) => {
      if (res) {
        this.messages = [res];
        this.op.show(null, this.elm.nativeElement);
        this.showRedButton = true;
        this.isFirstTime = true; // its first login and one time for popup new message
      }
    }
    );
    this.cdr.markForCheck();
  }

  closeMenuItems() {
    this.op.toggle(false);
    this.showRedButton = false;
  }

  clickOnMenuItems(event) {
    if (this.isFirstTime) {
      this.displayPopup = false;
      this.op.toggle(false, this.elm.nativeElement);
    }
    //on open messages board
    if (!this.op.overlayVisible || this.isFirstTime)
      this.messagesBoardService.getMessagesBoard()
        .subscribe((res: any) => {
          this.messages = res;
          if (this.messages && this.messages.find(m => !m.isLooking))
            this.showRedButton = true;
          else
            this.showRedButton = false;
          if (this.messages)
            this.messagesBoardService.updatesStatusAdversitings({ ids: this.messages.map(m => m.id), status: AdversitingStatusEnum.IsLooking }).subscribe(() => { });

          //if its first time, dont close board, open its again with all messages
          if (this.isFirstTime) {
            this.isFirstTime = false;
            setTimeout(() => {
              this.displayPopup = true;
              this.op.show(null, this.elm.nativeElement);
            }, 90);

          }
          else
            this.op.show(null, this.elm.nativeElement);

        });
    else {
      this.showRedButton = false;
      this.op.toggle(event);
    }
  }

  navigateToAdvertiseNow() {
    this.messagesBoardService.getAdvertiseNowLink().subscribe((res) => {
      if (res) {
        window.open('https://ultra.kesherhk.info/external/forms/edit/' + res, '_blank');
      }
    });
  }

  onGoToManagement() {
    this.closeMenuItems();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private messagesBoardService: MessagesBoardService,
    private store: Store<AppState>,
    private topbarService: TopbarService
  ) {
    this.store.select(selecCompanyInCommonState).subscribe((company) => {
      if (company) {
        if (this.companyCode !== company.companyCode) {
          this.companyCode = company.companyCode;
          this.href = "/kesher/" + this.companyCode + "/adversitings-management";
        }
      }
      else
        this.companyCode = null;
    });

    this.topbarService.openAdversitingClicked.subscribe(() => {
      this.closeMenuItems();
      this.isFirstTime = false;
    });
    this.topbarService.openCloseMenu.subscribe(() => {
      if (this.op.overlayVisible) {
        this.closeMenuItems();
        this.isFirstTime = false;
      }
    })
  }
}

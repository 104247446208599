import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { LocalizationService, SelectAllOnFocusService, selectLoadingCommonState, selectSpinnerLoadingCommonState, setIsLoading, setLocalization } from 'projects/shared/src/public-api';
import { environment } from '../environments/environment';
import { AppState, } from './app-state.index';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AppComponent {
  constructor(private store: Store<AppState>, private localizationService: LocalizationService, private translateService: TranslateService,
    private titleService: Title,private selectAllService: SelectAllOnFocusService ) {
    localizationService.onChangeLanguage.subscribe(localization => {
      this.store.dispatch(setLocalization({ localization }));

    });
    localizationService.InitializeLanguage();

  }
  @ViewChild('blockUIElement') blockUIElement: ElementRef;

  //@HostListener('window:beforeunload', ['$event'])
  isLoading$: any;
  spinnerLoading$: any;
  divElement: any;

  // public beforeunloadHandler($event) {
  //   //this behavior avoid refresh site (F5) and stay logged in.
  //   //this.authService.logout();
  // }


  ngOnInit() {

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.store.dispatch(setIsLoading({ isLoading: true, divElement: null }))
    this.isLoading$ = this.store.select(selectLoadingCommonState);
    this.spinnerLoading$ = this.store.select(selectSpinnerLoadingCommonState);
    // this.messagesService.onAddMessage.subscribe(() => {
    //   this.changeRef.detectChanges();
    // });

    if (this.translateService.store.translations[this.translateService.currentLang]) {
      this.titleService.setTitle(this.localizationService.Translate('GENERAL.KESHER_TITLE'));
    } else {
      this.translateService.onLangChange.subscribe(() => {
        this.titleService.setTitle(this.localizationService.Translate('GENERAL.KESHER_TITLE'));
      });
    }


  }
}

<div class="my-8 mx-3" style="min-height: 20rem">
<p-table *ngIf="changes.length" [value]="changes" styleClass="p-datatable-striped" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th style="text-align: right;" class="fw-700">{{ 'GENERAL.DATE' | translate }}</th>
            <th style="text-align: right;" class="fw-700">{{ 'CHANGES.FIELD' | translate }}</th>
            <th style="text-align: right;" class="fw-700">{{ 'CHANGES.OLD_VALUE' | translate }}</th>
            <th style="text-align: right;" class="fw-700">{{ 'CHANGES.NEW_VALUE' | translate }}</th>
             <th style="text-align: right;" class="fw-700">{{ 'CHANGES.PERFORMER' | translate }}</th> 
             <th style="text-align: right;" class="fw-700">{{ 'CHANGES.SERVICE' | translate }}</th> 

        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-change>
        <tr>
            <td style="text-align: right;">{{change.changeDate| date:'HH:mm dd/MM/yyyy'}}</td>
            <td style="text-align: right;">{{change.fieldDesc}}</td>
            <td style="text-align: right;">{{change.oldValue}}</td>
            <td style="text-align: right;">{{change.newValue}}</td>
           <td style="text-align: right;">{{change.user}}</td> 
           <td style="text-align: right;">{{change.service}}</td> 
        </tr>
    </ng-template>
</p-table>
</div>


// app/button-cell-renderer.component.ts

import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Component,  OnDestroy } from "@angular/core";
import { _createStoreReducers } from "@ngrx/store/src/store_module";


@Component({
  selector: 'checkbox-renderer',
  template:
    `<div (click)="btnClickedHandler()" class="pi" [ngClass]="this.params.value==1? this.params.trueIcon:this.params.falseIcon" ></div>`
})
export class CheckBoxIconRenderer implements ICellRendererAngularComp, OnDestroy {


  constructor() { }
  refresh(params: ICellRendererParams): boolean {return false  }
  
  ngOnDestroy(): void {
  }

  params: any;
  column: any;
  agInit(params: any): void {
    this.params = params;
    this.column = params.column.colDef;
  }

  btnClickedHandler() {
    this.params.clicked(this.params);
  }


}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class VerificationService {

  constructor(private httpClient: HttpClient) { }

  sendVerificationPhoneOrEmail(verificationType,userMailOrPhone): Observable<any> {
    return this.httpClient.get('/verification/sendVerificationPhoneOrEmail/' +userMailOrPhone +'/'+verificationType);
  }

  isValidVerificationCode(model): Observable<any> {
    return this.httpClient.post('/verification/isValidVerificationCode' ,model);
  }
}

<!-- <div class="main ui-rtl p-grid p-justify-center" dir="rtl" [ngStyle]="{'zoom':zoom}" (window:resize)="onResize($event)">
    <div class="backgroung-img ui-rtl p-grid p-justify-center" [ngStyle]="backgroundStyle">
        <div class="header p-lg-9 p-sm-11 p-col-12">
            <app-header></app-header>
        </div>
        <div class="body p-col-12">
            <router-outlet></router-outlet>
            <app-custom-message></app-custom-message>
        </div>
    </div>
    <div class="seperator p-col-12"></div>
    <div class="footer p-col-12">
        <app-public-footer></app-public-footer>
    </div>
</div>
<div *ngIf="isLoading$ | async">
    <p-blockUI [blocked]="true"></p-blockUI>
    <p-progressSpinner></p-progressSpinner>
</div> -->
<p>
    PUBLIC main

</p>
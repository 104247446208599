import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { CreditCardCompanyModel } from '../model/CreditCardCompanyModel';
import { TransactionsByBankAccountModel, TransactionsByCreditCardModel, TransactionsByMailOrPhoneModel } from '../model/TransactionSearchModel';

@Injectable()
export class TransactionsService {

  constructor(private httpClient: HttpClient) { }

  getCreditCardCompanies() :Observable<any>{
    return this.httpClient.get<{
      Entities: CreditCardCompanyModel[]
    }>('/transaction/getCreditCardCompanies');
  }
  getTransactionsByCreditCard(request: TransactionsByCreditCardModel) {
    this.httpClient.post('/transaction/getTransactionsByCreditCard', request);
  }
  getTransactionsByBankAccount(request: TransactionsByBankAccountModel) {
    this.httpClient.post('/transaction/getTransactionsByBankAccount', request);
  }
  getDuplicateTransactions(request: any) :Observable<any>{
   return this.httpClient.post('/transaction/getDuplicateTransactions', request);
  }
}



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RangeDates } from 'shared/src/enum';
import * as moment from 'moment';
import { DateRange, RangeDatesService } from './range-dates.service';

@Component({
  selector: 'range-dates',
  templateUrl: './range-dates.component.html',
  styleUrls: ['./range-dates.component.scss']
})
export class RangeDatesComponent implements OnInit {
  currentDate: Date = new Date();
  rangeDates = RangeDates;
  isMobile: boolean = window.innerWidth > 767 ? false : true;


  @Output() toDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() fromDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() labelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelectRange: EventEmitter<{ fromDate: Date, toDate: Date }> = new EventEmitter<{ fromDate: Date, toDate: Date }>();
  @Output() deSelectSummeryRowChange: EventEmitter<any> = new EventEmitter<any>();



  private _fromDate: Date;
  changeRange: boolean;
  @Input() set fromDate(data: Date) {
    if (data != this.fromDate && data != null) {
      this._fromDate = data;
      //prevent raise 2 events:fromDate and toDate events on change range
      if (!this.changeRange)
        this.fromDateChange.emit(data);
      this._label = '';
    }
  }
  get fromDate() { return this._fromDate }


  private _toDate: Date;
  @Input() set toDate(data: Date) {
    if (data != this.toDate && data != null) {
      this._toDate = data;
      //prevent raise 2 events:fromDate and toDate events on change range
      if (!this.changeRange)
        this.toDateChange.emit(data);
      this._label = '';
    }
  }
  get toDate() { return this._toDate }
  private _label: string;
  @Input() set label(data: string) {
    if (data != this.label && data != null) {
      this._label = data;
      //prevent raise 2 events:fromDate and toDate events on change range
      // if (!this.changeRange)
      //   this.fromDateChange.emit(this.fromDate);
    }

  }
  get label() { return this._label }
  @Input() differentDesign: boolean;
  @Input() toDateMaxDate: Date =null;
  constructor(rangeDatesService: RangeDatesService) {
    rangeDatesService.onChangeRange.subscribe((dates: DateRange) => {
      this.changeRange = true;
      this.fromDate = dates.fromDate;
      this.toDate = dates.toDate;
      this.changeRange = false;
    })
  }

  ngOnInit(): void {
    //when go to range dates with data, not initial default values
    if (!this.fromDate || !this.toDate) {
      this.fromDate = this.fromDate ?? new Date(moment().startOf('month').format('ll'));
      this.toDate = this.currentDate;
      this.label = 'CurrentMonth';
    }
  }

  setRanges(range: RangeDates, element, event) {
    this.changeRange = true;

    switch (range) {
      case (RangeDates.CurrentDay):
        this.fromDate = new Date();
        this.toDate = new Date();
        this.label = '';
        break;
      case (RangeDates.NextMonth):
        this.fromDate = new Date(moment(this.fromDate).add(1, 'M').startOf('month').format('ll'));
        this.toDate = new Date(moment(this.fromDate).endOf('month').format('ll'));
        this.label = '';
        break;
      case (RangeDates.PrevMonth):
        this.fromDate = new Date(moment(this.fromDate).subtract(1, 'M').startOf('month').format('ll'));
        this.toDate = new Date(moment(this.fromDate).endOf('month').format('ll'));
        this.label = '';
        break;
      case (RangeDates.CurrentMonth):
        this.fromDate = new Date(moment().startOf('month').format('ll'));
        this.toDate = new Date();
        this.label = 'CurrentMonth';
        break;
      case (RangeDates.LastThirtyDays):
        this.fromDate = new Date(moment(this.currentDate).subtract(30, 'd').format('ll'));
        this.toDate = new Date();
        this.label = 'LastThirtyDays';
        break;
      case (RangeDates.Quarterly):
        this.fromDate = new Date(moment(this.currentDate).subtract(3, 'M').format('ll'));
        this.toDate = this.currentDate;
        this.label = 'Quarterly';
        break;
      case (RangeDates.YearAgo):
        this.fromDate = new Date(moment(this.currentDate).subtract(1, 'y').format('ll'));
        this.toDate = new Date();
        this.label = 'YearAgo';
        break;
    }
    this.onSelectRange.emit({ fromDate: this.fromDate, toDate: this.toDate });
    this.changeRange = false;
    this.deSelectSummeryRow();
    if (element != null)
      element.hide(event);
  }
  deSelectSummeryRow() {
    this.deSelectSummeryRowChange.emit()
  }
}



<ul class="layout-menu margin" >
    <li class="mobileMenu mt-4" app-menuitem *ngFor="let item of items; let i = index;" [item]="item" [index]="i" [root]="true"></li>

    <div *ngFor="let item of activeMenuItem?.items; let i = index;" class="desktopMenu">
        <li  class="selectButton " app-menuitem [item]="item"  [index]="i" [root]="false"></li>

    </div>

</ul>
<messages-board></messages-board>

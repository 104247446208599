import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from "@ngrx/store";
import { SharedCommonState, SharedCommonReducer } from "./reducers/‏‏shared-common.reducer";


const reducers = {
    commonState: SharedCommonReducer,
};

interface CommonState {
    commonState: SharedCommonState;
}

const reducer: ActionReducer<CommonState> = combineReducers(reducers);

function commonReducer(state: any, action: any) {
    return reducer(state, action);
}

export { commonReducer, CommonState };

// export selectors
export const selectTranslation = (state: CommonState) => state.commonState.localization;
export const selectIsLoading = (state: CommonState) => state.commonState.isLoading;
export const selectSpinnerLoading = (state: CommonState) => state.commonState.spinnerLoading;


export const selectSharedState = createFeatureSelector<CommonState>('shared-common');

export const selectSharedCommonState = createSelector(selectSharedState, (sharedState) => sharedState.commonState);

export const selecTranslationInCommonState = createSelector(selectSharedState, selectTranslation)
export const selectLoadingCommonState = createSelector(selectSharedState, selectIsLoading)
export const selectSpinnerLoadingCommonState = createSelector(selectSharedState, selectSpinnerLoading)



import {  Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ColumnOptions, defualtColumnOptions, defualtGridOptions, GridOptions, loadDataParameter, RowSelectionType } from '../ag-grid-dynamic/grid-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSearchComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  //#region @Input() members

  @Input() loadData: (request: any) => Observable<any>;
  @Input() collectionName: string;
  @Input() skipId: string
  @Input() searchText: string;

  //#endregion 

  //#region @ViewChild() members
  @ViewChild("grid") grid: any;
  //#endregion

  //#region @Output members

  @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter<any>();

  //#endregion

  //#region members
  defaultColumnOptions: ColumnOptions = defualtColumnOptions;
  defaultGridOptions: GridOptions = defualtGridOptions;
  options: GridOptions
  column: ColumnOptions = { ...defualtColumnOptions };
  columns: ColumnOptions[] = [];
  loadDataParameters: loadDataParameter;
  //#endregion

  //#region angular lyfecycle methods
  ngOnInit(): void {
    this.initOptions();
  }

  ngAfterViewInit(): void {
    this.initOptions();
  }
  //#endregion  

  //#region AGgrid options initializers

  initOptions(): void {
    this.options = {
      ... this.defaultGridOptions,
      rowSelectionType: RowSelectionType.single,
      fitColumns: true,
      showSelectionColumn: false,
      displaySidebarFilter: false,
    }
  }

  //#endregion

  //#region public methods
  setDataSource() {
    this.grid.setDataSource();
  }

  prepareLoadData() {
    let request = {
      listQuery: this.loadDataParameters,
      status: this.loadDataParameters?.status,
    }
    return this.loadData(request).pipe(tap((res) => {
      if (!this.columns.length) {
        this.prepareColumns(res)
      }
    }));
  }

  //#endregion

  //#region event methods
  onRowDoubleClicked(params) {
    this.rowDoubleClicked.emit(params);
  }

  //#region private methods
  private prepareColumns(res: any) {
    let _columns = [];
    var state = localStorage.getItem("agState");
    var columnsState = JSON.parse(state)?.["list-search"];
    Object.entries(res.entities?.[0] ?? []).forEach((col, i) => {
      if (["id", this.skipId].includes(col[0])) return;
      _columns.push({
        ... this.defaultColumnOptions,
        field: col[0],
        hideColumn: () => columnsState?.find(c => c.colId == col[0])?.hide,
        headerName: this.getTranslation("SEARCH_LIST." + col[0]),
      })
    });
    this.columns = _columns;
  }

  private getTranslation(translationWord, parameter?) {
    return this.translate.instant(translationWord, parameter)
  }
  //#endregion

}

import { Injector, Pipe, PipeTransform, Type } from '@angular/core';
import { GridEnumTranslatePipe } from './grid-enum-translate.pipe';

@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {
  constructor(private injector: Injector) { }

  transform(value: unknown, requiredPipe: any, data: [], ...args: unknown[]): unknown {
    if( value == null ) return value;

    let availableDynamicPipes = ["email", "enum"];
    if (!args || args.length == 0 || !args[0] || !args[0][0] || availableDynamicPipes.indexOf(args[0][0].toString()) < 0) {
      // For build in pipes:
      // if (requiredPipe && requiredPipe.prototype.constructor.name != 'DynamicPipe') {
      return this.runPipe(requiredPipe, value, args);
    }
    // For local changes:
    // else {
    let _args = args[0];
    let pipe = _args[0];

    switch (pipe) {
      case "email":
        // injects @ between every word of the given string
        value = value?.toString().split(' ').join(_args[1]);
        break;

      case "enum":
        // convert other ENUM column data to its REAL value, and concat it to the given value
        if (data) {
          var enumType = _args[1];
          var enumField = _args[2];
          var enumName = _args[3];
          value += ' ' + this.runPipe(GridEnumTranslatePipe, data[enumField], enumType,enumName);
        }
        break;

      default:
        break;
    }
    return value;
    // }
  }

  private runPipe(requiredPipe: any, value: unknown, ...args: unknown[]): unknown {
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: requiredPipe }
      ]
    });
    const pipe = injector.get(requiredPipe);
    return pipe.transform(value, ...args);
  }
}
